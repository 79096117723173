import React, { useEffect, useState, version } from 'react'
import { getSocketConnection } from '../../../../../socket/socketHelperConnection';
import Icons from '../../../../../assets/icons';
import GenericCustomButton from '../../../../../genericComponents/GenericCustomButton';
import GenericSpinnerLoader from '../../../../../loaders/GenericSpinnerLoader';
import GenericToggleComponent from '../../../../../genericComponents/GenericToggleComponent'
import ParentModal from '../../../../../genericComponents/ParentModal'
import { select } from 'redux-saga/effects';
import Configure2FA from './Configure2FA';
import { dispatchHandler } from '../../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd'
import {QRCode} from 'antd';
import LanguageTranslator from '../../../../../locals/LanguageTranslator';

const WeChatConnect = (props) => {
    let socket = getSocketConnection();

    const [loading, setLoading] = useState({});

    const [errorData, setErrorData] = useState(null)

    const [barcode, setBarcode] = useState(null)

    const [connectionStatus, setConnectionStatus] = useState(false);

    const [connectToWechat, setConnectToWechat] = useState(false);

    const [selectedModal, setSelectedModal] = useState(false);

    const dispatch = useDispatch();

    const setupMfaData = useSelector(state => state?.authStore?.setupMfaData)
    const enableMfaData = useSelector(state => state?.authStore?.enableMfaData);

    const getWeChatConnectionStatus = () => {
        setLoading(prev => ({ ...prev, "get_we_chat_status": true }))
        if (props.currentTab == "2fa") {
            dispatchHandler(dispatch, "setupMfaRequest", {
                flowType: "GET_MFA_KEY",
                token: sessionStorage.getItem("token"),
            })
        } else {
            if (socket?.connected) {
                setErrorData(null);
                socket.emit("get_we_chat_status");
            } else {
                setErrorData("Failed to establish connection with Server");
            }
        }
    }

    useEffect(() => {
        if (setupMfaData?.isSuccess) {
            setLoading(prev => ({ ...prev, "get_we_chat_status": false }));
            setConnectionStatus({
                "2faConfiguration": setupMfaData?.data?.resource?.enableMfa ?? 0
            })
        }

        if (setupMfaData?.isError) {
            setLoading(prev => ({ ...prev, "get_we_chat_status": false }));
            setErrorData("Failed to get the status of 2FA integration.")
        }
    }, [setupMfaData])

    useEffect(() => {
        if (enableMfaData?.isSuccess) {
            setLoading(prev => ({ ...prev, "2faConfiguration": false }));
            setConnectionStatus(null);
            getWeChatConnectionStatus();
        }

        if (enableMfaData?.isError) {
            setLoading(prev => ({ ...prev, "2faConfiguration": false }));
            setErrorData("Failed to update the status of 2FA integration.")
        }
    }, [enableMfaData])

    useEffect(() => {
        getWeChatConnectionStatus();
    }, [])

    const handleSocketEvent = (name) => (content) => {
        setLoading(prev => ({ ...prev, [name]: false }));
        switch (name) {
            case "generate_we_chat_qr_code": {
                setBarcode(content);
                break;
            }
            case "get_we_chat_status": {
                setConnectionStatus(content);
                break;
            }
            case "we_chat_connection_status": {
                if (content.status === 200) {
                    getWeChatConnectionStatus();
                    setConnectToWechat(false);
                } else {
                    setErrorData(content?.message);
                }
                break;
            }
            case "disconnect_we_chat": {
                if (content.status === 200) {
                    setConnectToWechat(false);
                    setConnectionStatus(null);
                    getWeChatConnectionStatus();
                } else {
                    setErrorData(content?.message);
                }
                break;
            }
        }
    };

    useEffect(() => {
        if (socket?.connected) {
            // Handle the events here
            const events = {
                'generate_we_chat_qr_code': handleSocketEvent("generate_we_chat_qr_code"),
                'get_we_chat_status': handleSocketEvent("get_we_chat_status"),
                'we_chat_connection_status': handleSocketEvent("we_chat_connection_status"),
                'disconnect_we_chat': handleSocketEvent("disconnect_we_chat"),
            };

            Object.entries(events).forEach(([event, handler]) => {
                socket.on(event, handler);
            });

            return () => {
                Object.entries(events).forEach(([event, handler]) => {
                    socket?.off(event, handler);
                });
            };
        }
    }, [socket, handleSocketEvent]);

    const generateBarcode = (eventName = "generate_we_chat_qr_code") => {
        setBarcode(null);
        setErrorData(null);

        if (socket?.connected) {
            setLoading(prev => ({ ...prev, [eventName]: true }))

            socket.emit(eventName);
        } else {
            setErrorData("Failed to establish connection with the server.")
        }
    }
    if (loading?.["get_we_chat_status"]) {
        return <div style={{ padding: 10 }}>
            {/* <Skeleton.Input style={{width:"70vw"}}/>
            <Skeleton.Input style={{width:"70vw"}}/>
            <Skeleton.Input className='w-[200px] h-[200px] rounded'/>
            <Skeleton.Input className='w-[100px]'/> */}
            <div className='generic-skeleton w-[500px]'></div>
            <div className='generic-skeleton w-[500px]'></div>
            <div className='generic-skeleton w-[200px] !h-[200px] rounded'></div>
            <div className='generic-skeleton w-[150px] !h-[40px] mt-4'></div>
        </div>
    }

    // Function : To disconned from the we chat 
    const disconnectWithWeChat = () => {
        if (socket?.connected) {
            let updatedLoading = { ...loading, "disconnect_we_chat": true }
            setLoading(prev => ({ ...prev, "disconnect_we_chat": true }))
            socket.emit("disconnect_we_chat",);
        } else {
            setErrorData("Failed to establish connection with the server.")
        }
    }

    console.log("Loading134", loading)

    const renderContent = () => {

        switch (props.currentTab) {
            case "wechatConnect": {
                return (connectionStatus?.["weChat"] == "CONNECTED") ? <div>
                    <div className='flex items-center gap-3'>
                        <div className='p-[8px] rounded-full bg-[#DFEECC]'>
                            <Icons.WechatIcon className='h-[1.5625rem] w-[1.5625rem]' />
                        </div>
                        <div className='mb-1'>
                            <p className='text-[1rem] text-[#0F172A] font-semibold mb-0'><LanguageTranslator>WeChat account</LanguageTranslator>  <span className='text-[13px] text-[#487910] bg-[#DFEECC] px-2.5 py-0.5 rounded-full font-normal ml-3'><LanguageTranslator>Account Active</LanguageTranslator></span></p>
                            <p className='text-[#67768E] text-[13px] mb-0'><LanguageTranslator>Your account is now active until you disconnect it by your own.</LanguageTranslator></p>
                        </div>
                    </div>
                    <GenericCustomButton
                        label="Disconnect With WeChat"
                        // type="error"
                        loading={loading?.["disconnect_we_chat"]}
                        disabled={loading?.["disconnect_we_chat"]}
                        onClick={() => disconnectWithWeChat()}
                        className={"bg-[#FD3757] text-white text-[14px] font-medium px-10 py-2 mt-5 rounded hover:opacity-75"}
                    />

                </div> : <div className='p-[1rem]'>

                    <div>
                        <p className='text-[#67768E] text-[13px] pb-4 border-b border-[#EEEEEE]'><LanguageTranslator>Enabling the WeChat connection allows users to log in seamlessly and securely using their WeChat accounts. To connect, simply scan the QR code that will be generated when you click the button below.</LanguageTranslator></p>
                    </div>

                    {connectToWechat ? <div className='py-4'>
                        <p className='font-semibold mb-4'><LanguageTranslator>Scan QR to connect with your</LanguageTranslator><span className='text-[#8BC34A] ml-1.5'><LanguageTranslator>WeChat</LanguageTranslator></span> <LanguageTranslator>account</LanguageTranslator></p>

                        {
                            <div className={`w-2/3 max-w-[10.3125rem] aspect-square relative overflow-hidden ${loading?.["generate_we_chat_qr_code"] || !barcode ? 'rounded-2xl' : ''}`}>
                                {
                                    loading?.["generate_we_chat_qr_code"] || !barcode ?
                                    (
                                        loading?.["generate_we_chat_qr_code"] ?
                                        <>
                                            <Icons.DummyQRCode className='h-full w-full'/>
                                            <div className='absolute top-0 left-0 z-20 h-full w-full bg-black/70 flex items-center justify-center flex-col gap-2'><GenericSpinnerLoader /><p className='text-white font-medium'><LanguageTranslator>Loading...</LanguageTranslator></p></div>
                                        </> : 
                                        <>
                                            <Icons.DummyQRCode className='h-full w-full'/>
                                            <div className='absolute top-0 left-0 z-20 h-full w-full bg-black/70 flex items-center justify-center flex-col gap-2' style = {{borderRadius: 15}}>
                                                <button className='retryBtn p-[12px] bg-[#FD3757] rounded-full hover:opacity-75' onClick={() => generateBarcode()}>
                                                    {/* // Render the error case here */}
                                                    <Icons.RefreshIcon/>
                                                </button>
                                                <p className='text-white font-medium text-xs'><LanguageTranslator>Error Generating QR Code</LanguageTranslator></p>
                                            </div>
                                        </>
                                    )
                                    
                                    : (
                                        
                                        <div className='relative h-full w-full'>
                                            <div className='p-[8px] rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-[#DFEECC]'>
                                                <Icons.WechatIcon className='h-[1.5625rem] w-[1.5625rem]' />
                                            </div>
                                            <QRCode
                                                value={barcode}
                                                // size={'100%'}
                                                // includeMargin={true}
                                                className="!w-full !h-full object-contain object-center rounded-none p-[0] border-none"
                                            />
                                            {/* <img
                                                className='w-full h-full object-contain object-center'
                                                src={barcode}
                                            /> */}
                                        </div>
                                
                                    )
                                
                                }
                            </div>
                        }


                    </div> : <div className='py-2'>
                        <GenericCustomButton
                            sendEventAnalyticsToGoogle = {true}
                            eventAnlyticsLabel = "Connect With WeChat"
                            onClick={() => {
                                setConnectToWechat(true)
                                generateBarcode()
                            }}
                            label={() => <><Icons.WechatIcon /><span><LanguageTranslator>Connect with WeChat</LanguageTranslator></span></>}
                            className='flex items-center justify-center gap-3 px-9 py-2 bg-[#639B22] rounded-[3px] mt-3 text-white font-medium hover:opacity-75'
                        />
                    </div>}

                </div>
            }
            case "2fa": {
                return <div className='p-[1rem]'>
                    <div> <p className='text-[#67768E] text-[13px] pb-4 border-b border-[#EEEEEE]'><LanguageTranslator>Enabling Two-Factor Authentication (2FA) enhances your account security. You'll need to scan a QR code generated in Supplymint using an authentication app. Once set up, even if your password is compromised, your account will still be protected from unauthorized access.</LanguageTranslator> </p> </div>
                    <div>
                        <div className='flex gap-3'>

                            <GenericToggleComponent
                                disabled={loading?.["2faConfiguration"]}
                                loading={loading?.["2faConfiguration"]}
                                checked={connectionStatus?.["2faConfiguration"]}
                                onChange={(val) => {
                                    setLoading(prev => ({ ...prev, "2faConfiguration": true }));
                                    dispatchHandler(dispatch, "enableMfaRequest", {
                                        flowType: "ENABLE_MFA",
                                        enableMfa: connectionStatus?.["2faConfiguration"] ? '0' : '1',
                                        token: sessionStorage.getItem("token"),
                                    })
                                }}
                                className='mt-1'
                            />
                            <div>
                                <p className='text-[13px] text-[#0F172A] font-semibold mb-0.5'><LanguageTranslator>Enable 2FA Authentication</LanguageTranslator></p>
                                {connectionStatus?.["2faConfiguration"] ? <div className='border border-[#20BF6B] rounded-sm text-[#20BF6B] text-[8px] px-1 pt-[1px] pb-[0px] w-fit'><p className='mb-0'><LanguageTranslator>Enabled</LanguageTranslator></p></div> : <div className='border border-[#0F172A] rounded-sm text-[#0F172A] text-[8px] px-1 pt-[1px] pb-[0px] w-fit'><p className='mb-0'><LanguageTranslator>Disabled</LanguageTranslator></p></div>}
                            </div>
                        </div>
                        {connectionStatus?.["2faConfiguration"] ? <GenericCustomButton
                            sendEventAnalyticsToGoogle = {true}
                            label="Reconfigure 2FA"
                            // type="primary"
                            onClick={() => {
                                setSelectedModal("configure2fa")
                            }}
                            className={'border border-[#D6D9E4] rounded px-10 py-2 mt-5 font-semibold hover:opacity-75'}
                        /> : null}
                    </div>
                </div>
            }
        }
    }

    const getParentChildProps = () => {
        switch (selectedModal) {
            case "configure2fa": {
                return {
                    closeModal: () => { setSelectedModal("") },
                    animationType: "bottom",
                    // postitionProps: { top: "50%", left: "50%", transform: 'translate(-50%, -50%)', borderRadius: 0 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { maxWidth: '400px', minHeight: '400px', height: 'fit-content', maxHeight: "90vh", overflowY: 'auto' },
                    socket: socket
                }
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "configure2fa": return Configure2FA
        }
    }

    return (
        <div>
            <div className='px-4 py-5'>
                {renderContent()}
            </div>

            {errorData && <p>{errorData}</p>}

            {
                selectedModal && <ParentModal
                    getParentChildProps={getParentChildProps()}
                    childComponent={getChildComponent()}
                    closeModal={() => { setSelectedModal(false) }}
                />
            }
        </div>
    )
}

export default WeChatConnect
