/* eslint-disable no-dupe-class-members */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import GenericSearchModalNew from './tableHelperComponents/GenericSearchModalNew';
import moment from "moment";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import IconsPath from "../../assets/icons/index";
import LanguageTranslator from '../../locals/LanguageTranslator';
const { RangePicker } = DatePicker;

export default class TableMainLevelFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            filterItems: { ...this.props.filterItems },
            checkedFilters: { ...this.props.appliedFilters },
            applyFilterEnabled: false,
            headerType: this.props.filterItems["Custom Headers"] === undefined || Object.keys(this.props.filterItems["Custom Headers"] || {}).length === 0 ? "Default Headers" : "Custom Headers",
            // headerType: this.props.headersType,
            saveFilterData: {},
            textDropdown: "",
            dateDropdown: "",
            saveFilterModal: false,
            currentTab: "main",
            customHeaders: Object.keys(this.props.headersData.main.data || {}).length === 0 ? [] : Object.keys(this.props.headersData.main.data["Custom Headers"] || {}).length === 0 ? Object.keys(this.props.headersData.main.data["Default Headers"] || {}) : Object.keys(this.props.headersData.main.data["Custom Headers"] || {}),
            customHeadersType: this.props.headersData.main["Custom Headers"] === undefined || this.props.headersData.main["Custom Headers"].length === 0 ? "Default Headers" : "Custom Headers",
            tableDataPayload: this.props.tableDataPayload,
            hideSaveBtn: this.props.hideSaveButton ? this.props.hideSaveButton : false
        }
    }

    componentDidMount() {
        this.setState(prevState => {
            let checkedFilters = { ...prevState.checkedFilters };
            Object.keys(prevState.checkedFilters || {}).forEach(key => {
                if (key == "item") {
                    Object.keys(prevState.checkedFilters.item || {}).forEach(key => {
                        if (this.props.headersData.item.data.columnType[key] === "DATE" && checkedFilters[key].key || this.props.headersData.item.data.columnType[key] === "NUMBER" || this.props.headersData.item.data.columnType[key] === "TEXT") {
                            checkedFilters[key] = checkedFilters.item[key]
                        }
                        else if (this.props.headersData.item.data.columnType[key] === "LOV" || this.props.headersData.item.data.columnType[key] === "SEARCH") {
                            try {
                                checkedFilters[key] = checkedFilters.item[key].split(",");
                            }
                            catch (err) {
                                checkedFilters[key] = checkedFilters.item[key];
                            }
                        }
                    });
                }
                else {
                    if (prevState.filterItems?.columnType?.[key] === "DATE" && checkedFilters?.[key]?.key) {
                        checkedFilters[key] = {
                            ...this.getDateFromTo(checkedFilters[key].key),
                            key: checkedFilters[key].key
                        };
                    }
                    else if (prevState?.filterItems?.columnType?.[key] === "LOV" || prevState?.filterItems?.columnType?.[key] === "SEARCH") {
                        try {
                            checkedFilters[key] = checkedFilters?.[key]?.split(",");
                        }
                        catch (err) {
                            checkedFilters[key] = checkedFilters?.[key];
                        }
                    }
                }
            });
            return { checkedFilters };
        }, this.handleApplyFilterButton);
    }

    componentDidUpdate() {
        if (this.props.saveSuccess) {
            this.closeSaveFilter();
            this.props.closeFilterModal();
        }
        if (this.props.getTableDataSuccess) {
            document.querySelectorAll("tr .sticky-col").forEach(elem => {
                let temp = elem, left = 0;
                while (temp.previousElementSibling) {
                    left += temp.previousElementSibling.clientWidth;
                    temp = temp.previousElementSibling;
                }
                elem.style.left = `${left}px`;
            });
        }
    }

    handleApplyFilterButton = () => { //Enables the "Apply Filter" button if all the checked filters have a value corresponding to them.
        this.setState(prevState => {
            if (Object.keys(prevState?.checkedFilters || {})?.length === 0 || Object.values(prevState?.checkedFilters)?.some(value => value === "" || value?.length === 0 || value?.from === "" || value?.to === ""))
                return { applyFilterEnabled: false };
            else
                return { applyFilterEnabled: true };
        });
    }

    checkFilter = (key) => {
        if (Object.keys(this.state?.checkedFilters || {})?.indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key] || this.props.headersData[this.state.currentTab].data.columnType[key]) {
                case "NUMBER": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = {
                            from: "",
                            to: ""
                        };
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                case "LOV":
                case "SEARCH": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = [];
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
            }
        }
        else {
            this.setState(prevState => {
                let checkedFilters = prevState.checkedFilters;
                delete checkedFilters[key];
                return { checkedFilters };
            }, this.handleApplyFilterButton);
        }
    }

    handleInput = (e, key) => {
        try {
            e.persist();
        }
        catch (err) { }

        switch (this.state.filterItems.columnType[key]) {
            case "NUMBER": {
                if (e.target.dataset.type === "from") {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            ...checkedFilters[key],
                            from: e.target.value
                        };
                        return { checkedFilters };
                    }, () => {
                        this.handleApplyFilterButton
                        if (e.key == "Enter") { this.applyFilter(); }
                    });
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            ...checkedFilters[key],
                            to: e.target.value
                        };
                        return { checkedFilters };
                    }, () => {
                        // eslint-disable-next-line no-unused-expressions
                        this.handleApplyFilterButton
                        if (e.key == "Enter") { this.applyFilter(); }
                    });
                }
                break;
            }
            case "DATE": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, () => {
                        this.handleApplyFilterButton,
                            this.applyFilter();
                    });
                }
                else if (["SM_DATE_TODAY_VALUE", "SM_DATE_YESTERDAY_VALUE", "SM_DATE_LAST7DAYS_VALUE", "SM_DATE_LASTWEEK_VALUE", "SM_DATE_THISMONTH_VALUE", "SM_DATE_LASTMONTH_VALUE"].includes(e)) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            ...this.getDateFromTo(e),
                            key: e
                        };
                        return { checkedFilters, dateDropdown: "" };
                    }, () => {
                        this.handleApplyFilterButton,
                            this.applyFilter();
                    });
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            from: dayjs(e?.[0]).format('YYYY-MM-DD'),
                            to: dayjs(e?.[1]).format('YYYY-MM-DD')
                        };
                        return { checkedFilters };
                    }, () => {
                        this.handleApplyFilterButton,
                            this.applyFilter();
                    });
                }
                break;
            }
            case "TIME": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            from: dayjs(e[0]).format('YYYY-MM-DD HH:mm'),
                            to: dayjs(e[1]).format('YYYY-MM-DD HH:mm')
                        };
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                break;
            }
            case "LOV": {
                this.setState(prevState => {
                    let checkedFilters = { ...prevState.checkedFilters }, index = checkedFilters[key].indexOf(e.target.dataset.value);
                    if (!~index) {
                        checkedFilters[key].push(e.target.dataset.value);
                    }
                    else {
                        checkedFilters[key].splice(index, 1);
                    }
                    return { checkedFilters };
                }, this.handleApplyFilterButton);
                break;
            }
            case "SEARCH": {
                this.setState(prevState => {
                    let checkedFilters = prevState.checkedFilters;
                    checkedFilters[key] = [];
                    return { checkedFilters };
                }, this.handleApplyFilterButton);
                break;
            }
            default: {
                this.setState(prevState => {
                    let checkedFilters = { ...prevState.checkedFilters };
                    checkedFilters[key] = e.target.dataset.value || (e.target.parentElement.dataset.value || (e.target.value || ""));
                    return { checkedFilters };
                }, () => {
                    this.handleApplyFilterButton
                    if (e.key == "Enter" || this.state.textDropdown == key) { this.applyFilter(); }
                });
            }
        }
    }

    selectItems = (field, items) => {
        this.setState(prevState => {
            let checkedFilters = { ...prevState.checkedFilters };
            checkedFilters[field.filterKey] = items;
            return { checkedFilters };
        }, this.handleApplyFilterButton);
    }

    getDateFromTo = key => {
        switch (key) {
            case "SM_DATE_TODAY_VALUE": {
                return {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_YESTERDAY_VALUE": {
                return {
                    from: moment().subtract(1, "day").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LAST7DAYS_VALUE": {
                return {
                    from: moment().subtract(7, "days").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTWEEK_VALUE": {
                return {
                    from: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_THISMONTH_VALUE": {
                return {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTMONTH_VALUE": {
                return {
                    from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                };
            }
            default: {
                return {
                    from: "",
                    to: ""
                };
            }
        }
    }

    showTextDropdown = key => (e) => {
        if (Object.keys(this.state.checkedFilters || {}).indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key]) {
                case "LOV": {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = [];
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
            }
        }
        this.setState({
            textDropdown: key
        })
        document.addEventListener("click", this.hideTextDropdown)
        e.stopPropagation()
    }

    hideTextDropdown = () => {
        this.setState({
            textDropdown: ""
        })
        document.removeEventListener("click", this.hideTextDropdown)
    }

    showSelectDropdown = key => (e) => {
        if (Object.keys(this.state.checkedFilters || {}).indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key]) {
                case "LOV": {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = [];
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
            }
        }
        this.setState({
            selectDropdown: key
        });
        document.addEventListener("click", this.hideSelectDropdown)
        e.stopPropagation();
    }

    hideSelectDropdown = e => {
        let path = e.path || (e.composedPath && e.composedPath());
        if (!path.some(elem => elem.id === "genericFilterSelectDropdown")) {
            this.setState({
                selectDropdown: ""
            });
            document.removeEventListener("click", this.hideSelectDropdown)
        }
    }

    renderExtraFooter = key => () => {
        return (
            <span className="date-picker-extra-footer">
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_TODAY_VALUE"} onChange={e => this.handleInput("SM_DATE_TODAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Today</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_YESTERDAY_VALUE"} onChange={e => this.handleInput("SM_DATE_YESTERDAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Yesterday</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE"} onChange={e => this.handleInput("SM_DATE_LAST7DAYS_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last 7 Days</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTWEEK_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTWEEK_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Week</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_THISMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_THISMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">This Month</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Month</span>
                </label>
            </span>
        );
    }

    applyFilter = () => {
        if (this.props.actionBarConfig?.filters?.isItemRequire) {
            this.applyFilterOnItemLevel()
        }
        else {
            let filter = {};
            Object.keys(this.state.checkedFilters || {}).forEach(key => {
                if (this.state.filterItems.columnType[key] === "LOV" || this.state.filterItems.columnType[key] === "SEARCH") {
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else {
                    filter[key] = this.state.checkedFilters[key];
                }
            });
            this.props.applyFilter(filter)();
        }
    }

    applyFilterOnItemLevel = () => {
        let filter = {};
        let item = {};

        Object.keys(this.state.checkedFilters || {}).map(key => {
            if (this.props.headersData.main.data.columnType[key] === "LOV" || this.props.headersData.main.data.columnType[key] === "SEARCH" || this.props.headersData.item.data.columnType[key] === "LOV" || this.props.headersData.item.data.columnType[key] === "SEARCH") {
                if (this.props.headersData.main.data[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else if (this.props.headersData.item.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key].join();
                }
            }
            else {
                if (this.props.headersData?.main?.data?.[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters?.[key]
                }
                else if (this.props?.headersData?.item?.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key]
                }
            }
        });
        this.props.applyFilter({ ...filter, item })();
    }

    saveFilters = () => {
        this.openSaveFilter();
        let filter = {};
        Object.keys(this.state.checkedFilters || {}).forEach(key => {
            if (this.state.filterItems.columnType[key] === "LOV" || this.state.filterItems.columnType[key] === "SEARCH") {
                filter[key] = this.state.checkedFilters[key].join();
            }
            else {
                filter[key] = this.state.checkedFilters[key];
            }
        });
        this.setState({ saveFilterData: filter });
    }

    saveMainItemFilters = () => {
        this.openSaveFilter();
        let filter = {};
        let item = {};
        Object.keys(this.state.checkedFilters || {}).forEach(key => {
            if (this.state.filterItems.columnType[key] === "LOV" || this.state.filterItems.columnType[key] === "SEARCH") {
                if (this.props.headersData.main.data[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else if (this.props.headersData.item.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key].join();
                }
            }
            else {
                if (this.props.headersData.main.data[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters[key]
                }
                else if (this.props.headersData.item.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key]
                }
            }
        });
        let finalFilter = { ...filter, item }
        this.setState({ saveFilterData: finalFilter });
    }

    openSaveFilter = () => {
        this.setState({
            saveFilterModal: !this.state.saveFilterModal,
        });
    }

    closeSaveFilter = () => {
        this.setState({
            saveFilterModal: false
        });
    }
    onClear = () => {
        this.setState({ checkedFilters: {} })
    }

    clearCurrentFilter = (key) => {
        this.checkFilter(key);
        setTimeout(() => {
            this.applyFilter();
        }, 50);
    }

    // Save Filters Functionality
    saveFilters = () => {
        this.openSaveFilter();
        let filter = {};
        Object.keys(this.state.tableDataPayload.filter || {}).forEach(key => {
            if (this.state.headersData.main.columnType[key] === "LOV" || this.state.headersData.main.columnType[key] === "SEARCH") {
                // filter[key] = this.state.tableDataPayload.filter[key].join();
                try {
                    filter[key] = this.state.tableDataPayload.filter[key].join();
                }
                catch (err) {
                    filter[key] = this.state.tableDataPayload.filter[key];
                }
            }
            else {
                filter[key] = this.state.tableDataPayload.filter[key];
            }
        });
        this.setState({ saveFilterData: filter });
    }

    checkFilterExist = () => {
        let keys = Object.keys(this.props.appliedFilters || {});
        if (keys.length === 0) return false;
        if (keys.length > 1) {
            return true;
        } else {
            let itemIndex = keys.indexOf("item");
            if (itemIndex !== -1) {
                let itemKeys = this.props.appliedFilters.item && Object.keys(this.props.appliedFilters.item || {});
                if (itemKeys.length > 0) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        }
    }
    render() {
        return (
            this.props.viewType == 'grid' ? <div className='flex flex-col gap-1 h-screen text-[13px]'>
                <h2 className='font-bold text-lg w-full p-[1rem] border-b'>Filters</h2>
                <div className='imtmp-filters px-4 pb-4 w-full flex-1 space-y-3 overflow-y-auto overflow-x-clip'>
                    {this.props.stickyCols && this.props.stickyCols.map(key => <div key={key} className="filter-table-header sticky-col">
                        {this.state.filterItems.columnType[key] === "NOSORT" ? <span className="asnfbb-right not-checked"></span> : this.state.filterItems.columnType[key] === "NUMBER" ? <div className="asnfbb-right !flex min-w-200">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='p-[8px] border'>
                                <input
                                    type="number" autoComplete="off"
                                    data-type="from"
                                    id={key + "From"}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                    placeholder="From"
                                    onChange={(e) => this.handleInput(e, key)}
                                    onKeyDown={(e) => this.handleInput(e, key)}
                                    className="asnfbb-from w-full"
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear-from" onClick={() => this.clearCurrentFilter(key)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                    </svg>
                                </span>}
                            </div>
                            <div className='p-[8px] border'>
                                <input
                                    type="number" autoComplete="off"
                                    data-type="to"
                                    className="asnfbb-to w-full"
                                    id={key + "To"}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                    placeholder="To"
                                    onChange={(e) => this.handleInput(e, key)}
                                    onKeyDown={(e) => this.handleInput(e, key)}
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear-to" onClick={() => this.clearCurrentFilter(key)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                    </svg>
                                </span>}
                            </div>
                        </div> : this.state.filterItems.columnType[key] === "DATE" ? <div className="asnfbb-right asnfbb-date-field">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='flex justify-between gap-2 items-center'>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    id={key}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                    onChange={(e) => this.handleInput(e, key)}
                                    renderExtraFooter={this.renderExtraFooter(key)}
                                    onOpenChange={open => open ? this.setState({ dateDropdown: key }) : this.setState({ dateDropdown: "" })}
                                    open={this.state.dateDropdown === key}
                                    className='!w-full !h-[37.5px] !border-none'
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                    </svg>
                                </span>}
                            </div>
                        </div> : this.state.filterItems.columnType[key] === "TIME" ? <div className="asnfbb-right asnfbb-date-field">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='flex justify-between gap-2 items-center'>
                                <RangePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ format: "HH:mm", defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                                    id={key}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                    onChange={(e) => this.handleInput(e, key)}
                                    className="!w-full !h-[37.5px] !border-none"
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                    </svg>
                                </span>}
                            </div>
                        </div> : this.state.filterItems.columnType[key] === "LOV" ? <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <input
                                type="text" autoComplete="off"
                                id={key}
                                placeholder="Select Options"
                                value={this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                readOnly
                            />
                            {this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].length > 0 ? <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span> :
                                <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                            {this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}
                            {this.state.selectDropdown == key && <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                <ul className="dropdown-menu-city-item">
                                    {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item =>
                                        <li key={item} className='hover:bg-gray-100 w-full'>
                                            <label className="checkBoxLabel0">
                                                <input
                                                    type="checkBox"
                                                    checked={this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].indexOf(item) !== -1}
                                                    onChange={(e) => this.handleInput(e, key)}
                                                    data-value={item}
                                                />
                                                <span className="checkmark1"></span>
                                                {item}
                                            </label>
                                        </li>
                                    )}
                                </ul>
                                <div className={`gen-dropdown-pagination`}>
                                    <div className="page-close">
                                        <button type="button" className='gdp-done' onClick={this.applyFilter}>Done</button>
                                    </div>
                                </div>
                            </div>}
                        </span> : this.state.filterItems.columnType[key] === "SEARCH" ? <span className="asnfbb-right search">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='relative'>
                                <GenericSearchModalNew
                                    field={{
                                        filterKey: key,
                                        entity: this.state.filterItems.tableName[key],
                                        key: this.state.filterItems.tableColumnMap[key],
                                        // value: this.state.filterItems[this.state.headerType][key]
                                        value: "All"
                                    }}
                                    callApiOnClick={false}
                                    emptyAttributes={true}
                                    showLabel={false}
                                    selectedItems={{ [this.state.filterItems.tableColumnMap[key]]: this.state.checkedFilters[key] }}
                                    selectItems={this.selectItems}
                                    isDoneRequire={true}
                                    done={this.applyFilter}
                                    isClearRequire={true}
                                    clearFilter={() => this.checkFilter(key)}
                                    pasteConfiguration = {{
                                        "enabled" : true,
                                    }}
                                    {...this.props}
                                />
                            </div>
                        </span> : <div className="asnfbb-right select-right">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='border'>
                                <div className='flex justify-between gap-2 items-center p-[8px]'>
                                    <input
                                        type="text"
                                        id={key}
                                        value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                        placeholder="Value"
                                        onChange={(e) => this.handleInput(e, key)}
                                        onKeyDown={(e) => this.handleInput(e, key)}
                                        disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                                        className='w-full'
                                    />
                                    {this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" || this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" || this.state.checkedFilters[key] ? <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                            <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                            <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                        </svg>
                                    </span> :
                                        <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                                </div>
                                {this.state.textDropdown == key && <div className="asnfbbr-inner pb-1" >
                                    <ul className="asnfbbr-all-vendor list-none">
                                        <li data-value="SM_TEXT_EMPTY_VALUE" className='hover:bg-gray-100 w-full px-[8px] py-1' onClick={(e) => this.handleInput(e, key)}>
                                            <button>is Empty</button>
                                        </li>
                                        <li data-value="SM_TEXT_NOT_EMPTY_VALUE" className='hover:bg-gray-100 w-full px-[8px] py-1' onClick={(e) => this.handleInput(e, key)}>
                                            <button>is Not Empty</button>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>}
                    </div>
                    )}
                    {Object.keys(this.state.filterItems[this.state.headerType] || {}).map((key) =>
                        this.props.stickyCols && this.props.stickyCols.includes(key) ? null :
                            <div key={key} className="filter-table-header">
                                {this.state.filterItems.columnType[key] === "NOSORT" ?
                                    <span className="asnfbb-right not-checked"></span> :
                                    this.state.filterItems.columnType[key] === "NUMBER" ?
                                        <div className="asnfbb-right min-w-200">
                                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                            <div className='w-full flex gap-3'>
                                                <div className='p-[8px] border flex-1'>

                                                    <input
                                                        type="number" autoComplete="off"
                                                        data-type="from"
                                                        id={key + "From"}
                                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                                        placeholder="From"
                                                        onChange={(e) => this.handleInput(e, key)}
                                                        onKeyDown={(e) => this.handleInput(e, key)}
                                                        className="asnfbb-from w-full"
                                                    />
                                                    {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                                        <span class="gsis-clear-from" onClick={() => this.clearCurrentFilter(key)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                            </svg>
                                                        </span>}
                                                </div>
                                                <div className='p-[8px] border flex-1'>

                                                    <input
                                                        type="number" autoComplete="off"
                                                        data-type="to"
                                                        className="asnfbb-to w-full"
                                                        id={key + "To"}
                                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                                        placeholder="To"
                                                        onChange={(e) => this.handleInput(e, key)}
                                                        onKeyDown={(e) => this.handleInput(e, key)}
                                                    />
                                                    {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                                        <span class="gsis-clear-to" onClick={() => this.clearCurrentFilter(key)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                            </svg>
                                                        </span>}
                                                </div>
                                            </div>
                                        </div> :
                                        this.state.filterItems.columnType[key] === "DATE" ?
                                            <div className="asnfbb-right asnfbb-date-field">
                                                {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                <div className='flex justify-between gap-2 items-center'>
                                                    <RangePicker
                                                        format="YYYY-MM-DD"
                                                        id={key}
                                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                                        onChange={(e) => this.handleInput(e, key)}
                                                        renderExtraFooter={this.renderExtraFooter(key)}
                                                        onOpenChange={open => open ? this.setState({ dateDropdown: key }) : this.setState({ dateDropdown: "" })}
                                                        open={this.state.dateDropdown === key}
                                                        className='!w-full !h-[37.5px] !border-none'
                                                    />
                                                    {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                                        <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                            </svg>
                                                        </span>}
                                                </div>
                                            </div> :
                                            this.state.filterItems.columnType[key] === "TIME" ?
                                                <div className="asnfbb-right asnfbb-date-field">
                                                    {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                    <div className='flex justify-between gap-2 items-center'>

                                                        <RangePicker
                                                            format="YYYY-MM-DD HH:mm"
                                                            showTime={{ format: "HH:mm", defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                                                            id={key}
                                                            value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                                            onChange={(e) => this.handleInput(e, key)}
                                                            className='!w-full !h-[37.5px] !border-none'
                                                        />
                                                        {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                                            <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                                </svg>
                                                            </span>}
                                                    </div>
                                                </div> :
                                                this.state.filterItems.columnType[key] === "LOV" ?
                                                    <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                                                        {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                        <input
                                                            type="text"
                                                            id={key}
                                                            placeholder="Select Options"
                                                            value={this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                                            readOnly
                                                        />
                                                        {this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].length > 0 ?
                                                            <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                                </svg>
                                                            </span> :
                                                            <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                                                        {this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}

                                                        {this.state.selectDropdown == key &&
                                                            <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                                                <ul className="dropdown-menu-city-item">
                                                                    {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item =>
                                                                        <li key={item} className='hover:bg-gray-100 w-full'>
                                                                            <label className="checkBoxLabel0">
                                                                                <input
                                                                                    type="checkBox"
                                                                                    checked={this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].indexOf(item) !== -1}
                                                                                    onChange={(e) => this.handleInput(e, key)}
                                                                                    data-value={item}
                                                                                />
                                                                                <span className="checkmark1"></span>
                                                                                {item}
                                                                            </label>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                <div className={`gen-dropdown-pagination`}>
                                                                    <div className="page-close">
                                                                        <button type="button" className='gdp-done' onClick={this.applyFilter}>Done</button>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </span> :
                                                    this.state.filterItems.columnType[key] === "SEARCH" ?
                                                        <span className="asnfbb-right search">
                                                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                            <div className='relative'>
                                                                <GenericSearchModalNew
                                                                    field={{
                                                                        filterKey: key,
                                                                        entity: this.state.filterItems.tableName[key],
                                                                        key: this.state.filterItems.tableColumnMap[key],
                                                                        // value: this.state.filterItems[this.state.headerType][key]
                                                                        value: "All"
                                                                    }}
                                                                    callApiOnClick={false}
                                                                    emptyAttributes={true}
                                                                    showLabel={false}
                                                                    selectedItems={{ [this.state.filterItems.tableColumnMap[key]]: this.state.checkedFilters[key] }}
                                                                    selectItems={this.selectItems}
                                                                    isDoneRequire={true}
                                                                    done={this.applyFilter}
                                                                    isClearRequire={true}
                                                                    clearFilter={() => this.checkFilter(key)}
                                                                    pasteConfiguration = {{
                                                                        "enabled" : true,
                                                                    }}
                                                                    {...this.props}
                                                                />
                                                            </div>
                                                        </span> :
                                                        <span className="asnfbb-right select-right">
                                                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                            <div className='border'>

                                                                <div className='flex justify-between gap-2 items-center p-[8px]'>
                                                                    <input
                                                                        type="text"
                                                                        id={key}
                                                                        value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                                                        placeholder="Value"
                                                                        onChange={(e) => this.handleInput(e, key)}
                                                                        onKeyDown={(e) => this.handleInput(e, key)}
                                                                        disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                                                                        className='w-full'
                                                                    />
                                                                    {this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" || this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" || this.state.checkedFilters[key] ?
                                                                        <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                                                <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                                <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                                            </svg>
                                                                        </span> :
                                                                        <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                                                                </div>
                                                                {this.state.textDropdown == key &&
                                                                    <div className="asnfbbr-inner pb-1">
                                                                        <ul className="asnfbbr-all-vendor list-none">
                                                                            <li data-value="SM_TEXT_EMPTY_VALUE" className='hover:bg-gray-100 w-full px-[8px] py-1' onClick={(e) => this.handleInput(e, key)}>
                                                                                <button>is Empty</button>
                                                                            </li>
                                                                            <li data-value="SM_TEXT_NOT_EMPTY_VALUE" className='hover:bg-gray-100 w-full px-[8px] py-1' onClick={(e) => this.handleInput(e, key)}>
                                                                                <button>is Not Empty</button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>}
                                                            </div>
                                                        </span>}
                            </div>
                    )}
                </div>
            </div> : <tr>
                <th className='fix-action-btn'>
                    {this.checkFilterExist() ? <React.Fragment>
                        <div className='!flex items-center w-100 p-lr-8'>
                            <LanguageTranslator tag="label" className='clearAll' onClick={this.props.clearMainFilter}>Clear All</LanguageTranslator>
                            <span className='save-icon' onClick={this.props.saveFilters}>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.26664 1.86583C3.04564 1.86583 2.83368 1.95363 2.67741 2.1099C2.52113 2.26618 2.43334 2.47813 2.43334 2.69914V14.3654C2.43334 14.5864 2.52113 14.7984 2.67741 14.9547C2.83368 15.1109 3.04564 15.1987 3.26664 15.1987H14.9329C15.1539 15.1987 15.3659 15.1109 15.5222 14.9547C15.6784 14.7984 15.7662 14.5864 15.7662 14.3654V5.54423L12.0879 1.86583H3.26664ZM1.49893 0.931428C1.96776 0.462602 2.60362 0.199219 3.26664 0.199219H12.433C12.654 0.199219 12.866 0.287013 13.0223 0.443289L17.1888 4.60982C17.3451 4.7661 17.4329 4.97805 17.4329 5.19906V14.3654C17.4329 15.0285 17.1695 15.6643 16.7006 16.1331C16.2318 16.602 15.596 16.8654 14.9329 16.8654H3.26664C2.60362 16.8654 1.96776 16.602 1.49893 16.1331C1.03011 15.6643 0.766724 15.0285 0.766724 14.3654V2.69914C0.766724 2.03612 1.03011 1.40025 1.49893 0.931428Z" fill="#5D5FEF" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.09995 9.36559C4.09995 8.90537 4.47303 8.53229 4.93326 8.53229H13.2663C13.7265 8.53229 14.0996 8.90537 14.0996 9.36559V16.032C14.0996 16.4923 13.7265 16.8654 13.2663 16.8654C12.8061 16.8654 12.433 16.4923 12.433 16.032V10.1989H5.76656V16.032C5.76656 16.4923 5.39348 16.8654 4.93326 16.8654C4.47303 16.8654 4.09995 16.4923 4.09995 16.032V9.36559Z" fill="#5D5FEF" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.93326 0.199219C5.39348 0.199219 5.76656 0.572303 5.76656 1.03253V4.36575H11.5997C12.0599 4.36575 12.433 4.73884 12.433 5.19906C12.433 5.65928 12.0599 6.03237 11.5997 6.03237H4.93326C4.47303 6.03237 4.09995 5.65928 4.09995 5.19906V1.03253C4.09995 0.572303 4.47303 0.199219 4.93326 0.199219Z" fill="#5D5FEF" />
                                </svg>
                                <span className='generic-tooltip'>Save</span>
                            </span>
                        </div>
                    </React.Fragment> : null}
                </th>
                {this.props.stickyCols && this.props.stickyCols.map(key => <th key={key} className="filter-table-header sticky-col">
                    {this.state.filterItems.columnType[key] === "NOSORT" ?
                        <span className="asnfbb-right not-checked"></span> :
                        this.state.filterItems.columnType[key] === "NUMBER" ?
                            <div className="asnfbb-right !flex min-w-200">
                                {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                <input
                                    type="number" autoComplete="off"
                                    data-type="from"
                                    id={key + "From"}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                    placeholder="From"
                                    onChange={(e) => this.handleInput(e, key)}
                                    onKeyDown={(e) => this.handleInput(e, key)}
                                    className="asnfbb-from w-full"
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                    <span class="gsis-clear-from" onClick={() => this.clearCurrentFilter(key)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                            <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                            <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                        </svg>
                                    </span>}
                                <input
                                    type="number" autoComplete="off"
                                    data-type="to"
                                    className="asnfbb-to w-full"
                                    id={key + "To"}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                    placeholder="To"
                                    onChange={(e) => this.handleInput(e, key)}
                                    onKeyDown={(e) => this.handleInput(e, key)}
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                    <span class="gsis-clear-to" onClick={() => this.clearCurrentFilter(key)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                            <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                            <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                        </svg>
                                    </span>}
                            </div> :
                            this.state.filterItems.columnType[key] === "DATE" ?
                                <div className="asnfbb-right asnfbb-date-field">
                                    {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                    <div className='flex justify-between gap-2 items-center'>
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            id={key}
                                            value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                            onChange={(e) => this.handleInput(e, key)}
                                            renderExtraFooter={this.renderExtraFooter(key)}
                                            onOpenChange={open => open ? this.setState({ dateDropdown: key }) : this.setState({ dateDropdown: "" })}
                                            open={this.state.dateDropdown === key}
                                            className='!w-full !h-[37.5px] !border-none'
                                        />
                                        {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                            <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                </svg>
                                            </span>}
                                    </div>
                                </div> :
                                this.state.filterItems.columnType[key] === "TIME" ?
                                    <div className="asnfbb-right asnfbb-date-field">
                                        {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                        <div className='flex justify-between gap-2 items-center'>
                                            <RangePicker
                                                format="YYYY-MM-DD HH:mm"
                                                showTime={{ format: "HH:mm", defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                                                id={key}
                                                value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                                onChange={(e) => this.handleInput(e, key)}
                                                className='!w-full !h-[37.5px] !border-none'
                                            />
                                            {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                                <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                    </svg>
                                                </span>}
                                        </div>
                                    </div> :
                                    this.state.filterItems.columnType[key] === "LOV" ?
                                        <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                            <input
                                                type="text" autoComplete="off"
                                                id={key}
                                                placeholder="Select Options"
                                                value={this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                                readOnly
                                            />
                                            {this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].length > 0 ?
                                                <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                    </svg>
                                                </span> :
                                                <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                                            {this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}

                                            {this.state.selectDropdown == key &&
                                                <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                                    <ul className="dropdown-menu-city-item">
                                                        {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item =>
                                                            <li key={item} className='hover:bg-gray-100 w-full'>
                                                                <label className="checkBoxLabel0">
                                                                    <input
                                                                        type="checkBox"
                                                                        checked={this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].indexOf(item) !== -1}
                                                                        onChange={(e) => this.handleInput(e, key)}
                                                                        data-value={item}
                                                                    />
                                                                    <span className="checkmark1"></span>
                                                                    {item}
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    <div className={`gen-dropdown-pagination`}>
                                                        <div className="page-close">
                                                            <button type="button" className='gdp-done' onClick={this.applyFilter}>Done</button>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </span> :
                                        this.state.filterItems.columnType[key] === "SEARCH" ?
                                            <span className="asnfbb-right search">
                                                {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                <div className='relative'>
                                                    <GenericSearchModalNew
                                                        field={{
                                                            filterKey: key,
                                                            entity: this.state.filterItems.tableName[key],
                                                            key: this.state.filterItems.tableColumnMap[key],
                                                            // value: this.state.filterItems[this.state.headerType][key]
                                                            value: "All"
                                                        }}
                                                        callApiOnClick={false}
                                                        emptyAttributes={true}
                                                        showLabel={false}
                                                        selectedItems={{ [this.state.filterItems.tableColumnMap[key]]: this.state.checkedFilters[key] }}
                                                        selectItems={this.selectItems}
                                                        isDoneRequire={true}
                                                        done={this.applyFilter}
                                                        isClearRequire={true}
                                                        clearFilter={() => this.checkFilter(key)}
                                                        pasteConfiguration = {{
                                                            "enabled" : true,
                                                        }}
                                                        {...this.props}
                                                    />
                                                </div>
                                            </span> :
                                            <span className="asnfbb-right select-right">
                                                {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                                                <input
                                                    type="text"
                                                    id={key}
                                                    value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                                    placeholder="Value"
                                                    onChange={(e) => this.handleInput(e, key)}
                                                    onKeyDown={(e) => this.handleInput(e, key)}
                                                    disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                                                />
                                                {this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" || this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" || this.state.checkedFilters[key] ?
                                                    <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                            <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                            <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                        </svg>
                                                    </span> :
                                                    <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                                                {this.state.textDropdown == key &&
                                                    <span className="asnfbbr-inner">
                                                        <span className="asnfbbr-all-vendor">
                                                            <li data-value="SM_TEXT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                                <button>is Empty</button>
                                                            </li>
                                                            <li data-value="SM_TEXT_NOT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                                <button>is Not Empty</button>
                                                            </li>
                                                        </span>
                                                    </span>}
                                            </span>}
                </th>)}
                {Object.keys(this.state.filterItems[this.state.headerType] || {}).map((key) => this.props.stickyCols && this.props.stickyCols.includes(key) ? null : <th key={key} className="filter-table-header">
                    {this.state.filterItems.columnType[key] === "NOSORT" ? <span className="asnfbb-right not-checked"></span> :
                        this.state.filterItems.columnType[key] === "NUMBER" ? <span className="asnfbb-right !flex min-w-200">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <input
                                type="number" autoComplete="off"
                                data-type="from"
                                id={key + "From"}
                                value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                placeholder="From"
                                onChange={(e) => this.handleInput(e, key)}
                                onKeyDown={(e) => this.handleInput(e, key)}
                                className="asnfbb-from w-full"
                            />
                            {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear-from" onClick={() => this.clearCurrentFilter(key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span>}
                            <input
                                type="number" autoComplete="off"
                                data-type="to"
                                className="asnfbb-to w-full"
                                id={key + "To"}
                                value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                placeholder="To"
                                onChange={(e) => this.handleInput(e, key)}
                                onKeyDown={(e) => this.handleInput(e, key)}
                            />
                            {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear-to" onClick={() => this.clearCurrentFilter(key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span>}
                        </span> : this.state.filterItems.columnType[key] === "DATE" ? <div className="asnfbb-right asnfbb-date-field">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='flex justify-between gap-2 items-center'>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    id={key}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                    onChange={(e) => this.handleInput(e, key)}
                                    renderExtraFooter={this.renderExtraFooter(key)}
                                    onOpenChange={open => open ? this.setState({ dateDropdown: key }) : this.setState({ dateDropdown: "" })}
                                    open={this.state.dateDropdown === key}
                                    className="!w-full !h-[37.5px] !border-none"
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null :
                                    <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                            <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                            <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                        </svg>
                                    </span>}
                            </div>
                        </div> : this.state.filterItems.columnType[key] === "TIME" ? <div className="asnfbb-right asnfbb-date-field">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='flex justify-between gap-2 items-center'>
                                <RangePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ format: "HH:mm", defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                                    id={key}
                                    value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                    onChange={(e) => this.handleInput(e, key)}
                                    className='!w-full !h-[37.5px] !border-none'
                                />
                                {this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                        <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                        <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                    </svg>
                                </span>}
                            </div>
                        </div> : this.state.filterItems.columnType[key] === "LOV" ? <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <input
                                type="text"
                                id={key}
                                placeholder="Select Options"
                                value={this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                readOnly
                            />
                            {this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].length > 0 ? <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span> : <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                            {this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}
                            {this.state.selectDropdown == key && <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                <ul className="dropdown-menu-city-item">
                                    {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item => <li key={item} className='hover:bg-gray-100 w-full'>
                                        <label className="checkBoxLabel0">
                                            <input
                                                type="checkBox"
                                                checked={this.state.checkedFilters && this.state.checkedFilters[key] && this.state.checkedFilters[key].indexOf(item) !== -1}
                                                onChange={(e) => this.handleInput(e, key)}
                                                data-value={item}
                                            />
                                            <span className="checkmark1"></span>
                                            {item}
                                        </label>
                                    </li>
                                    )}
                                </ul>
                                <div className={`gen-dropdown-pagination`}>
                                    <div className="page-close">
                                        <button type="button" className='gdp-done' onClick={this.applyFilter}>Done</button>
                                    </div>
                                </div>
                            </div>}
                        </span> : this.state.filterItems.columnType[key] === "SEARCH" ? <span className="asnfbb-right search">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <div className='relative'>
                                <GenericSearchModalNew
                                    field={{
                                        filterKey: key,
                                        entity: this.state.filterItems.tableName[key],
                                        key: this.state.filterItems.tableColumnMap[key],
                                        // value: this.state.filterItems[this.state.headerType][key]
                                        value: "All"
                                    }}
                                    callApiOnClick={false}
                                    emptyAttributes={true}
                                    showLabel={false}
                                    selectedItems={{ [this.state.filterItems.tableColumnMap[key]]: this.state.checkedFilters[key] }}
                                    selectItems={this.selectItems}
                                    isDoneRequire={true}
                                    done={this.applyFilter}
                                    isClearRequire={true}
                                    clearFilter={() => this.checkFilter(key)}
                                    pasteConfiguration = {{
                                        "enabled" : true,
                                    }}
                                    {...this.props}
                                />
                            </div>
                        </span> : <span className="asnfbb-right select-right">
                            {this.props.viewType === 'grid' && <label className='fth-label mb-[0.3rem]'>{this?.state?.filterItems?.[this?.state?.headerType]?.[key]}</label>}
                            <input
                                type="text"
                                id={key}
                                value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                placeholder="Value"
                                onChange={(e) => this.handleInput(e, key)}
                                onKeyDown={(e) => this.handleInput(e, key)}
                                disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                            />
                            {this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" || this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" || this.state.checkedFilters[key] ? <span class="gsis-clear" onClick={() => this.clearCurrentFilter(key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span> : <IconsPath.DownArrowNew className="asnfbbr-input-arrow" onClick={this.showTextDropdown(key)} />}
                            {this.state.textDropdown == key && <span className="asnfbbr-inner">
                                <span className="asnfbbr-all-vendor">
                                    <li data-value="SM_TEXT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                        <button>is Empty</button>
                                    </li>
                                    <li data-value="SM_TEXT_NOT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                        <button>is Not Empty</button>
                                    </li>
                                </span>
                            </span>}
                        </span>
                    }
                </th>
                )}
            </tr>
        )
    }
}