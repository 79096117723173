import { takeLatest, all, takeEvery } from "redux-saga/effects";

import {
  generateGlobalConstant,
  generateRequestAction,
} from "./StateData/apiFunction";
import { APIState } from "./StateData/apiState";
import { generateUpperCase } from "./StateData/commonFunction";

export default function* rootSaga() {
  const sagaEffects = [];

  let valX = generateGlobalConstant();
  let valY = generateRequestAction()


  for (const item in APIState) {
    // console.log(7, item , new Date())
    for (const stateListItem of APIState[item]) {
      const actionType = valX[`${generateUpperCase(stateListItem.actionPrefix)}_REQUEST`];
      const actionFunction = valY[`${stateListItem.actionPrefix}Request`];
      sagaEffects.push(stateListItem?.takeEvery ? takeEvery(actionType, actionFunction) : takeLatest(actionType, actionFunction))
    }
    // console.log(8, item, new Date())
  }

  yield all(sagaEffects);
}
