import React, { useEffect, useState } from 'react'
import GenericCustomButton from '../genericComponents/GenericCustomButton'
import Icons from '../assets/icons'
import GenericInput from '../genericComponents/input/GenericInputComponent'
import { ChevronDown, CircleX, X } from 'lucide-react'
import LanguageTranslator from './LanguageTranslator'
import { parseJwt } from '../helper/genericFunction'
import ParentModal from '../genericComponents/ParentModal'
import GenericFormHeaderComponent from '../genericComponents/forms/GenericFormHeaderComponent'
import { dispatchHandler } from '../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { getPermissionKey } from '../helper/permissions'

const Component = (props) => {
    return (
        props.child
    )
}

const LanguagePopup = (props) => {
    let { currentLanguage, setCurrentLanguage, type } = props;
    let parseToken = parseJwt(sessionStorage.getItem("token"))
    const [language, setLanguage] = useState(parseToken?.language ?? "en")
    const [languagePopup, setLanguagePopup] = useState(false);

    const editUserData = useSelector(state => state?.authStore?.editUserData);

    let lang = {
        "en": "English",
        "zh": "Chinese ( 中文 )",
    }

    let newLang = {
        "en" : "English - EN",
        "zh" : "Chinese - ZH",
        "en-HS" : "English - HS"
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.type === 2 && localStorage?.getItem("LANGUAGE_POPUP")) {
            setLanguagePopup(true);
        }
    }, [])

    useEffect(() => {
        if (editUserData?.isSuccess) {
            setLanguagePopup(false);
            localStorage.removeItem("LANGUAGE_POPUP")
            if (localStorage?.getItem("CURRENT_LANGUAGE") != language) {
                localStorage?.setItem("CURRENT_LANGUAGE", language);
                window.location.reload();
            }
        }
    }, [editUserData?.isSuccess])

    const renderLanguageInput = () => {
        return (
            <GenericInput
                label={type === 2 ? "Choose Language" : null}
                iconRight={<ChevronDown color='white' size={17} />}
                value={(props.type === 2 ? newLang?.[language] || "" : lang?.[currentLanguage || "en"])}
                type="dropdown"
                onChange={val => {
                    if (type === 2) {
                        setLanguage(val?.key);
                    } else {
                        localStorage.setItem("CURRENT_LANGUAGE", val?.key)
                        setCurrentLanguage(val?.key);
                        props.setCurrentLanguage(val?.key)
                        window.location?.reload()
                    }
                }}
                inputProps={() => ({
                    headerKeys: {
                        "language": {}
                    },
                    itemKeys: {
                        "language": ["language"]
                    },
                    getListData: () => (props.type === 2 ?
                        [
                            { language: "English EN", key: "en" }, { language: "English-HS", key: "en-HS" }, { language: "Chinese ZH", key: "zh" }
                        ]
                        : [
                            { language: "English", key: "en" }, { language: "Chinese ( 中文 )", key: "zh" }
                        ])
                })}
                genInputExtraClass='!mb-0 bg-transparent rounded-none'
            />
        )
    }


    switch (type) {
        case 2: {
            return languagePopup && <ParentModal
                getParentChildProps={{
                    closeModal: () => { setLanguagePopup(false); localStorage.removeItem("LANGUAGE_POPUP"); },
                    animationType: "bottom",
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', positon: "absolute", margin: 0, borderRadius: 0 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "33vw", height: "46vh", maxHeight: '400px', maxWidth: '500px' },
                    child: <div className='choose-language-popup'>
                        <GenericFormHeaderComponent
                            label={{
                                contentPrimary: "Choose Language"
                            }}
                            closeModal={() => { setLanguagePopup(false); localStorage.removeItem("LANGUAGE_POPUP"); }}
                        />
                        <div className='px-4 overflow-auto h-[calc(46vh-110px)]'>

                        <LanguageTranslator tag='p' className='text-xs text-[#67768e]'>Current language of website is not updated in your profile, please choose your default language</LanguageTranslator>
                        <div className='pt-[20px] pb-3'>
                            {renderLanguageInput({})}
                        </div>
                        </div>
                        <div className='px-4 py-2.5'>
                        {/* Render the final submit button */}
                        <GenericCustomButton
                            disabled={!language || editUserData?.isLoading}
                            loading={editUserData?.isLoading}
                            onClick={() => {
                                dispatchHandler(dispatch, "editUserRequest", {
                                    firstName: parseToken?.firstName || "",
                                    lastName: parseToken?.lastName || "",
                                    language: language || "",
                                    country: parseToken?.country || "",
                                }, getPermissionKey("common", "commonfunctionality", "view"))
                            }}
                            label={"UPDATE LANGUAGE"}
                            type='primary'
                            className='!rounded-none !h-[37px] w-full'
                            />
                        </div>
                    </div>
                }}
                childComponent={Component}
                closeModal={() => { setLanguagePopup(false); localStorage.removeItem("LANGUAGE_POPUP"); }}
            />
        }
        default: {

            return (
                <div className='languagePopup flex items-center justify-between gap-3'>
                    <div className='flex items-center gap-4'>
                        <p className='text-start font-medium mb-0 flex items-center gap-2'><span><Icons.ChineseLangIcon /></span><LanguageTranslator>The app is in a different language than your browser, you can select your preferred language from the list</LanguageTranslator></p>

                        {renderLanguageInput({})}
                    </div>
                    <GenericCustomButton
                        leftIcon={<CircleX color='white' size={20} />}
                        style={{ backgroudn: "#2969FF" }}
                        onClick={() => props.closeModal()}
                        className={'flex items-center justify-center gap-1.5'}
                        label="Dismiss"
                    />
                </div>
            )
        }
    }
}

export default LanguagePopup