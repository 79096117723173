import React, { useEffect, useState } from 'react'
import { getDesiredPayload, provideInputs } from './components/CommonFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { getPermissionKey } from '../../helper/permissions';

const CompanyLocation = ({ menuBarDetailsData = [], getPayloadData, getInitialPayload, getInputErrorState }) => {
    const authStore = useSelector((state) => state.authStore)
    const dispatch = useDispatch()

    const [inputData, setInputData] = useState({})
    const [isChecked, setIsChecked] = useState(false)
    const [errorData, setErrorData] = useState({});
    const [isApiCalled, setIsApiCalled] = useState(false)

    // storing the data in the required format
    useEffect(() => {
        const newData = {};
        menuBarDetailsData?.forEach(item => {
            newData[item?.headerKey] = item;
        });
        setInputData(newData);

        // getting the initial payload data
        const initialPayloadData = getDesiredPayload(newData);
        getInitialPayload(initialPayloadData)
    }, [menuBarDetailsData])

    // function to get the factory data for usecase same address as the  previous address
    useEffect(() => {
        if (isChecked) {
            if (!isApiCalled) {
                dispatchHandler(dispatch, 'getFactoryDetailsRequest', { type: 'menuBarData', code: 'FACDET' }, getPermissionKey("common", "commonfunctionality", "view"))
                setIsApiCalled(true)
            }
        }
    }, [isChecked])

    // function to get the desired payload and transfering to the parent
    useEffect(() => {
        getPayloadData(getDesiredPayload(inputData))
    }, [inputData])

    // getting InputErrorStatedata in parent component
    useEffect(() => {
        getInputErrorState({ inputData, errorData, setErrorData })
    }, [errorData, inputData])

    // manupulate the data if isChecked is true
    useEffect(() => {
        if (isChecked && authStore?.getFactoryDetailsData?.data?.resource) {
            setInputData(prevData => ({
                ...prevData,
                'factory_add': {
                    ...prevData['factory_add'],
                    value: authStore?.getFactoryDetailsData?.data?.resource?.find((field) => field?.headerKey == "registered_add")?.value
                }
            }));
        }
        else {
            setInputData(prevData => ({
                ...prevData,
                'factory_add': {
                    ...prevData['factory_add'],
                    value: authStore?.getMenuBarDetailsData?.data?.resource?.find((field) => field?.headerKey == "factory_add")?.value
                }
            }));
        }
    }, [isChecked, authStore?.getFactoryDetailsData?.isSuccess])

    console.log('inputdatathdjy37437aa', inputData);
    return (
        <div className='vob-right'>
            {
                Object.values(inputData || {})?.map((inputField, index) => {
                    return <div className='vobrs-input-sec'>
                        <h3> {inputField?.headerName} {inputField?.isSectionMandate && <span className='mandatory'>*</span>} </h3>
                        {inputField?.headerKey == "factory_add" &&
                            <div className='w-100 p-top-15'>
                                <label className="generic-checkbox p-lft-20">
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                        // disabled={inputField?.isEditableVendor != 0}
                                    />
                                    <span className="checkmark1"></span>
                                    <p>Same as registered address</p>
                                </label>
                            </div>}
                        {provideInputs(inputField, inputData, setInputData, errorData?.[index], (data) => {
                            let updatedErrorData = { ...errorData }
                            updatedErrorData[index] = data
                            setErrorData(updatedErrorData);
                        })}
                    </div>
                })
            }
        </div>
    )
}

export default CompanyLocation