import React, { useState, useEffect } from 'react'
import GenericCustomButton from '../GenericCustomButton';
import IconsPath from '../../assets/icons/index'
import GenericFileShareModal from './GenericFileShareModal';
import ParentModal from '../ParentModal';
import { Slider } from 'antd';
import Icons from '../../assets/icons/index';
import NoDataFound from '../NoDataFound';
import {File, ChevronLeft, ChevronRight} from 'lucide-react'

const GenericMediaViewModal = (props) => {

    const { filesData = [], closeModal = null, current } = props;
    // Files data is going to be an array
    // let filesData = [
    //     {
    //         fileUrl : "",
    //         fileName : "",
    //         fileType : "", // other, image, video , pdf;
    //     }
    // ]

    // State : To store the current file that is selected by the user at inital stage it is going to be 1st index of the file either it is going to be a dummy file.
    const [currentFile, setCurrentFile] = useState(filesData?.[0] || {
        "fileName": "File Name Not Found",
    });

    const [currentFileIndex, setCurrentFileIndex] = useState(current ? current : 0)
    const [selectedModal, setSelectedModal] = useState("");
    const [currentZoom, setCurrentZoom] = useState(props.current ? props.current : 0);

    useEffect(() => {
        {
            if (filesData?.[currentFileIndex]) {
                setCurrentFile(filesData?.[currentFileIndex])
            }
        }
    }, [currentFileIndex])

    // To handle all the changes of the component
    const handleChange = (type, data) => {
        switch (type) {
            case "downloadData": {
                window.open(currentFile?.fileUrl);
                break;
            }
            case "shareData": {
                setSelectedModal("shareDataModal");
                break;
            }
            default:
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal("") },
            animationType: "bottom"
        }

        switch (selectedModal) {
            case "shareDataModal": {
                return {
                    ...payload,
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: "absolute", margin: 0, borderRadius: 3 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "50vw", height: "60vh", maxHeight: '510px', maxWidth: '730px' },
                    fileUrl: currentFile?.fileUrl
                }
            }
            default:
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "shareDataModal": return GenericFileShareModal;
            default: {
                return <React.Fragment></React.Fragment>
            }
        }
    }

    const renderMediaControls = () => {
        let Component = null;

        // Render the zoom in and zoom out functinality
        Component = <React.Fragment>
            {Component}
            <Slider
                value={currentZoom}
                onChange={(val) => setCurrentZoom(val)}
                min={1}
                max={100}
                style={{
                    // Customize the color to red or any other color you prefer
                    track: {
                        backgroundColor: 'red',
                    },
                    handle: {
                        borderColor: 'red',
                        backgroundColor: 'red',
                    },
                    width: '20%', // Set the width as needed
                }}
            />
        </React.Fragment>

        if (currentFile?.["fileType"] === "video") {
            // Add the video controls here
        }

        return Component;
    }

    const renderHeaderComponent = () => {
        return (
            <div className='givm-head'>
                {/* the header component will contain the name and controls of the media section based on the type of the media content*/}
                {/* Render the file name of the media component */}
                {currentFile?.["fileName"] && (
                    <h3>{currentFile?.["fileName"] || "File Name Not Found!"}</h3>
                )}
                <React.Fragment>
                    {/* Render the media controls for the given media content */}
                    {/* {renderMediaControls()} */}
                    <div></div>
                    <div className='givmh-right'>
                        {/* Render the download and share icon to download the data the share the data */}
                        {
                            (currentFile?.fileType !== 'pdf' && (!props.hideDownloadOption && currentFile?.["fileUrl"])) && <GenericCustomButton
                                onClick={() => handleChange("downloadData")}
                                leftIcon={<IconsPath.DownloadContentIcon />}
                            />
                        }
                        {
                            (!props.hideShareOption && currentFile?.["fileUrl"]) && <GenericCustomButton
                                onClick={() => handleChange("shareData")}
                                leftIcon={<IconsPath.FileShareIcon />}
                            />
                        }

                        {/* At the end od the header component render the cross iocn to close the component */}
                        {/* Render the close icon */}
                        {/* TODO: Create the hover effect on this close icon */}
                        {closeModal &&
                            <>
                                <GenericCustomButton
                                    onClick={closeModal}
                                    disabled={false}
                                    loading={false}
                                    leftIcon={<IconsPath.ModalCloseIcon />}
                                />
                            </>
                        }
                    </div>
                </React.Fragment>
            </div>
        )
    }

    const renderContentPreview = (file, isCurrentlyOpened) => {
        console.log("File Url", file?.fileType);
        
        switch (file?.fileType) {
            case "image": {
                return <div className={`w-100 h-100 duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    {file?.fileUrl ? <img src={file?.fileUrl} /> : <Icons.ImageThumbnail />}
                </div>
            }
            case "video": {
                return <div className={`w-100 h-100 duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    {/* Please provide the backup image url */}
                    {file?.fileUrl ?
                        <video width="100%" className="videoPlayer" preload="metadata">
                            <source src={file?.fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        :
                        <Icons.ImageThumbnail />
                    }
                </div>
            }
            case "pdf": {
                return <div className={`PreviewPdfIcon w-100 h-100 flex items-center justify-center duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    <Icons.TGPdfIcon/>
                </div>
            }
            case "xlsx":
            case "xls": {
                return <div className={`PreviewPdfIcon w-100 h-100 flex items-center justify-center duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    <Icons.ExcelFileIcon/>
                </div>
            }
            case "doc":
            case "docx": {
                return <div className={`PreviewPdfIcon w-100 h-100 flex items-center justify-center duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    <Icons.WordFileIcon/>
                </div>
            }
            default: {
                return <div className={`w-100 h-100 flex items-center justify-center duration-200 ${isCurrentlyOpened ? 'scale-90' : 'group-hover:scale-90'}`}>
                    <File size={35}/>
                </div>
            }
        }
    }

    const handlePagination = (type, data) => {
        switch (type) {
            case "prevImg": {
                setCurrentFileIndex(prev => {
                    if (prev == 0) {
                        console.log("current sdfasd",currentFileIndex, filesData);
                        if (filesData?.length > 1) {
                            return filesData?.length - 1
                        }
                    }
                    else {
                       return Number(prev) - 1;
                    }
                })
            }
                break;
            case "nextImg": {
                setCurrentFileIndex(prev => {
                    if ((filesData?.length - 1) == prev) {
                        if (filesData?.length > 1) {
                            return 0;
                        }
                    }
                    else {
                       return Number(prev) + 1;
                    }
                })
            }
                break;
            case "imageClick": {
                setCurrentFileIndex(data)
            }
                break;
        }
    }

    console.log("Current file index", currentFileIndex);

    // Function : To render the list of the all the media files present.
    const renderBottomMediaList = () => {

        return <div className='givm-footer'>
            <div  onClick={() => handlePagination("prevImg")} className='w-[75px] flex items-center justify-center absolute left-0 bottom-0 bg-gradient-to-r from-black z-10 to-transparent' style={{cursor:"pointer"}}>
                <GenericCustomButton
                    leftIcon={<span><ChevronLeft color='white'/></span>}

                    className={'!bg-transparent'}
                />
            </div>
            <div className='givmf-img'>
                {filesData?.map((file, index) => {
                    return <div className={`givmfi !border-[3px] hover:!border-black group ${index === currentFileIndex ? "!border-black" : "!border-transparent"}`} onClick={() => handlePagination("imageClick", index)} key={index?.toString()}>
                        {renderContentPreview(file, index === currentFileIndex )}
                    </div>
                })}
            </div>
            <div onClick={() => handlePagination("nextImg")}  className='w-[75px] flex items-center justify-center absolute right-0 bottom-0 bg-gradient-to-l from-black z-10 to-transparent pointer' style={{cursor:"pointer"}}>
                <GenericCustomButton
                    leftIcon={<span><ChevronRight color='white'/></span>}
                    className={'!bg-transparent'}
                />
            </div>
        </div>
    }

    console.log(43343434343434, currentFile)

    // Function: TO render the actual content of current selected file
    const renderContent = () => {
        switch (currentFile?.fileType) {
            case "image": {
                return <div className='givmm-data'>
                    <img src={currentFile?.fileUrl || require('../../assets/icons/NoImgToView.svg')}
                    // style={{ transform: `scale(${1 + currentZoom / 100})` }} 
                    />
                </div>
            }
            case "video": {
                return <div className='givmm-data'>
                    {currentFile?.fileUrl ?
                        <video controls width="100%" className="videoPlayer" preload="metadata">
                            <source src={currentFile?.fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        :
                        <Icons.ImageThumbnail />
                    }
                </div>
            }
            case "pdf": {
                return <div className='h-full w-full'>
                    <iframe src={currentFile?.fileUrl} frameBorder="false" style={{ width: "100%", height: "100%" }}></iframe>
                </div>
            }
            default: {
                return <div className='givmm-data'>
                    <NoDataFound  primaryText = "No Preview Available"/>
                </div>
            }
        }
    }

    
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            handlePagination("nextImg")
        } else if (event.key === 'ArrowLeft') {
            handlePagination("prevImg")
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='generic-img-view-modal'>
            {/* Render the header component for the media component */}
            {renderHeaderComponent()}

            {/* Render the actual content -> image / pdf / video */}
            <div className='givm-mid'>
                {renderContent()}
            </div>

            {/* Render the bottom scroller to show all the data */}
            {renderBottomMediaList()}

            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            />}
        </div>
    )
}

export default GenericMediaViewModal