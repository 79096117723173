import React, { useRef, useState, useEffect } from 'react'
import GenericCustomButton from '../../../GenericCustomButton';
import Icons from '../../../../assets/icons';
import CommentEditor from './CommentEditor';
import { getSocketConnection } from '../../../../socket/socketHelperConnection';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { decodeToken } from '../../../../helper/genericFunction';
import GenericInput from '../../../input/GenericInputComponent';
import GenericSpinnerLoader from '../../../../loaders/GenericSpinnerLoader';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { getFileNameFromExt, getFileTypeFromUrl } from '../../../../helper/helperTableFunctions';
import ParentModal from '../../../ParentModal';
import GenericMediaViewModal from '../../../../genericComponents/media/GenericMediaViewModal'
import { AtSign, Download, File, FileMusic, Paperclip, Play, Search, User, X } from 'lucide-react';
import MentionsCommentContainer from './MentionsCommentContainer';
import AttatchmentsCommentContainer from './AttatchmentsCommentContainer';
import LanguageTranslator from '../../../../locals/LanguageTranslator';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const CommentBoxModal = (props) => {

    let { comment = {}, item, onClose, mode = "normal", outerMode, closeSection } = props;

    let tokenData = decodeToken(sessionStorage.getItem("token"));

    const [loading, setLoading] = useState({});

    const [messageApi, contextHolder] = message.useMessage();

    let observerTarget = useRef();

    // Handle Scroll in chatbox
    const chatContainerRef = useRef(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
    const [prevHeight, setPrevHeight] = useState(0)

    // State : To check if more data is available at the server for this document;
    const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
    const [commentsListData, setCommentsListData] = useState([]);

    let commentListDataRef = useRef();
    let loadingRef = useRef();

    const [search, setSearch] = useState("");

    const [errorData, setErrorData] = useState(null);

    const [resetData, setResetData] = useState(false);

    const [selectedModal, setSelectedModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openSearchComponent, setOpenSearchComponent] = useState(false)
    const [viewAllAttachments, setViewAllAttachments] = useState({})

    let getAllCommentsName = `get_all_comments_${mode == "central" ? "normal" : mode}`

    // The ref of the top most element inside the comment here
    const lastListElementRef = (node) => {
        if (loading?.[getAllCommentsName]) return;

        if (observerTarget?.current) observerTarget.current.disconnect();
        observerTarget.current = new IntersectionObserver(entries => {
            console.log("entry intersecrting", entries?.[0].isIntersecting);
            if (entries?.[0].isIntersecting) {
                // So that the state current page and max page state gets updated
                getComments();
            }
        })
        if (node) observerTarget.current.observe(node);
    }

    useEffect(() => {
        commentListDataRef.current = commentsListData;
        if (commentsListData?.length < 30) {
            const chatContainer = chatContainerRef.current;
            setPrevHeight(chatContainer?.scrollHeight || 0);
            if (isScrolledToBottom) {
                (mode === 'normal' || mode === 'central' ) && scrollToBottom();
            }
        }
    }, [commentsListData])

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading])

    useEffect(() => {
        const chatContainer = chatContainerRef.current
        if (!isScrolledToBottom && chatContainer) {
            chatContainer.scrollTop = (chatContainer?.scrollHeight || 0) - prevHeight + (chatContainer?.scrollTop || 0);
        }
    }, [prevHeight]);

    const handleScroll = () => {
        const chatContainer = chatContainerRef.current
        // console.log("Check q34",chatContainer?.scrollTop );
        setIsScrolledToBottom(chatContainer?.scrollTop > -20);
    }

    let socket = getSocketConnection();

    useEffect(() => {
        getComments({
            "action": "prev",
            "timestamp": dayjs()?.format("YYYY-MM-DDTHH:mm:ss"),
        })
    }, [])

    const scrollToBottom = () => {
        setTimeout(() => {
            const chatContainer = chatContainerRef?.current;
            if (chatContainer)
                chatContainer.scrollTop = (chatContainer?.scrollHeight || 0)
        }, 500)
    }

    // Function : To handle the json validation error socket event
    const handleSocketEventError = (data, type) => {

        setLoading(prev => {
            delete prev?.[data?.name];
            return prev;
        });

        switch (type) {
            case "json_validation_error": {
                let updatedErrorData = [];
                data?.errors?.map(item => {
                    updatedErrorData?.push(`${item?.instancePath?.slice(1)} ${item?.message}`);
                })
                setErrorData(updatedErrorData);
                break;
            }
            case "eventError": {
                setErrorData(["Something went wrong, please try again."]);
                break;
            }
        }

    }

    // Function : To handle the sucess of the events on the frontend
    const handleEventSuccess = (data, config = {}) => {
        let tokenData = decodeToken(sessionStorage.getItem("token"));
        let { eventName } = config;
        setErrorData(null);
        if (mode == "normal" || mode == "central") {
            if(eventName == "new_comment_addition_chat" ? data?.documentId == item?.[comment?.configKeys?.["documentId"]] && data?.documentNumber == item?.[comment?.configKeys?.["documentNumber"]] : true){
                // console.log("Step 7 : ",eventName );
                socket?.emit("store_user_read_time", { "read_time": dayjs()?.format("YYYY-MM-DDTHH:mm:ss"), "documentId": item?.[comment?.configKeys?.documentId], "documentNumber": item?.[comment?.configKeys?.documentNumber] })
            }
        }
        console.log("evenmdsafsd", eventName);
        if (data?.resource?.error) {
            handleSocketEventError({
                "name": eventName,
                ...data?.resource,
            }, "eventError");
        } else {
            switch (eventName) {
                case "add_comment": {
                    console.log("New comment", loadingRef?.current?.["add_comment"]);
                    loadingRef?.current?.["add_comment"] && setCommentsListData(prev => ([loadingRef?.current?.["add_comment"], ...prev]));
                    setResetData("resetInputBox")
                    scrollToBottom()
                    break;
                }
                case getAllCommentsName: {
                    console.log("Data comment", data);

                    if (data?.mode == mode) {
                        let values = data?.data?.reverse();

                        let commentsListData = commentListDataRef.current || null;

                        if (commentsListData?.length == 0) {
                            setCommentsListData([...values] || []);
                            setTimeout(() => {
                                document.getElementById("bottomMessagesDiv")?.scrollIntoView({ behavior: "smooth" });
                            }, 100);
                        } else {
                            if (values?.[values?.length - 1]?.creationDetails?.creationTime >= commentsListData?.[commentsListData?.length - 1]?.creationDetails?.creationTime) {
                                // Make a sound inside the system
                                setCommentsListData(prev => [...values, ...prev,])
                            } else {
                                setCommentsListData(prev => [...prev, ...values]);
                            }
                        }

                        if (values?.length < 30) {
                            setIsMoreDataAvailable(false);
                        } else {
                            setTimeout(() => {
                                setIsMoreDataAvailable(true);
                            }, 1000)
                        }

                    }
                    break;
                }
                case "new_comment_addition_chat": {
                    console.log("Date changes", data, item);
                    // Play a sound here
                    if (data?.documentId == item?.[comment?.configKeys?.["documentId"]] && data?.documentNumber == item?.[comment?.configKeys?.["documentNumber"]]) {

                        // Case : check if the comment belongs to the user or not 
                        if (data?.createdByUserId != tokenData?.["jti"]) {
                            setCommentsListData(prev => ([
                                {
                                    "commentBody": data?.["commentBody"] || "",
                                    "creationDetails": {
                                        "createdByProfileImage": "", //data?.["createdByProfileImage"] || "",
                                        "createdByUserId": data?.["createdByUserId"] || "",
                                        "createdByUserName": data?.fullName || "",
                                        "creationTime": data?.creationTime || ""
                                    },
                                    "documentId": data?.documentId || "",
                                    "documentNumber": data?.documentNumber || "",
                                    "fileDirectory": data?.fileDirectory || "[]",
                                    "taggedUser": data?.taggedUser || "{}"
                                },
                                ...prev
                            ]))
                        }
                    }
                    break;
                }
                default:
            }
        }

        setTimeout(() => {
            let updatedLoading = { ...loading };
            delete updatedLoading?.[eventName];
            setLoading(updatedLoading);
        }, 100)
    }

    const handleChange = (key, config) => {
        switch (key) {
            case "sendMessage": {

                let { taggedUser = {}, content, uploadedFiles } = config || {}
                if (socket?.connected) {
                    let obj = {}
                    ["vendorCode"]?.map(key => {
                        if (item?.[key]) {
                            obj[key] = item?.[key];
                        }
                    })
                    // To store all the tagged user
                    const tagUser = {};
                    Object.keys(taggedUser || {})?.map(tag => {
                        if (content?.includes(tag)) {
                            tagUser[tag] = taggedUser?.[tag]?.id || ""
                        }
                    })
                    let payload = {
                        "documentId": item?.[comment?.configKeys?.documentId] || "",
                        "documentNumber": item?.[comment?.configKeys?.documentNumber] || "",
                        "documentType": item?.[comment?.configKeys?.documentType] || "",
                        "commentBody": content,
                        "fileDirectory": JSON.stringify(uploadedFiles),
                        "creationTime": dayjs()?.format("YYYY-MM-DDTHH:mm:ss"),
                        "taggedUser": JSON.stringify(tagUser),
                        "vendorCode": item?.[comment?.configKeys?.["vendorCode"]] || undefined,
                        "customerCode": item?.[comment?.configKeys?.["customerCode"]] || undefined,
                        "additional" : JSON.stringify({
                            "vendorCode": item?.[comment?.configKeys?.["vendorCode"]] || undefined,
                            "customerCode": item?.[comment?.configKeys?.["customerCode"]] || undefined,
                        })
                    }

                    socket.emit("add_comment", payload)
                    setLoading(prev => ({
                        ...prev, "add_comment": {
                            id: uuidv4(),
                            commentBody: content,
                            creationDetails: {
                                "creationTime": dayjs()?.format("YYYY-MM-DDTHH:mm:ss"),
                                'createdByUserId': tokenData?.jti || "",
                                "createdByUserName": `${tokenData?.firstName} ${tokenData?.lastName}`,
                                "createdByProfileImage": "", //tokenData?.org_logo || "",
                            },
                            documentId: item?.[comment?.configKeys?.documentId],
                            documentNumber: item?.[comment?.configKeys?.documentNumber],
                            fileDirectory: JSON.stringify(uploadedFiles),
                            taggedUser: JSON.stringify(tagUser),
                            "additional" : JSON.stringify({
                                "vendorCode": item?.[comment?.configKeys?.["vendorCode"]] || undefined,
                                "customerCode": item?.[comment?.configKeys?.["customerCode"]] || undefined,
                            })
                        }
                    }));
                }
                else {
                    console.log("Socket connection error");
                }
                break;
            }
            case "openImageViewModal": {
                setSelectedModal("openImageViewModal");
                let allFiles = [];
                let curent = null;
                commentsListData?.map((item, ci) => {
                    if (item?.fileDirectory?.length > 0) {
                        let doc = JSON.parse(item?.fileDirectory || "[]");
                        doc?.map((file, fi) => {
                            if (ci === config?.commentIndex && fi === config?.fileIndex) {
                                curent = allFiles?.length;
                            }
                            allFiles.push(file);
                        })
                    }
                })
                console.log(curent, "Current index")
                setSelectedData({
                    allFiles: allFiles,
                    curent: curent,
                })
                break;
            }
            default:
        }
    }

    // Function : To get the comments data here
    const getComments = (config = {}) => {
        // console.log("Get comments", config)
        // console.log("Comment data list", commentsListData);
        let { action = "prev", timestamp, pageSize = 30, currentSearch = search || "", filter = {} } = config;

        let useIndex = action == "prev" ? commentsListData?.length - 1 : 0

        let payload = {
            "documentId": item?.[comment?.configKeys?.documentId] || "",
            "documentNumber": item?.[comment?.configKeys?.documentNumber] || "",
            "timestamp": timestamp ? timestamp : commentsListData?.[useIndex]?.creationDetails?.creationTime ? dayjs(commentsListData?.[useIndex]?.creationDetails?.creationTime)?.format("YYYY-MM-DDTHH:mm:ss") : action == "prev" ? dayjs()?.format("YYYY-MM-DDTHH:mm:ss") : "",
            "pageSize": pageSize,
            "filter": filter,
            "search": currentSearch,
            "action": action,
            "mode": mode ?? "normal",
        }

        // console.log("Get all comment payload", payload);
        setErrorData(null);
        socket.emit(getAllCommentsName, payload);

        setLoading(prev => ({ ...prev, [getAllCommentsName]: true }));

        setIsMoreDataAvailable(false);
    }

    const renderSearchComponent = (config = {}) => {
        const { className = '' } = config;

        return <GenericInput
            inputKey="searchComment"
            placeholder="Search in Chats"
            isDisabled={loading?.[getAllCommentsName]}
            dependentFieldsObject={["searchComment"]}
            onChange={(e) => {
                setSearch(e?.target?.value || "")
                if ((e?.target?.value == "" || e?.target?.value == undefined) && search) {
                    setCommentsListData([]);
                    getComments({ timestamp: dayjs()?.format("YYYY-MM-DDTHH:mm:ss"), currentSearch: "" })
                }
            }}
            value={search}
            iconLeft={<span className='ml-[6px] mr-[8px] text-black'><Icons.SearchIconDark /></span>}
            onKeyPress={(e) => {
                if (e.key == "Enter") {
                    setCommentsListData([]);
                    getComments({ timestamp: dayjs()?.format("YYYY-MM-DDTHH:mm:ss") })
                }
            }}
            className={`placeholder:font-normal rounded-none border-[#DAE4EE]`}
            genInputExtraClass={className || ''}
        />
    }

    const renderCommentHeaderDetails = () => {
        console.log('headerMode', outerMode)
        switch (mode) {
            case "normal": {
                return (
                    <div className='flex items-center justify-between border-b py-2 p-[12px]'>
                        <div className='flex items-center gap-3'>
                            <button className='p-[6px] rounded-md bg-[#F3F5F7] hover:opacity-75' onClick={onClose}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.5 6L1.5 9L4.5 12" stroke="black" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M1.5 9H16.5" stroke="black" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                            {/* <p>Document Number</p> */}
                            <p className='font-semibold p-[0] m-[0]'>{item?.[comment?.configKeys?.documentNumber] || "-"}</p>
                        </div>
                    </div>
                )
            }
            case "mentions": {
                return (
                    <div className={`bg-white flex items-center justify-between border-b px-3.5 ${outerMode === 'mentions_central' ? 'pt-[18px] pb-[19px]' : 'py-[9px]'}`}>
                        
                        <div className='flex items-center gap-3'>
                            <button className='p-[6px] rounded-md bg-[#F3F5F7] hover:opacity-75' onClick={closeSection}>
                                <X size={16}/>
                            </button>
                            {/* <p>Document Number</p> */}
                            <LanguageTranslator tag="p" className='font-semibold mb-0'>Mentions</LanguageTranslator>
                        </div>
                    </div>
                )
            }
            case "attachments": {
                return (
                    <div className={`bg-white flex items-center justify-between border-b px-3.5 ${outerMode === 'attachments_central' ? 'pt-[18px] pb-[19px]' : 'py-[9px]'}`}>
                        <div className='flex items-center gap-3'>
                            <button className='p-[6px] rounded-md bg-[#F3F5F7] hover:opacity-75' onClick={closeSection}>
                                <X size={16}/>
                            </button>
                            <LanguageTranslator tag="p" className='font-semibold mb-0'>Attachments</LanguageTranslator>
                        </div>
                    </div>
                )
            }
            case "central": {
                return (
                    <div className={`bg-white border-l border-b border-[#DAE4EE] px-6 pt-[10px] pb-[11px] flex items-center justify-between gap-3`}>
                        <div>
                            <LanguageTranslator tag="p" className='text-base font-semibold mb-1'>Showing All Comments</LanguageTranslator>
                            <LanguageTranslator tag="p" className='text-xs font-normal mb-0'>For : {props?.item?.document_no || ""}</LanguageTranslator>
                        </div>
                        <div className='relative flex items-center gap-2'>
                            <div className='h-[32px] overflow-hidden'>
                                <div className={`ease-in duration-100 ${openSearchComponent === true ? 'translate-y-0 opacity-100 visible pointer-events-auto' : 'translate-y-[calc(100%+2px)] opacity-0 invisible pointer-events-none'}`}>
                                    {renderSearchComponent({ className: '!mb-0' })}
                                </div>
                            </div>
                            <GenericCustomButton
                                leftIcon={
                                    <div className="relative h-[18px] overflow-hidden w-[18px]">
                                        <span className={`absolute left-0 duration-200 ${openSearchComponent ? 'translate-y-0 opacity-100' : '-translate-y-[32px] opacity-0'}`}>
                                            <X size={18} />
                                        </span>
                                        <span className={`absolute left-0 duration-200 ${!openSearchComponent ? 'translate-y-0 opacity-100' : '-translate-y-[32px] opacity-0'}`}>
                                            <Search size={18} />
                                        </span>
                                    </div>
                                }
                                onClick={() => {
                                    setSearch('')
                                    setOpenSearchComponent(!openSearchComponent)
                                }}
                                className={'hover:opacity-75'}
                            />
                        </div>
                    </div>
                )
            }
            default:
        }
    }

    // Function : To render the message editor here

    const renderMessageEditor = () => {
        const enterDisabled = (!item?.[comment?.configKeys?.documentNumber] || !item?.[comment?.configKeys?.documentId]);
        return (
            <>
                <div
                    className={`msgInput bg-white focus-within:border-primary focus:border-primary break-all ${mode === 'normal' ? 'border border-[#DAE4EE] rounded py-1.5' : 'rounded-[9px] py-2'}`}
                    style={{
                        boxShadow: mode === 'central' ? '0px 3px 15px 1px #66646440' : 'none',
                    }}
                >
                    <CommentEditor
                        setResetData={setResetData}
                        resetInputBox={resetData == "resetInputBox"}
                        errorData={errorData}
                        handleChange={(config) => {
                            handleChange("sendMessage", config)
                        }}
                        loading={loading}
                        enterDisabled={enterDisabled}
                        editable={!loading?.["add_comment"] && (item?.[comment?.configKeys?.documentNumber] || false) && (item?.[comment?.configKeys?.documentId] || false)}
                        enterButton={true}
                        setErrorData={setErrorData}
                        chatContainerWidth={chatContainerRef?.current?.getBoundingClientRect?.()?.width || ""}
                        mode={mode}
                    />
                </div>
                {/* <div className='flex items-center justify-between gap-1 text-[10px] mt-2'> */}
                {/* {"Press Enter to send the message, press "} {"Ctrl + Enter"} to add a new block, press Shift + Enter for selection and to add a new line. */}
                {/* <div className='flex items-center gap-1'><div className='enterIcon bg-gray-200 rounded px-1.5 pt-0.5 pb-[3px] shadow border-b-2 border-gray-300'><Icons.AddComentEnterIcon /> </div> <Icons.RightArrowThin /> Send message</div>
                    <div className='flex items-center gap-1'><div className='bg-gray-200 rounded px-1.5 font-medium shadow text-[9px] border-b-2 border-gray-300'>Ctrl</div> + <div className='enterIcon bg-gray-200 rounded px-1.5 pt-0.5 pb-[3px] shadow text-[10px] border-b-2 border-gray-300'><Icons.AddComentEnterIcon /></div> <Icons.RightArrowThin />Add a new block</div>
                    <div className='flex items-center gap-1'><div className='bg-gray-200 rounded px-1.5 font-medium shadow text-[9px] border-b-2 border-gray-300'>Shift</div> + <div className='enterIcon bg-gray-200 rounded px-1.5 pt-0.5 pb-[3px] shadow text-[10px] border-b-2 border-gray-300'><Icons.AddComentEnterIcon /></div> <Icons.RightArrowThin />Add a new line</div>
                </div> */}
                {enterDisabled && <LanguageTranslator tag="p" className='text-red-600 font-medium text-[11px] mt-2'>Document details are not valid i.e, Document No and Document Id.</LanguageTranslator>}
            </>
        )
    }

    // render Skeleton component
    const renderLoadingSkeleton = (type) => {
        console.log('type', type)
        switch (type) {
            case 'message':
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((obj, index) => (
                    <div key={index} className='w-full mb-8 flex'>
                        <div className={`w-full flex items-start justify-end gap-3 ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'}`}>
                            <div className=''>
                                <div className={`flex items-center gap-2 ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
                                    <div className={`generic-skeleton w-[60px] ${mode === 'central' ? '!bg-[#CFD1E1]' : ''}`}></div>
                                    <div className={`generic-skeleton w-[50px] ${mode === 'central' ? '!bg-[#CFD1E1]' : ''}`}></div>
                                </div>
                                <div className={`generic-skeleton !h-[70px] w-[220px] ${mode === 'central' ? '!bg-[#CFD1E1]' : ''} ${mode === 'central' && '!w-[25vw] !h-[15vh]'}`}></div>
                            </div>
                            <div className={`generic-skeleton mt-7 !w-[35px] !h-[35px] !rounded-full ${mode === 'central' ? '!bg-[#CFD1E1]' : ''}`}></div>
                        </div>
                    </div>
                ));
                break;

            case 'mentions':
                // return <div className='flex-1 flex items-center justify-center p-[1rem]'><p className='mb-0'>Loading mentions...</p></div>
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((obj, index) => (
                    <div key={index} className='w-full mb-8 flex'>
                        <div className={`w-full flex items-start justify-end gap-3 flex-row-reverse`}>
                            <div className=''>
                                <div className={`flex items-center gap-2 justify-start`}>
                                    <div className='generic-skeleton w-[60px]'></div>
                                    <div className='generic-skeleton w-[50px]'></div>
                                    <div className='generic-skeleton w-[50px]'></div>
                                </div>
                                <div className={`generic-skeleton !h-[70px] w-[180px] ${mode === 'central' && '!w-[25vw] !h-[15vh]'}`}></div>
                            </div>
                            <div className='generic-skeleton !w-[35px] !h-[35px] !rounded-full'></div>
                        </div>
                    </div>
                ));
                break;

                case 'attachments':
                // return <div className='flex-1 flex items-center justify-center p-[1rem]'><p className='mb-0'>Loading attachments...</p></div>
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((obj, index) => (
                    <div key={index} className='w-full mb-8 flex'>
                        <div className={`w-full flex items-start justify-start gap-3 flex-row`}>
                            <div className=''>
                                <div className={`flex items-center gap-2 justify-start`}>
                                    <div className='generic-skeleton w-[20px]'></div>
                                    <div className='generic-skeleton w-[50px]'></div>
                                    <div className='generic-skeleton w-[50px]'></div>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className={`generic-skeleton !h-[110px] w-[100px]`}></div>
                                    <div className={`generic-skeleton !h-[110px] w-[100px]`}></div>
                                </div>
                            </div>
                            {/* <div className='generic-skeleton mt-7 !w-[35px] !h-[35px] !rounded-full'></div> */}
                        </div>
                    </div>
                ));
                break;

            default:
                break;
        }

    }

    const checkImageUrl = async (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    // Function : to render the comment message here
    const renderCommentMessage = (config = {}) => {
        let {
            commentItem,
            commentIndex,
            isMyMsg
        } = config;

        // to check file types : image, video , audio, doc
        function checkFileType(fileType) {
            const imageFormats = [
                "jpg", "jpeg", "png", "gif", "bmp", "tiff",
                "image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/tiff"
            ];

            const videoFormats = [
                "mp4", "video/mp4", "avi", "video/avi", "mov", "video/quicktime",
                "wmv", "video/x-ms-wmv", "flv", "video/x-flv", "mkv", "video/x-matroska",
                "webm", "video/webm", "mpeg", "video/mpeg", "3gp", "video/3gpp",
                "ogv", "video/ogg", "mts", "video/mts", "m2ts", "video/m2ts",
                "vob", "video/dvd", "f4v", "video/x-f4v", "asf", "video/x-ms-asf",
                "ts", "video/MP2T", "divx", "video/divx"
            ];

            const audioFormats = [
                "mp3", "audio/mpeg", "wav", "audio/wav", "ogg", "audio/ogg", "flac", "audio/flac",
                "aac", "audio/aac", "m4a", "audio/mp4", "wma", "audio/x-ms-wma"
            ];

            const docFormats = [
                "pdf", "application/pdf", "doc", "application/msword", "docx",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "xls", "application/vnd.ms-excel", "xlsx",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "ppt", "application/vnd.ms-powerpoint", "pptx",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "txt", "text/plain", "rtf", "application/rtf"
            ];

            if (imageFormats.includes(fileType)) {
                return 'image';
            } else if (videoFormats.includes(fileType)) {
                return 'video';
            } else if (audioFormats.includes(fileType)) {
                return 'audio';
            } else if (docFormats.includes(fileType)) {
                return 'doc';
            } else {
                return 'unknown';
            }
        }

        const toggleViewAllAttachments = (index) => {
            setViewAllAttachments(prevState => {
                return { [index]: !prevState[index] };
            });
        };
        

        // Function : to render the uploaded files in the chat of comment
        const renderUploadedFiles = (files,) => {

            const filesToBeRendered = (mode === 'attachments' || outerMode === 'attachments_central' ) ? viewAllAttachments[commentIndex] ? files : files.slice(0, 2) : files;

            return <> 
            {filesToBeRendered?.map((file, fileIndex) => {
                let fileType = getFileTypeFromUrl(file?.fileUrl?.split('?')?.[0]);
                switch (checkFileType(fileType)) {
                    case "image":
                    case "video": {
                        return <div className={`cursor-pointer bg-white ${files?.length === 1 && (mode === 'attachments' || outerMode === 'attachments_central') ? 'max-w-[132px]' : 'max-w-[124px]'} w-full ${(mode !== 'mentions' && outerMode !== 'mentions_central') ? 'p-[10px] pb-1.5' : 'px-1 pt-0.5 pb-[3px]'} border border-gray-300 bg-whited duration-200 hover:opacity-75`} onClick={() => handleChange("openImageViewModal", {
                            file: file,
                            commentIndex: commentIndex,
                            fileIndex: fileIndex,
                        })}>
                            <div className={`${(mode === 'mentions' || outerMode === 'mentions_central') ? 'flex items-center gap-2' : 'space-y-1'}`}>
                                <div className={`relative aspect-square overflow-hidden object-cover object-center ${(mode === 'mentions' || outerMode === 'mentions_central') ? 'shrink-0 !h-[15px] !w-[15px]' : ''}`}>
                                    {checkFileType(fileType) === 'image' ?
                                        <img className='w-full h-full object-cover object-center' src={file?.fileUrl} />
                                        :
                                        <>
                                            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full p-[12px] bg-white/75 backdrop-blur-sm'><Play fill='black' color='black' /></div>
                                            <video className='w-full h-full object-cover object-center' src={file?.fileUrl} />
                                        </>
                                    }
                                </div>
                                {<div className={`flex items-center justify-between gap-2  ${(mode === 'mentions' && outerMode === 'mentions_central') ? 'overflow-hidden' : ''}`}>
                                    <p className={`min-w-[40px] text-xs ${(mode !== 'mentions' && outerMode !== 'mentions_central') ? 'text-[#67768E]' : 'text-fontBlack font-medium'} mb-0 truncate whitespace-nowrap`}>{file?.fileName || ""}</p>
                                    {(mode !== 'mentions' && outerMode !== 'mentions_central') && <GenericCustomButton
                                        tooltip={"Download"}
                                        leftIcon={<span><Download size={17} /></span>}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            window?.open(file?.fileUrl)
                                        }}
                                    />}
                                </div>}
                            </div>
                        </div>
                    }

                    default: {
                        return <div className={`bg-white h-full flex ${(mode !== 'mentions' && outerMode !== 'mentions_central') ? 'flex-col' : ''} gap-2 cursor-pointer ${files?.length === 1 && (mode === 'attachments' || outerMode === 'attachments_central') ? 'max-w-[132px]' : 'max-w-[124px]'} ${mode === 'attachments' || outerMode === 'attachments_central' ? '!w-[124px]' : ''} w-full border border-gray-300 ${(mode !== 'mentions' && outerMode !== 'mentions_central') ? 'px-3 pt-[8px] pb-1.5' : 'p-[2px]'} duration-200 hover:opacity-75`} onClick={() => handleChange("openImageViewModal", {
                            file: file,
                            commentIndex: commentIndex,
                            fileIndex: fileIndex,
                        })}>
                            <div className={`w-full h-full flex items-center justify-center aspect-square ${(mode === 'mentions' || outerMode === 'mentions_central') ? '!h-[15px] !w-[15px]' : ''}`}>{checkFileType(fileType) === 'audio' ? <FileMusic strokeWidth={1} size={50} /> : <File strokeWidth={1} size={50} />}</div>
                            {<div className={`flex items-center justify-between gap-2 ${(mode === 'mentions' || outerMode === 'mentions_central') ? 'overflow-hidden' : ''}`}>
                                <p className={`min-w-[40px] text-xs ${(mode !== 'mentions' && outerMode !== 'mentions_central') ? 'text-[#67768E]' : 'text-fontBlack font-medium'} mb-0 truncate whitespace-nowrap`}>{file?.fileName || ""}</p>
                               {(mode !== 'mentions' && outerMode !== 'mentions_central') && <div>
                                    <GenericCustomButton
                                        tooltip={"Download"}
                                        leftIcon={<span><Download size={17} /></span>}
                                        onClick={(e) => {
                                            e?.preventDefault()
                                            e?.stopPropagation()
                                            window?.open(file?.fileUrl)
                                        }}
                                    />
                                </div>}
                            </div>}
                        </div>
                    }
                }
            })}
            </>
        }
        
        const highlightedText = () => {
            // Get the comment body text or fallback to "-"
            const commentBody = commentItem?.["commentBody"] || "";
        
            // Get the keys from taggedUser which are the usernames
            let taggedUser = JSON.parse(commentItem?.taggedUser || "{}");
            const taggedUsernames = Object.keys(taggedUser);
        
            // Create a regular expression to match any tagged username
            const regex = new RegExp(`(@(${taggedUsernames.join('|')}))`, 'gi');
        
            // Check if there’s a match in the comment body
            const hasMatch = regex.test(commentBody);
        
            // If there’s a single tagged username and no other content
            if (hasMatch && commentBody.match(regex).length === 1 && commentBody.replace(regex, "").trim() === "") {
                const username = commentBody.match(regex)[0].substring(1); // remove @ symbol
                return (
                    <p className='font-medium mb-0'>
                        <span className='text-[#5D5FEF] font-semibold break-keep mr-1'>@{username}</span>
                    </p>
                );
            }
        
            // Replace matched usernames with a span that highlights the text and preserves unmatched @ symbols
            const highlightedText = commentBody.replace(regex, (match, p1) =>
                taggedUsernames.includes(p1.substring(1)) // check if username without @ is in taggedUsernames
                    ? `<span class='text-[#5D5FEF] font-semibold break-keep mr-1'>${match}</span>`
                    : match // leave unmatched @username as is
            );
        
            // Render the highlighted text with dangerouslySetInnerHTML to keep @ symbols
            return highlightedText ? (
                <p className='font-medium mb-0' dangerouslySetInnerHTML={{ __html: highlightedText }}></p>
            ) : null;
        };        

        let fileDirectory = JSON.parse(commentItem?.["fileDirectory"] || "[]");

        return <div 
            style={{ 
                overflowWrap: 'anywhere',
                boxShadow: mode === 'central' && !isMyMsg ? '2px 4px 3px 0px #948F8F40' : ''
             }} 
            className={`text-[#0F172A] break-all text-xs px-3 py-3 flex flex-col gap-3 
            ${(mode === 'normal' && isMyMsg) ? 'bg-[#E5F0FF] items-end' : (mode === 'central' && isMyMsg) ? 'bg-[#CFD1E1] items-end' : (mode === 'central' && !isMyMsg) ? '!bg-white' : (mode !== 'normal' && mode !== 'central') ? 'border mt-[8px]' : 'bg-[#F3F5F7] items-start'}
            ${(mode === 'attachments' || outerMode === 'attachments_central') ? '!border-none !px-0 !pb-2 !pt-[4px]' : ''}
            ${mode === 'central' ? isMyMsg ? 'rounded-lg rounded-br-none' : 'rounded-lg rounded-bl-none' : ''}
        `}
        
        >
            {mode !== 'attachments' && outerMode !== 'attachments_central' && highlightedText?.()}
            {fileDirectory?.length > 0 && <div className={`max-w-[255px] z-10 grid ${fileDirectory?.length > 1 && 'grid-cols-2'} gap-2.5 ${isMyMsg ? 'items-end' : 'items-start'}`}>
                {renderUploadedFiles(fileDirectory || [],)}
            </div>}
            {fileDirectory?.length > 0 && (mode === 'attachments' || outerMode === 'attachments_central' ) && fileDirectory?.length > 2 && <button 
                className='text-xs font-medium w-fit underline hover:text-primary hover:border-primary'
                onClick={()=>toggleViewAllAttachments(commentIndex)}
            >
                {viewAllAttachments[commentIndex] 
                    ? 'View Less' 
                    : fileDirectory?.length > 2 
                        ? `+${fileDirectory?.length - 2} More` 
                        : null
                }
            </button>}
        </div>
    }


    // Function : To check the comment has the mentions inside it
    const isCommentContainsMention = (item) => {
        if (item?.["commentBody"]) {
            let taggedUser = JSON.parse(item?.taggedUser || "{}")
            const taggedUsernames = Object.keys(taggedUser);
            if (taggedUsernames?.length > 0) return true
            else return false;
        }
        return false;
    }

    // Function : To check if the comment has the files inside it
    const isCommentContainsFiles = (item) => {
        if (item && item?.["fileDirectory"]) {
            let fileDirectory = JSON.parse(item?.["fileDirectory"] || "{}");
            if (fileDirectory?.length > 0) return true
            else return false
        } else return false;
    }

    // Function : To render the message here
    const renderMessages = () => {

        let tokenData = decodeToken(sessionStorage.getItem("token"));

        let commentsListData = commentListDataRef?.current || [];

        if(!isMoreDataAvailable){
            if (mode == "mentions") {
                commentsListData = commentsListData?.filter(item => isCommentContainsMention(item))
            }
    
            if (mode == "attachments") {
                commentsListData = commentsListData?.filter(item => isCommentContainsFiles(item))
            }
        }

        if (commentsListData?.length == 0 && !loading?.[getAllCommentsName]) {
            return <div className='flex-1 flex justify-center items-center flex-col gap-3 px-[12px]'>
                {mode == "normal" && <LanguageTranslator tag="p">No Conversation Available!</LanguageTranslator>}
                {mode == "mentions" && <LanguageTranslator tag="p">No Mentions Available!</LanguageTranslator>}
                {mode == "attachments" && <LanguageTranslator tag="p">No Attachments Available!</LanguageTranslator>}
                <LanguageTranslator tag="p">Add something</LanguageTranslator>
            </div>
        }


        if (mode == "mentions") {
            commentsListData = commentsListData?.filter(item => isCommentContainsMention(item))
        }

        if (mode == "attachments") {
            commentsListData = commentsListData?.filter(item => isCommentContainsFiles(item))
        }


        return (
            <div className={`modal-chat-container flex-1 flex flex-col ${(mode === 'normal' || mode === 'central') ? 'justify-end' : 'justify-start'} overflow-y-auto w-full relative scroll-smooth`}>
                {
                    commentsListData && commentsListData?.length !== 0 &&
                    (mode == "normal" || mode == "central" ) && <div className={`w-fit absolute top-12 left-1/2 -translate-x-1/2 z-[900] duration-100 ${isScrolledToBottom ? '-translate-y-full opacity-0 invisible pointer-events-none' : 'translate-y-0 opacity-100 visible pointer-events-auto'}`}>
                        <GenericCustomButton
                            rightIcon={<Icons.DownArrowWhite />}
                            onClick={() => scrollToBottom()}
                            label={"Jump to Recent Chats"}
                            className='flex items-center gap-1 rounded-full bg-black text-white font-normal text-xs pl-3 pr-2 pb-1.5 pt-[0.3rem] align-middle shadow-lg duration-300 transition-transform hover:bg-slate-700 active:scale-75'
                        />
                    </div>
                }
                <div className={`flex ${(mode === 'normal' || mode === 'central') ? 'flex-col-reverse' : 'flex-col'} scroll-smooth w-full relative ${(mode === 'attachments' || mode === 'mentions' || outerMode === 'mentions_central' || outerMode === 'attachments_central') ? 'py-3 px-4 overflow-auto' : 'pl-[12px] pr-[2px] overflow-y-scroll'}`} onScroll={handleScroll} ref={chatContainerRef}>

                    {commentsListData?.map((item, index) => {
                        let taggedUser = JSON.parse(item?.taggedUser || "{}")
                        const taggedUsernames = Object.keys(taggedUser);

                        let { createdByProfileImage, createdByUserName, creationTime, createdByUserId } = item?.["creationDetails"] || {};
                        let date = '';

                        if (index >= 1 && commentsListData?.[index - 1]?.creationDetails?.creationTime?.slice(0, 10) == creationTime?.slice(0, 10)) {

                        } else {
                            let newtime = commentsListData?.[index - 1]?.creationDetails?.creationTime?.slice(0, 10)
                            if (newtime && dayjs(newtime)?.format("YYYY-MM-DD") == dayjs()?.format("YYYY-MM-DD")) {
                                date = 'Today';
                            } else if (newtime && dayjs(newtime).isYesterday()) {
                                date = 'Yesterday';
                            } else {
                                date = newtime
                            }
                        }

                        const isImgAvailable = checkImageUrl(createdByProfileImage);

                        return (
                            <React.Fragment key={index}>

                                {date && (mode == "normal" || (mode === 'central' && outerMode !== 'mentions_central' && outerMode !== 'attachments_central' )) && <div className={`flex items-center w-full mb-8 sticky bottom-[0] left-[0] z-[899] py-1`}>
                                    <div className={`h-[1px] w-full ${mode === 'central' ? 'bg-[#CFD1E1]' : 'bg-[#E3E4E7]'}`}></div>
                                    <div className={`rounded-full border px-3 py-0.5 text-xs sticky top-0 whitespace-nowrap ${mode === 'central' ? 'border-[#CFD1E1]' : 'text-[#939497] border-[#E3E4E7]'}`}>
                                        {date ?? ''}
                                    </div>
                                    <div className={`h-[1px] w-full ${mode === 'central' ? 'bg-[#CFD1E1]' : 'bg-[#E3E4E7]'}`}></div>
                                </div>}

                                <div className={`w-full mb-8`}>
                                    <div className={`w-fit ${(mode === 'attachments' || mode === 'mentions' || outerMode === 'mentions_central' || outerMode === 'attachments_central') ? 'max-w-full' : mode === 'central' ? 'max-w-[85%] xl:max-w-[60%]' : 'max-w-[85%]'} ${mode === 'mentions' || outerMode === 'mentions_central' ? 'flex gap-2 items-start' : ''} flex items-start gap-3 ${(mode === 'normal' || mode === 'central') && tokenData?.["jti"] === createdByUserId ? 'flex-row float-end pr-[12px]' : 'flex-row-reverse float-start'}`}>
                                        <div className={`relative ${'mt-[4px]'}`}>
                                            {mode === 'central' && 
                                                <div class={`absolute bottom-0 w-0 h-0 border-[1rem] border-transparent border-l-[1rem] ${tokenData?.["jti"] === createdByUserId ? '-right-2 border-b-[#CFD1E1]' : '-left-2 border-b-white'}`}></div>
                                            }
                                            <div className={`${((mode === 'normal' || mode === 'central') && tokenData?.["jti"] === createdByUserId) ? 'justify-end' : 'justify-start'} flex flex-wrap items-baseline gap-2 mb-1.5 text-[#767B88]`}>
                                                {(mode === 'attachments' || outerMode === 'attachments_central') && <div className='mb-0 self-center'><Paperclip color='black' size={16} /></div>}
                                                <p className={`m-0 font-medium text-xs ${(mode === 'attachments' || mode === 'mentions' || outerMode === 'mentions_central' || outerMode === 'attachments_central') ? 'text-fontBlack border px-1.5' : ''}`}>{((mode === 'attachments' || mode === 'mentions' || outerMode === 'mentions_central' || outerMode === 'attachments_central') && tokenData?.["jti"] === createdByUserId) ? 'You' : createdByUserName}</p>
                                                {mode == "mentions" && <><span className='text-fontBlack text-xs'>mentioned</span> {taggedUsernames?.map(tag => <p className='mb-0 font-medium border px-1.5 text-fontBlack text-xs'>{tag}</p>)}</>}
                                                {(mode === "normal" || mode === 'central' || mode === "attachments" || outerMode === 'attachments_central') && <p className='m-0 font-normal text-[10px]'>{creationTime ? dayjs(creationTime?.slice(0, 19))?.format('hh:mm A') : ""}</p>}
                                            </div>
                                            {renderCommentMessage({
                                                commentItem: item,
                                                commentIndex: index,
                                                isMyMsg: tokenData?.["jti"] === createdByUserId
                                            })}
                                        </div>
                                        {(mode === 'mentions' || outerMode === 'mentions_central') && <div className='bg-[#EE5A2433] p-[6px] rounded-full'><AtSign size={16} color='#EE5A24' /></div>}
                                        {(mode === "normal" || mode === 'central') && (createdByProfileImage && isImgAvailable ?
                                            <div className='mt-[1.5rem] min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px] rounded-full overflow-hidden'>
                                                <img className='h-full w-full object-cover object-center' alt="profileUser" src={createdByProfileImage} />
                                            </div>
                                            : <div className='mt-[1.5rem] border rounded-full bg-gray-100 p-[7px]'>
                                                <Icons.ProfileFaceIcon />
                                            </div>)
                                        }
                                    </div>
                                </div>

                                {/* {date && (mode !== "normal" && mode !== 'central') && <div className={`flex items-center w-full mb-8 sticky top-[0] left-[0] z-[899] py-1`}>
                                    <div className='h-[1px] w-full bg-[#E3E4E7]'></div>
                                    <div className='rounded-full border border-[#E3E4E7] px-3 py-0.5 text-xs text-[#939497] sticky top-0 whitespace-nowrap'>
                                        {date ?? ''}
                                    </div>
                                    <div className='h-[1px] w-full bg-[#E3E4E7]'></div>
                                </div>} */}

                            </React.Fragment>
                        )
                    })}

                    {!loading?.[getAllCommentsName] ? isMoreDataAvailable && <div ref={lastListElementRef} className='h-[30px] w-full' id="topMessageDiv"></div> : <div className={`space-y-4 ${(mode === 'normal' || mode === 'central') ? 'px-[12px]' : ''}`}>
                        {commentsListData?.length == 0 ? renderLoadingSkeleton(mode === 'normal' || (mode === 'central' && outerMode !== 'mentions_central' && outerMode !== 'attachments_central') ? 'message' : (outerMode === 'mentions_central' ? 'mentions' : outerMode === 'attachments_central' ? 'attachments' : mode)) :
                            <div className='flex items-center gap-4 justify-center mt-20 mb-8'><div className='max-w-20 overflow-hidden'><GenericSpinnerLoader /></div> <span className='whitespace-nowrap'>Fetching {(mode === 'mentions' || outerMode === 'mentions_central') ? 'mentions' : (mode === 'attachments' || outerMode === 'attachments_central') ? 'attachments' : 'messages'}...</span></div>
                        }
                    </div>}
                    <div className='h-10 w-full mb-12'></div>

                </div>


                <div id="bottomMessagesDiv"></div>
            </div>
        )
    }

    useEffect(() => {
        let obj;
        if (mode == "normal" || mode == "central") {
            obj = {
                "json_validation_error": (data) => handleSocketEventError(data, "json_validation_error"),
                "add_comment": (data) => handleEventSuccess(data, { eventName: "add_comment" }),
                "get_all_comments_normal": (data) => handleEventSuccess(data, { eventName: "get_all_comments_normal" }),
                "new_comment_addition_chat": (data) => handleEventSuccess(data, { eventName: "new_comment_addition_chat" })
            }
        } else {
            obj = {
                [`${getAllCommentsName}`]: (data) => handleEventSuccess(data, { eventName: getAllCommentsName }),
            }
        }

        Object.keys(obj || {})?.forEach(key => {
            socket.on(key, obj?.[key]);
        })

        return () => {
            Object.keys(obj || {})?.forEach(key => {
                console.log("Off socket event", key);
                socket.off(key);
            })
        }

    }, [])

    const previousErrorRef = useRef(null)

    useEffect(() => {
        const currentError = errorData?.join(', ') || 'An Unexpected error has occurred';

        if (previousErrorRef.current !== currentError && errorData) {
            messageApi.error({
                type: 'error',
                content: currentError,
                style: {
                    position: 'fixed',
                    top: '5rem',
                    right: '60px',
                    width: 'auto',
                    margin: '0',
                    textAlign: 'right',
                    zIndex: '999',
                },
            });
            setTimeout(() => {
                setErrorData(null)
            }, 3000)
        }

        // Update the ref with the current error
        previousErrorRef.current = errorData?.join(',')
    }, [errorData]);

    const getChildComponent = () => {
        switch (selectedModal) {
            case "openImageViewModal": return GenericMediaViewModal
            default:
        }
    }

    const getParentChildProps = () => {
        switch (selectedModal) {
            case "openImageViewModal": {
                return {
                    animationType: "bottom",
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: "fixed", borderRadius: 4 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "80%", height: "80%", maxHeight: '660px', maxWidth: '1140px' },
                    closeModal : () => setSelectedModal(false),
                    filesData: selectedData?.allFiles?.map(file => {
                        console.log("File chjeck", file)
                        let extName = getFileTypeFromUrl(file?.fileUrl);
                        let type = getFileNameFromExt(extName)
                        return {
                            ...file,
                            fileType: type,
                        }
                    }),
                    current: selectedData?.current
                }
            }
            default:
        }
    }


    // console.log('currMode', mode)

    return (
        <>
            <div className={`commentsModal h-full flex items-start border-r border-[#E3E4E7] min-w-[300px] ${mode === 'central' ? 'bg-[#F3F4F9]' : 'bg-white'} ${mode === 'central' && 'w-full'}`}>
                <div className={`flex-1 flex flex-col ${mode === 'central' || outerMode === 'mentions_central' || outerMode === 'attachments_central' ? 'max-w-full h-full' : 'max-w-[500px] max-h-screen h-screen'} ${mode === 'mentions' || mode === 'attachments' || outerMode === 'mentions_central' || outerMode === 'attachments_central' ? '!max-w-[300px]' : ''}`}>

                    {renderCommentHeaderDetails()}

                    {mode == "normal" && <div className='pt-2 pb-1 px-[12px]'>
                        {renderSearchComponent()}
                    </div>}
                    {renderMessages()}

                    {(mode === 'normal' || mode === 'central') && <div className='px-[12px] pb-3 z-10'>
                        {renderMessageEditor()}
                    </div>}
                </div>
            </div>
            {contextHolder}

            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false); setSelectedData("") }}
            />}
        </>
    )
}

export default CommentBoxModal