import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { openDB } from 'idb';

export function CreateFilterAndHeaderManagement() {
    const generalStore = useSelector(state => state.generalStore);

    // Handle the create Headers api response
    useEffect(() => {
        if (generalStore?.["createHeadersData"]?.isSuccess) {
            let data = generalStore?.["createHeadersData"]?.data;
            console.log("SADFasdf", data);
            addHeaderToDatabase(data?.displayName, data);
        }
    }, [generalStore?.["createHeadersData"]?.isSuccess])

    useEffect(() => {
        if (generalStore?.["createQuickFiltersData"]?.isSuccess) {
            let data = generalStore?.["createQuickFiltersData"]?.data;
            if (data?.isDeleteFilter) {
                addFilterToDatabase(data?.displayName, data, "delete")
            } else if (data?.is_default != undefined) {
                addFilterToDatabase(data?.displayName, data, "update")
            } else {
                addFilterToDatabase(data?.displayName, data);
            }
        }
    }, [generalStore?.["createQuickFiltersData"]?.isSuccess])


    // Add or replace the header key updated data inside the database.
    const addHeaderToDatabase = async (itemKey, itemValue) => {
        console.log("Item value check", itemValue);
        const db = await openDB("Supplymint-ai-local-storage", 1);
        let value = await db.get("MASTER_DB_HEADER", itemKey);
        let headersData = {
            "Custom Headers": itemValue?.["Custom Headers"] || {},
            "Default Headers": itemValue?.["Default Headers"] || value?.["Default Headers"] || {},
            "Fixed Headers": itemValue?.["Fixed Headers"] || value?.["Fixed Headers"]  || {},
            "exportFilters" : itemValue?.["exportFilters"] || value?.["exportFilters"] || "",
            "isDisplay" : itemValue?.["isDisplay"] ? 1 : 0,
            "freezeColumn" : itemValue?.["freezeColumn"] || value?.["freezeColumn"] || "",
        }
        if (value) {
            await db.put("MASTER_DB_HEADER", value ? { ...value, ...headersData } : { ...headersData }, itemKey)
        } else {
            await db.add("MASTER_DB_HEADER", value ? { ...value, ...headersData } : { ...headersData }, itemKey)
        }
        db?.close();
    }

    // Add or replace the filter key updated data inside the database.
    const addFilterToDatabase = async (itemKey, itemValue, type) => {
        const db = await openDB("Supplymint-ai-local-storage", 1);
        let value = await db.get("MASTER_DB_FILTER", itemKey);
        switch (type) {
            case "delete": {
                if (value) {
                    let index = value.findIndex(item => item.filterName === itemValue?.filterName?.[0]);
                    if (index !== -1) {
                        value?.splice(index, 1);
                    }
                    db.put("MASTER_DB_FILTER", value, itemKey);
                }
                break;
            }
            case "update": {
                value?.forEach(item => {
                    if (item.filterName === itemValue?.filterName?.[0]) {
                        item.is_default = itemValue?.is_default || 0;
                    }
                    else {
                        item.is_default = 0;
                    }
                });
                db.put("MASTER_DB_FILTER", value, itemKey);
                break;
            }
            default: {
                let index = value?.findIndex(item => item?.filterName === itemValue?.filterName?.[0]);
                if (value?.length > 0) {
                    value = [];
                }
                console.log("Index value", itemValue, index, value);
                if (index !== -1) {
                    value[index].filterValue = itemValue?.filterValue;
                    await db.put("MASTER_DB_FILTER", value, itemKey)
                }
                else {
                    value.push({
                        filterName: itemValue?.filterName?.[0],
                        filterValue: itemValue?.filterValue,
                        is_default: 0
                    });
                    await db.put("MASTER_DB_FILTER", value, itemKey)
                }
            }
                break;
        }
        db?.close();
    }

    return (
        <React.Fragment>

        </React.Fragment>
    )
}
