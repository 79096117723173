import React, { useRef } from 'react'
import GenericTableComponent from '../genericComponents/tableComponent/GenericTableComponent'
import { useSelector } from 'react-redux'
import { getPermissionKey } from '../helper/permissions'

const AuditLogs = () => {
    const generalStore = useSelector(state => state.generalStore)
    const tableComponentRef = useRef();

    const tableHeadersMap = {
        main: {
            name: "Main",
            key: "AUDIT_LOG"
        },
        details: {
            name: "Details",
            key: "AUDIT_LOG_DETAILS"
        }
    }

    return (
        <>
            <GenericTableComponent
                actionBarConfig={{
                    isItemAvailable: true,
                }}

                tableConfig={{
                    select: {
                        disabled: false
                    },
                    customCols: {
                        orderNumber: item => <React.Fragment><label className="!font-medium whiteUnset wordBreakInherit">{item.orderNumber} </label></React.Fragment>
                    }
                }}
                // Expand First Props
                getExpandFirstPayload={(item) => ({
                    uuid:item.uuid,
                    apiUrl: "/system/config/audit/log/det",
                })}
                getExpandFirstExtraHeaders={getPermissionKey("administration", "auditlogs", "view")}
                expandFirstKey={(item) => "details"}
                getExpandFirstListData={(data) => data?.resource?.data || [] }

                tableHeadersPayload={tableHeadersMap}
                tableHeadersMap={tableHeadersMap}
                apiUrl='/system/config/get/all/auditLog'
                tableComExtraHeaders={getPermissionKey("administration", "auditlogs", "view")}
                tableData={
                    {
                        data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
                        totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                        currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                        maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                        getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                    }
                }
                availableStores={["generalStore"]}
                ref={tableComponentRef}
            />
        </>
    )
}

export default AuditLogs