import React, { useEffect, useState } from 'react'
import GenericTabsComponent from '../../../genericComponents/GenericTabsComponent';
import DSRSummary from './DSRSummary';
import PANIndia from './PANIndia';
import AsmYtd from './AsmYtd';
import DivisionWiseSales from './DivisionWiseSales';
import DailySalesTracker from './DailySalesTracker';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import Icons from '../../../assets/icons';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler, parseJSON } from '../../../helper/apiHelperFunctions';
import { scaleQuantile } from 'd3-scale';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';

const PREV_YEAR = (new Date()).getFullYear() - 1;
// ? Split "month" in cases where data is displayed month-wise
const splitMonth = data => {
    return data.map(item => {
        let temp = item?.month?.split("-");
        if (temp[1]) {
            return { ...item, month: temp[1] };
        }
        return item;
    });
}
// ? Make JSON for Store Grade Contribution - Sales Qty/Value
const getStoreGradeContri = (data, type) => {
    try {
        let temp = {}, storeGradeContri = [];
        data.forEach(item => {
            if (!temp?.[item?.grade]) {
                temp[item?.grade] = {};
            }
            temp[item?.grade] = {
                [item?.section]: item[type],
                ...temp[item?.grade]
            };
            delete temp[item?.grade]?.grade;
        });
        Object.keys(temp).forEach(key => {
            key != "null" && storeGradeContri?.push({
                grade: key,
                ...temp[key]
            });
        });
        return storeGradeContri;
    }
    catch (e) {
        console.log(e);
        return [];
    }
};
let colorScale = Function.prototype;

const PerformanceSummaryDashboard = (props) => {
    const dispatch = useDispatch()
    const digivendStore = useSelector(state => state.digivendStore)
    const [currentTab, setCurrentTab] = useState('dsrSummary')
    const [dashboardData, setDashboardData] = useState([])

    const tabsMapping = {
        dsrSummary: "SUMMARY",
        panIndia: "PAN_INDIA",
        asm: "ASM_YTD",
        divisionWiseSales: "PAN_INDIA",
        dailySalesTracker: "DSR_SUMMARY",
    }
    const exportToExcel = () => {
        // var workbook, worksheet;
        // workbook = XLSX.utils.book_new();
        // worksheet = XLSX.utils.table_to_sheet(document.getElementById("dailySalesTrackerTable"), { raw: true });
        // worksheet["!cols"][0] = { hidden: true };
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        // XLSX.writeFile(workbook, "DAILY_SALES_TRACKER" + ".xlsx");
    }
    // ? API call to generate data
    const refreshData = () => {
        dispatchHandler(dispatch, 'saveSiteAndItemFilterRequest', {
            filterType: "panasm",
            data: {
                end_date: dayjs().format("YYYY-MM-DD")
            }
        }, getPermissionKey("dashboard", "performancesummary", "view"))
    }
    useEffect(() => {
        getDashboardData()
    }, [currentTab])

    // Country Map Constants
    const COUNTRY_CHART_COLORS = [
        "#DED8FD", "#D4CCFD", "#C9C0FD", "#BFB4FD",
        "#B4A7FC", "#AA9BFC", "#9F8EFB", "#9482FB",
        "#8975FA"
    ];

    const [dataType, setDataType] = useState('salesQty')
    const getDataType = (type) => {
        setDataType(type)
    }

    // setting the dashboard data
    useEffect(() => {
        if (digivendStore?.getARSDashboardDataData?.isSuccess && digivendStore?.getARSDashboardDataData?.data?.resource) {
            let res = digivendStore?.getARSDashboardDataData?.data?.resource, temp = {};
            if (currentTab === "dsrSummary") {
                res?.dsrSummary && res?.dsrSummary?.forEach(item => {
                    if (!temp[item?.channel_wise]) {
                        temp[item?.channel_wise] = {}
                    }
                    if (!temp[item?.channel_wise][item?.store_type]) {
                        temp[item?.channel_wise][item?.store_type] = {
                            compLabels: {
                                "salesQty": "vs Previous Day",
                                "salesValue": "vs Previous Day",
                                "wtdSalesQty": "vs Previous Week",
                                "wtdSalesValue": "vs Previous Week",
                                "mtdSalesQty": `vs ${PREV_YEAR} MTD`,
                                "mtdSalesValue": `vs ${PREV_YEAR} MTD`,
                                "ytdSalesQty": `vs ${PREV_YEAR} YTD`,
                                "ytdSalesValue": `vs ${PREV_YEAR} YTD`,
                                "ytdUPT": `vs ${PREV_YEAR} YTD`,
                                "ytdATV": `vs ${PREV_YEAR} YTD`
                            }
                        }
                    }
                    temp[item?.channel_wise][item?.store_type][item?.type1 === "percentage" ? "percentages" : 'values'] = {
                        "salesQty": item?.sales_qty,
                        "salesValue": item?.retail_sales,
                        "wtdSalesQty": item?.wtd_sales_qty,
                        "wtdSalesValue": item?.wtd_retail_sales,
                        "mtdSalesQty": item?.mtd_sales_qty,
                        "mtdSalesValue": item?.mtd_retail_sales,
                        "ytdSalesQty": item?.ytd_sales_qty,
                        "ytdSalesValue": item?.ytd_retail_sales,
                        "ytdUPT": item?.ytd_upt,
                        "ytdATV": item?.ytd_atv
                    }

                });
                setDashboardData(temp);
            }
            else if (currentTab === "panIndia") {
                if (digivendStore?.getARSDashboardDataData?.data?.resource?.regionWiseContriPer) {
                    colorScale = scaleQuantile()?.domain(digivendStore?.getARSDashboardDataData?.data?.resource?.regionWiseContriPer?.map(d => d?.value))?.range(COUNTRY_CHART_COLORS);
                }
                let storeGradeContri = getStoreGradeContri(res?.storeGradeContri, dataType),
                    monthWiseSales = splitMonth(res?.monthWiseSales),
                    monthWiseASP = splitMonth(res?.monthWiseASP);
                setDashboardData({ ...res, storeGradeContri, monthWiseSales, monthWiseASP });
            }
            else if (currentTab === "asm") {
                let regionWiseSectionContriPer = [],
                    monthWiseSales = splitMonth(res?.monthWiseSales),
                    monthWisePriceTypeSales = splitMonth(res?.monthWisePriceTypeSales);
                res?.regionWiseSectionContriPer?.forEach(item => {
                    if (!temp[item?.region]) {
                        temp[item?.region] = {};
                    }
                    temp[item?.region] = { ...temp[item?.region], ...item };
                    delete temp[item?.region]?.region;
                });
                Object.keys(temp)?.forEach(key => {
                    key != "null" && regionWiseSectionContriPer?.push({
                        region: key,
                        ...temp[key]
                    });
                });
                setDashboardData({ ...res, regionWiseSectionContriPer, monthWiseSales, monthWisePriceTypeSales });
            }
            else if (currentTab === "dailySalesTracker") {
                res.dsrDetails = parseJSON(res?.dsrDetails, true);
                Object.keys(res?.dsrTotal).forEach(key => {
                    res.dsrTotal[key] = parseJSON(res?.dsrTotal?.[key], true);
                });
                setDashboardData({ ...res });
            }
            else {
                setDashboardData({ ...res });
            }
        }
    }, [digivendStore?.getARSDashboardDataData?.isSuccess, currentTab]);

    // function to get the dashboard data
    const getDashboardData = (filtersData = {}) => {
        setDashboardData([]);
        let apiPayload = { filter: {}, type: tabsMapping[currentTab] }
        Object.keys(filtersData).forEach((filter) => {
            switch (filter) {
                default:
                    apiPayload.filter[filter] = filtersData[filter];
                    break;
            }
        });
        dispatchHandler(dispatch, 'getARSDashboardDataRequest', apiPayload, getPermissionKey("dashboard", "performancesummary", "view"));
    }

    return (
        <div className='performance-summary'>
            <div className='ps-filter-tab'>
                <GenericTabsComponent
                    tabsData={{
                        dsrSummary: "DSR Summary",
                        panIndia: "PAN India - YTD",
                        asm: "ASM - YTD",
                        divisionWiseSales: "Division Wise Sales",
                        dailySalesTracker: "Daily Sales Tracker",
                    }}
                    tabsKey={"currentTab"}
                    handleTabChange={(val) => {
                        setCurrentTab(val);
                    }}
                    current={currentTab}
                    type={1}
                />
                <div className="psf-right">
                    <span>
                        <i><LanguageTranslator>All amount values are in</LanguageTranslator> <LanguageTranslator tag="b">millions</LanguageTranslator></i>
                    </span>
                    {currentTab === "dailySalesTracker" &&
                        <GenericCustomButton
                            className='psfr-download-btn'
                            label='Download Report'
                            onClick={exportToExcel}
                            leftIcon={<Icons.ReportDownloadIcon />}
                        />}
                    <GenericCustomButton
                        className='psfr-refresh-btn'
                        label='Refresh Data'
                        loading={digivendStore?.saveSiteAndItemFilterData?.isLoading}
                        onClick={refreshData}
                    />

                </div>
            </div>

            {currentTab === 'dsrSummary' && <DSRSummary getDashboardData={getDashboardData} dsrSummaryData={dashboardData} />}
            {currentTab === 'panIndia' && <PANIndia colorScale={colorScale} getDashboardData={getDashboardData} getDataType={getDataType} dashboardData={dashboardData} />}
            {currentTab === 'asm' && <AsmYtd getDashboardData={getDashboardData} dashboardData={dashboardData} />}
            {currentTab === 'divisionWiseSales' && <DivisionWiseSales getDashboardData={getDashboardData} dashboardData={dashboardData} />}
            {currentTab === 'dailySalesTracker' && <DailySalesTracker getDashboardData={getDashboardData} dashboardData={dashboardData} />}

        </div>)
}

export default PerformanceSummaryDashboard