/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { parseJSON, } from '../helper/apiHelperFunctions';
import { openDB } from 'idb';
import ParentModal from '../genericComponents/ParentModal';
import PrintDocument from '../printDocument/PrintDocument';

const MasterDbData = () => {

    const [selectedModal, setSelectedModal] = useState(null);
    const [selectedData, setSelectedData] = useState(null);

    const generalStore = useSelector(state => state.generalStore);
    const salesManagement = useSelector(state => state.salesManagementStore)

    // ---------------------------------------- GET USER INFORMATION ---------------------------------------------- //

    // Function -> To create the instances  databases
    const addFilterInDatabase = async (key, data) => {
        const db = await openDB("Supplymint-ai-local-storage", 1);
        switch (key) {
            case "MASTER_DB_FILTER": {
                // Add the filters inside the MASTER_DB_FILTER;
                let filters = {};
                data?.forEach(item => {
                    let key = item.display_name;
                    if (filters[key] === undefined) {
                        filters[key] = [];
                    }
                    filters[key].push({
                        filterName: item.filter_name,
                        filterValue: JSON.parse(item.filter_value),
                        is_default: item.is_default
                    });
                })
                Object.keys(filters).forEach(async (element) => {
                    addKeyToDatabase(db, element, filters?.[element] || {}, key);
                });
                break;
            }
            case "MASTER_DB_HEADER": {
                var headers = {};
                const HEADMAP = {
                    COLUMNMAP: "tableColumnMap",
                    COLUMNTYPE: "columnType",
                    CUSTOM: "Custom Headers",
                    DEFAULT: "Default Headers",
                    EXPORTFILTERS: "exportFilters",
                    FIXED: "Fixed Headers",
                    LOVVALUE: "Lov Headers",
                    MANDATE: "Mandate Headers",
                    TABLENAME: "tableName",
                    ISDISPLAY : "isDisplay",
                    FREEZECOLUMN: "freezeColumn"
                }
                data?.forEach((item) => {
                    let fixedPart = item.key.split("-")[0];
                    let varPart = item.key.split("-")[1];
                    if (headers[fixedPart] === undefined) {
                        headers[fixedPart] = {
                            "tableColumnMap": {},
                            "columnType": {},
                            "Custom Headers": {},
                            "Default Headers": {},
                            "exportFilters": null,
                            "Fixed Headers": {},
                            "Lov Headers": {},
                            "Mandate Headers": {},
                            "tableName": {},
                            "isDisplay" : 1,
                            "freezeColumn": ""
                        }
                    }
                    headers[fixedPart][HEADMAP[varPart]] = parseJSON(item.fixed_header);
                });
                Object.keys(headers).forEach((itemKey) => {
                    addKeyToDatabase(db, itemKey, headers?.[itemKey] || {}, key);
                });
                break;
            }
            case "MASTER_DB_DROPDOWN": {
                let dropdown = {};
                data.forEach(async (item) => {
                    dropdown[item.key] = JSON.parse(item.value);
                });
                Object.keys(dropdown || {}).forEach(async (itemKey) => {
                    addKeyToDatabase(db, itemKey, dropdown?.[itemKey] || {}, key);
                })
                break;
            }
            case "MASTER_DB_COLORS": {
                Object.keys(data || {}).forEach(async (itemKey) => {
                    addKeyToDatabase(db, itemKey, data?.[itemKey] || {}, key);
                })
                break;
            }
            case "MASTER_DB_DOWNLOAD_DOCUMENTS": {
                data?.map(item => {
                    addKeyToDatabase(db, item?.displayName, {
                        ...item,
                        "type": item?.["type"] || []
                    } || {}, key)
                })
                break;
            }
        }
        db?.close();
    }

    const addKeyToDatabase = async (db, itemKey, itemValue, storeKey) => {
        await db.put(storeKey, itemValue || {}, itemKey)
    }


    // Use Effect -> To handle the headers 
    useEffect(() => {
        if (generalStore?.getMasterDbFilterData?.isSuccess) {
            let filterData = generalStore?.getMasterDbFilterData?.data?.resource?.response || [];
            setTimeout(() => {
                addFilterInDatabase("MASTER_DB_FILTER", filterData);
            }, 0)
        }
    }, [generalStore?.getMasterDbFilterData])

    useEffect(() => {
        if (generalStore?.getMasterDbDropdownData?.isSuccess) {
            let dropdownData = generalStore?.getMasterDbDropdownData?.data?.resource?.response || [];
            setTimeout(() => {
                addFilterInDatabase("MASTER_DB_DROPDOWN", dropdownData);
            }, 0)
        }
    }, [generalStore?.getMasterDbDropdownData])

    useEffect(() => {
        if (generalStore?.getMasterDbHeaderData?.isSuccess) {
            let dropdownData = generalStore?.getMasterDbHeaderData?.data?.resource?.response || {};
            setTimeout(() => {
                addFilterInDatabase("MASTER_DB_HEADER", dropdownData);
            }, 0)
        }
    }, [generalStore?.getMasterDbHeaderData])

    // Handle the response of the master db document download api.
    useEffect(() => {
        if (generalStore?.["getMasterDbDocumentDropdownData"]?.isSuccess) {
            let documentDownloadData = generalStore?.["getMasterDbDocumentDropdownData"]?.data?.resource?.downloadConfig || [];
            setTimeout(() => {
                addFilterInDatabase("MASTER_DB_DOWNLOAD_DOCUMENTS", documentDownloadData);
            }, 0)
        }
    }, [generalStore?.["getMasterDbDocumentDropdownData"]?.isSuccess])

    useEffect(() => {
        if (salesManagement?.getSoColorsApiRequestData?.isSuccess) {
            let colorsData = generalStore?.getMasterDbHeaderData?.data?.data || {};
            setTimeout(() => {
                addFilterInDatabase("MASTER_DB_COLORS", colorsData);
            }, 0)
        }
    }, [salesManagement?.getSoColorsApiRequestData])

    // Handle the response of the download document api
    useEffect(() => {
        if (generalStore?.["downloadDocumentsData"]?.isSuccess) {
            let url = generalStore?.["downloadDocumentsData"]?.data?.resource?.["url"] || "";
            if (url) {
                let data = generalStore?.["downloadDocumentsData"]?.data?.resource;
                if(url?.substring(-4)?.includes('.txt')){
                    setSelectedData(data);
                    setSelectedModal("printDocument")
                }else{
                    window.open(url);
                }
            }
        }
    }, [generalStore?.["downloadDocumentsData"]?.isSuccess])

    // ------------------------------------ END GET USER INFORMATION ---------------------------------------------- //

    // Store user session id in session
    // useEffect(() => {
    //     if(generalStore?.["userSessionCreateData"]?.isSuccess) {
    //         console.log("User is running")
    //         sessionStorage.setItem("loginId", generalStore?.["userSessionCreateData"]?.data?.resource?.id)
    //     }
    // }, [generalStore?.["userSessionCreateData"]?.isSuccess])

    const getParentChildProps = () => {
        switch(selectedModal){
            case "printDocument" : {
                return{
                    closeModal: () => { setSelectedModal(null) },
                    // animationType: "bottom",
                    animationType: "bottom",
                    // postitionProps: { top: "20%", left: "15%", borderRadius: 10},
                    // postitionProps: { top: "50%", left: "50%", transform: 'translate(-50%, -50%)', transformOrigin: 'center'},
                    parentModalOuterMostClassName: 'flex items-center justify-center',
					postitionProps: { position: 'relative' },
                    // dimensions: { width: "70vw", height: "60vh" },
                    dimensions: {width: "450px", height: 'fit-content', maxHeight: "610px", overflowY:'auto', borderRadius:'0'},
                    selectedData : selectedData
                }
            }
        }
    }

    const getChildComponent = () => {
        switch(selectedModal){
            case "printDocument" : return PrintDocument
        }
    }

    return (
        <React.Fragment>

            {selectedModal ? <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            /> : null}

        </React.Fragment>
    )
}

export default MasterDbData