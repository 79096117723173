import React, { useEffect, useRef, useState } from 'react'
import GenericFormFooterComponent from '../../genericComponents/forms/GenericFormFooterComponent'
import Icons from '../../assets/icons'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import MenuBar from './MenuBar'
import IndustryFabricDetailsForm from './IndustryFabricDetailsForm'
import FactoryDetails from './FactoryDetails'
import CompanyLocation from './CompanyLocation'
import MediaUpload from './MediaUpload'
import DocumentUpload from './DocumentUpload'
import TransactionDetail from './TransactionDetail'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { finalSubmitErrorValidationsCheck } from './components/CommonFunctions'
import '../../styles/stylesheets/home/VendorOnboarding.scss'
import { getPermissionKey } from '../../helper/permissions'

const VendorOnboarding = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const authStore = useSelector(state => state.authStore)

    const [menuBarData, setMenuBarData] = useState([])
    const [percenatgeData, setPercentageData] = useState({})
    const [currentStep, setCurrentStep] = useState(location.state ? location?.state?.initialStep : { index: 1, code: 'IFABDET' } || { index: 1, code: 'IFABDET' })
    const [menuBarDetailsData, setMenuBarDetailsData] = useState([])
    const [desiredPayload, setDesiredPayload] = useState([])
    const [initialPayloadData, setInitialPayloadData] = useState([])
    const [formData, setFormData] = useState({
        inputData: {},
        errorData: {},
        setErrorData: () => { }
    })

    const brandsArrayRef = useRef(null);

    // function to get the menu bar
    useEffect(() => {
        dispatchHandler(dispatch, 'getMenuDetailsRequest', { type: 'menuBar' }, getPermissionKey("masterData", "onboardingrequest", "view"))
        dispatchHandler(dispatch, 'getVendorOnboardingPercentageRequest', getPermissionKey("masterData", "onboardingrequest", "view"))
    }, [])

    useEffect(() => {
        if (authStore?.getMenuDetailsData?.isSuccess && authStore?.getMenuDetailsData?.data?.resource) {
            setMenuBarData(authStore?.getMenuDetailsData?.data?.resource || [])
        }
    }, [authStore?.getMenuDetailsData?.isSuccess])

    useEffect(() => {
        if (authStore?.getVendorOnboardingPercentageData?.isSuccess) {
            setPercentageData(authStore?.getVendorOnboardingPercentageData?.data)
        }
    }, [authStore?.getVendorOnboardingPercentageData?.isSuccess])

    // function to get the menubardata after getting the menubar
    useEffect(() => {
        dispatchHandler(dispatch, 'getMenuBarDetailsRequest', { type: 'menuBarData', code: currentStep?.code }, getPermissionKey("masterData", "onboardingrequest", "view"))
    }, [currentStep?.code])

    // function to gget the form data
    useEffect(() => {
        const getUpdatedMenuList = (data) => {
            return data?.map(item => {
                if (item?.value?.length == 1 && item?.value?.[0]?.["isMandatory"] == 1) {
                    return { ...item, isSectionMandate: 1 }
                }
                return item;
            })
        }

        if (authStore?.getMenuBarDetailsData?.isSuccess && authStore?.getMenuBarDetailsData?.data?.resource) {
            setMenuBarDetailsData(getUpdatedMenuList(authStore?.getMenuBarDetailsData?.data?.resource || []));
        }
    }, [authStore?.getMenuBarDetailsData?.isSuccess])

    // logic to call the percentage api after form data save
    useEffect(() => {
        if (authStore?.saveVendorOnBoardingDetailsData?.isSuccess) {
            dispatchHandler(dispatch, 'getVendorOnboardingPercentageRequest', {}, getPermissionKey("masterData", "onboardingrequest", "view"))
        }
    }, [authStore?.saveVendorOnBoardingDetailsData?.isSuccess])

    const flattenedMenuBars = menuBarData?.flatMap(item => item?.menuBar);
    const totalSteps = flattenedMenuBars?.length;
    // function to handle button changes
    const handleChange = (type) => {
        switch (type) {
            case 'handlePrevious':
                if (currentStep.index > 1) {
                    const prevIndex = currentStep.index - 1;
                    const prevCode = flattenedMenuBars[prevIndex - 1]?.code;
                    setCurrentStep({ index: prevIndex, code: prevCode });
                }
                break;
            case 'handleNext':
                let errorDataVal = finalSubmitErrorValidationsCheck(formData?.inputData, formData?.errorData, formData?.setErrorData, {
                    enteredBrands: brandsArrayRef?.current || []
                });
                console.log(3434433434, formData?.inputData)
                if (Object.values(errorDataVal)?.every(item => Object.keys(item)?.length === 0)) {
                    if (currentStep.index < totalSteps) {
                        const nextIndex = currentStep.index + 1;
                        const nextCode = flattenedMenuBars[nextIndex - 1]?.code;
                        setCurrentStep({ index: nextIndex, code: nextCode });
                        if (JSON.stringify(initialPayloadData) != JSON.stringify(desiredPayload)) {
                            // api to save the form data
                            dispatchHandler(dispatch, 'saveVendorOnBoardingDetailsRequest', desiredPayload, getPermissionKey("masterData", "onboardingrequest", "view"))
                        }
                    }
                    if (currentStep.index == totalSteps) {
                        if (JSON.stringify(initialPayloadData) != JSON.stringify(desiredPayload)) {
                            // api to save the form data
                            dispatchHandler(dispatch, 'saveVendorOnBoardingDetailsRequest', desiredPayload, getPermissionKey("masterData", "onboardingrequest", "view"))
                        }

                        // redirect to the summary page 
                        history.push("/vendoronboarding/summary")
                    }
                }
                break;
            case 'handleSkip':
                if (currentStep.index < totalSteps) {
                    const nextIndex = currentStep.index + 1;
                    const nextCode = flattenedMenuBars[nextIndex - 1]?.code;
                    setCurrentStep({ index: nextIndex, code: nextCode });
                }
                if (currentStep.index == totalSteps) {
                    // redirect to the summary page 
                    history.push("/vendoronboarding/summary")
                }
                break;
            default:
                break;
        }
    }


    console.log('payloadisisisis', desiredPayload?.[1], 'initial', initialPayloadData?.[1]);
    // function for settinng the payload
    const handleRequiredPayload = (payload = []) => {
        setTimeout(() => {
            setDesiredPayload(JSON.parse(JSON.stringify(payload)))
        }, 100);
    }
    // getting the initial payload for compaarision
    const getInitialPayloadData = (initialPayload = []) => {
        setInitialPayloadData(JSON.parse(JSON.stringify(initialPayload)))
    }

    // function to get the input and errorstates
    const getInputErrorStateData = (inputErrorData = {}) => {
        setFormData({ inputData: inputErrorData?.inputData, errorData: inputErrorData?.errorData, setErrorData: inputErrorData?.setErrorData })
    }

    return (
        <div className='vendor-onboarding'>
            <div className='vo-top'>
                <div className='vot-left'>
                    <Icons.SupplymintRIcon />
                </div>
                <div className='vot-right'>
                    <GenericCustomButton
                        className="skip-btn"
                        label='Skip, I will do it later'
                        onClick={() => handleChange('handleSkip')}
                    />
                    <GenericFormFooterComponent
                        buttons={[
                            currentStep.index !== 1 && currentStep.code !== 'IFABDET' && {
                                onClick: () => handleChange('handlePrevious'),
                                type: "primary",
                                label: "Back",
                                leftIcon: <Icons.RightBigArrow />
                            },
                            {
                                onClick: () => handleChange('handleNext'),
                                type: "primary",
                                label: "Save & Continue",
                                rightIcon: <Icons.RightBigArrow />,
                                disabled: () => Object.values(formData?.errorData)?.some(item => Object.keys(item)?.length > 0)
                            }
                        ]}
                    />
                </div>
            </div>

            <div className='vo-body'>
                {/*side menu bar */} 
                <MenuBar menuBarData={menuBarData} setCurrentStep={setCurrentStep} currentStep={currentStep} modulePercentageHeaderWise={percenatgeData?.getModulePercentageHeaderWise} />

                {/* render the forms according to the steps */}
                {currentStep.index === 1 && currentStep.code === 'IFABDET' && <IndustryFabricDetailsForm brandsArrayRef={brandsArrayRef} getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
                {currentStep.index === 2 && currentStep.code === 'FACDET' && <FactoryDetails getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
                {currentStep.index === 3 && currentStep.code === 'COMLC' && <CompanyLocation getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
                {currentStep.index === 4 && currentStep.code === 'DOCUPL' && <DocumentUpload getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
                {currentStep.index === 5 && currentStep.code === 'MEDUPL' && <MediaUpload getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
                {currentStep.index === 6 && currentStep.code === 'TRNDET' && <TransactionDetail getInputErrorState={getInputErrorStateData} getInitialPayload={getInitialPayloadData} getPayloadData={handleRequiredPayload} menuBarDetailsData={menuBarDetailsData} />}
            </div>

        </div>
    )
}

export default VendorOnboarding