import React, { useEffect, useState } from 'react'
import TableSkeleton from '../../../../dashboard/orderTracking/TableSkeleton';
import TableLoader from '../../../../../genericComponents/oldGenericComponents/tableHelperComponents/TableLoader';
import { dispatchHandler } from '../../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from '../../../../../helper/genericFunction';
import Icons from '../../../../../assets/icons';
import { ChevronRight, SquareMinus, SquarePlus } from 'lucide-react';

const ManageVendors = () => {

    const getTableComponentListData = useSelector(state => state?.generalStore?.getTableComponentListData);      

    // let data = [
    //     {
    //       "orgName": "MEGASHOP",
    //       "userdetails": [
    //         {
    //           "firstname": "Dharmendra",
    //           "mobilenumber": "+917011943422",
    //           "isadmin": 1,
    //           "email": "babila.in@gmail.com",
    //           "lastname": null,
    //           "username": "Babilaindustries",
    //           "uCode": "3568"
    //         }
    //       ]
    //     },
    //     {
    //       "orgName": "V-mart Retail Limited",
    //       "userdetails": [
    //         {
    //           "firstname": "Dharmendra",
    //           "mobilenumber": "+917011943422",
    //           "isadmin": 1,
    //           "email": "babila.in@gmail.com",
    //           "lastname": null,
    //           "username": "Babilaindustries",
    //           "uCode": "102488"
    //         }
    //       ]
    //     },
    //     {
    //       "orgName": "Metro Retail Private Limited",
    //       "userdetails": [
    //         {
    //           "firstname": "Dharmendra",
    //           "mobilenumber": "+917011943422",
    //           "isadmin": 1,
    //           "email": "babila.in@gmail.com",
    //           "lastname": null,
    //           "username": "Babilaindustries",
    //           "uCode": "13402"
    //         },
    //         {
    //           "firstname": "Dharmendra",
    //           "mobilenumber": "+917011943422",
    //           "isadmin": 1,
    //           "email": "babila.in@gmail.com",
    //           "lastname": null,
    //           "username": "Babilaindustries",
    //           "uCode": "15832"
    //         }
    //       ]
    //     },
    //     {
    //       "orgName": "V2Retail Limited",
    //       "userdetails": [
    //         {
    //           "firstname": "Dharmendra",
    //           "mobilenumber": "+917011943422",
    //           "isadmin": 1,
    //           "email": "babila.in@gmail.com",
    //           "lastname": null,
    //           "username": "Babilaindustries",
    //           "uCode": "200423"
    //         }
    //       ]
    //     }
    //   ]

    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState(null);
    const [error, setError] = useState(false);
    const [expandedData, setExpandedData] = useState({});

    const dispatch = useDispatch();

    let tokenData = parseJwt(sessionStorage.getItem('token'));

    useEffect(() => {fetchData()}, [])

    useEffect(() => {
        if(getTableComponentListData?.isSuccess){
            setLoading(false);
            setListData(getTableComponentListData?.data?.resource || []);
        }
        if(getTableComponentListData?.isError){
            setError(true);
            setLoading(false);
        }
    }, [getTableComponentListData])
    
    const fetchData = () => {
        setLoading(true);
        setError(false);
        dispatchHandler(dispatch, 'getTableComponentListRequest', {
            "apiType": "PROFILE_DETAILS",
            "apiUrl" : "/core/user/get/external/user/detail"
        })
    }

    const renderTable = () => {
    
        if(loading){
            return <div className='h-full'>
                <TableLoader/>
            </div>
        }

        if(error){
            return (
                <TableLoader isError={true} onRefresh={fetchData} /> 
            )
        }

        let headers = {
            "firstname" : "First Name",
            "lastname" : "Last Name",
            "username" : "Username",
            "mobilenumber" : "Mobile Number",
            "email" : "Email",
        }

        const renderTable = (item) => {
            // if(expandedData?.[item?.orgName]){
                return <div className={`ml-[1.9rem] overflow-hidden origin-top-left duration-200 ${expandedData?.[item?.orgName] ? 'translate-y-0 opacity-100 scale-y-100 visible pointer-events-auto h-auto' : '-translate-y-3 opacity-0 scale-y-50 invisible pointer-events-none h-[0]'}`}>
                {expandedData?.[item?.orgName] && <div className={`w-full max-h-[300px] overflow-auto mb-2 pt-[12px]`}>
                <table className='border border-[#DAE4EE] text-fontBlack'>
                    <thead className='bg-[#F8F8F8] whitespace-nowrap sticky top-0'>
                        <tr className='border-b border-[#DAE4EE]'>
                            {Object.keys(headers || {})?.map(key => (
                                <th className='px-3 py-1.5 text-[#6A6C70] text-[11px] font-medium border-r last:border-r-0 border-[#DAE4EE]'>{headers?.[key] || ""}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='text-xs font-normal'>
                        {
                            item?.userdetails?.map(user => (
                                <tr className='border-b last:border-b-0 border-[#DAE4EE]'>
                                    {Object.keys(headers || {})?.map(key => (
                                        <td className='px-3 py-1.5 border-r last:border-r-0 border-[#DAE4EE]'>{user?.[key] || ""}</td>
                                    ))}
                                </tr>                            
                            ))
                        }
                    </tbody>
                </table>
                </div>}
                </div>
            // }
        }

        return (
            <div className='px-5 py-3'>
                <p className='text-lg font-bold text-[#21314b]'>List of Oraganizations</p>
                <div className='space-y-4 mt-[1.5rem]'>
                    {
                        listData?.map(item => (
                            <div>
                                <div 
                                    onClick={() => {
                                        setExpandedData(prev => ({
                                            ...prev,
                                            [item?.orgName] : !prev?.[item?.orgName],
                                        }))
                                    }} 
                                    className='flex items-center gap-2.5 cursor-pointer w-fit hover:opacity-75'
                                >
                                    {/* <div>{expandedData?.[item?.orgName] ? <SquareMinus size={18}/> : <SquarePlus size={18}/>}</div> */}
                                    <div className={`duration-200 ${expandedData?.[item?.orgName] ? 'rotate-90' : 'rotate-0'}`}><ChevronRight size={18} /></div>
                                    <p className='mb-0 text-[13px] font-medium'>{item?.orgName}</p>
                                </div>
                                {renderTable(item)}
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    return (
        <div>
            {renderTable()}
        </div>
    )
}

export default ManageVendors