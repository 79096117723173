import React, { useState, useEffect } from 'react'
import Icons from '../../../../assets/icons';
import { parseJwt } from '../../../../helper/genericFunction';
import ProfileDetails from './ProfileDetails';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from '../../../../authComponents/login/forgot/ResetPassword';
import DataMappingView from './DataMappingView';
import Settings from './settings/Settings';
import LanguageTranslator from '../../../../locals/LanguageTranslator';
import { sendButtonClickEventToGoogleAnalytics } from '../../../../helper/helperTableFunctions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Waypoints } from 'lucide-react';
import ManageVendors from './manageUsers/ManageVendors';

const UserProfileHome = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState("User Info");
    const [currentMenuSearch, setCurrentMenuSearch] = useState("");

    useEffect(() => {
        if(location?.state?.currentTab) {
            setCurrentTab(location?.state?.currentTab);
        }
    }, []);

    const renderSideTabs = () => {
        let tokenData = parseJwt(sessionStorage.getItem("token"));
        // REnder the profile tabs data
        return [
            { label: "User Info", icon: <Icons.UserIcon /> },
            { label: "Change Password", icon: <Icons.PasswordLockIcon /> },
            // isSupportUser && {label : "Support Access", icon : <Icons.SupportIcon/>},
            parseJwt(sessionStorage.getItem('token'))?.uType === "ENT" && { label: "Data Mapping", icon: <Waypoints size={19} strokeWidth={1.5} className='text-[#67768E] group-hover:text-primary' /> },
            {label : "Settings", icon: <Icons.ProfileSettingIcon width={21} height={21}/>},
            parseJwt(sessionStorage.getItem('token'))?.uType === "VENDOR" && tokenData?.userAsAdmin &&  {label : "Manage Vendors", icon : <Icons.AdminIconMenuIcon height={18} width={18}/>},
        ].filter(tab => tab?.label?.toLowerCase()?.includes(currentMenuSearch?.toLowerCase() || ""))?.map(item => (<div className={`upl-det ${currentTab == item?.label && "upl-active"} ${currentTab == item?.label && item?.label === 'Data Mapping' && 'setIconActive'} group`} onClick={() => {
            setCurrentTab(item?.label);
            sendButtonClickEventToGoogleAnalytics({
                label: item?.label, location : location,
            })
        }}>
            <LanguageTranslator tag="span">{item?.icon}</LanguageTranslator>
            <LanguageTranslator tag="p">{item?.label}</LanguageTranslator>
        </div>))

    }

    // Funcction: TO render the profile component here
    const renderProfileComponents = () => {
        switch (currentTab) {
            case "User Info": {
                return <ProfileDetails />
            }
            case "Change Password": {
                return <ResetPassword
                    changePassword={(data) => handleChange("changePassword", data)}
                    showCurrentPass={true}
                />
            }
            case "Manage Vendors" : {
                return (
                    <ManageVendors/>
                )
            }
            case "Data Mapping": {
                return <DataMappingView />
            }
            case "Settings" : {
                return <Settings/>
            }
        }
    }

    const handleChange = (type, data) => {
        switch (type) {
            case "changePassword": {
                dispatchHandler(dispatch, "changePasswordRequest", {
                    token: sessionStorage?.getItem("token") || "",
                    password: btoa(data?.newPass),
                    oldpassword: btoa(data?.currentPass),
                    hash: "true"
                })
                break;
            }
        }
    }

    return (
        <div className='user-profile'>
            <div className='up-left'>
                <div className='upl-head'>
                    <LanguageTranslator tag="h3">User Profile</LanguageTranslator>   
                </div>
                {/* <div className='p-[12px] pt-[10px]'>
                    <GenericInput
                        inputKey="search"
                        value={currentMenuSearch}
                        placeholder="Search"
                        iconLeft={<span><Icons.GlobalSearchIcon className='ml-1 mr-1.5' /></span>}
                        onChange={(e) => setCurrentMenuSearch(e?.target?.value || "")}
                        dependentFieldsObject={["search"]}
                        type='text'
                    />
                </div> */}
                {renderSideTabs()}
            </div>
            <div className='up-right'>
                {renderProfileComponents()}
            </div>
        </div>
    )
}

export default UserProfileHome