import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { parseJwt } from "../helper/genericFunction";

const ParentModal = (props) => {
  const { childComponent } = props;
  const childElement = childComponent || <React.Fragment></React.Fragment>;

  useEffect(() => {
    // Set up the keyboard shortcuts
    let tokenData = parseJwt(sessionStorage?.getItem("token"));
    sessionStorage.setItem("X-REQUEST-ID", uuidv4() + (tokenData.jti || ""))

    document.addEventListener("keydown", handleKeywordPress);
    return () => {
      document.removeEventListener("keydown", handleKeywordPress);
      sessionStorage.removeItem("X-REQUEST-ID", uuidv4() + (tokenData.jti || ""))
    };
  }, []);

  // Function: to handle the keyboard shortcuts
  const handleKeywordPress = (e) => {
    if (e.key == "Escape") {
      closeModalWithAnimation();
    }
  };
  const childProps = props.getParentChildProps;
  const animationType = {
    center: ["from-center-in", "from-center-out"],
    top: ["from-top-in", "from-top-out"],
    bottom: ["from-bot-in", "from-bot-out"],
    left: ["from-left-in", "from-left-out"],
    right: ["from-right-in", "from-right-out"],
    moveUp: ["start-moveup", "close-moveup"],
    AlignBottom: ['from-bot', 'to-bot']
  };

  const closeModalWithAnimation = (e) => {

    if (!childProps?.isCloseBlocked) {
      document
        .getElementById("parentModalAnimation")
        ?.classList?.replace(
          animationType?.[props.getParentChildProps?.animationType]?.[0],
          animationType?.[props.getParentChildProps?.animationType]?.[1]
        );
      setTimeout(() => {
        childProps.closeModal();
      }, 100);
    }
  };

  return (
    <div id="parentModalMain" className={`fixed zi998 h-100 w-100 top-0 left-0 ${childProps?.zIndexClass} ${childProps?.parentModalOuterMostClassName}`}>
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${props.backdropClass}`}
        onClick={(e) => {
          closeModalWithAnimation(e);
        }}></div>
      <div
        id="parentModalAnimation"
        className={`m-0 w-8/12 h-4/6 bg-white text-left shadow-xl transition-all sm:my-8 fixed inset-0 ${childProps?.parentModalMainClass} ${
          animationType[props.getParentChildProps?.animationType]?.[0]
        }`}
        style={{ ...childProps?.dimensions, ...childProps?.postitionProps }}>
        {childElement &&
          React.createElement(childElement, {
            ...props,
            ...childProps,
            availableDocument:
              document.getElementById("parentModalMain") || null,
            closeModal: () => {
              if (!childProps?.staticModal) {
                closeModalWithAnimation();
              }
            },
          })}
      </div>
    </div>
  );
};

export default ParentModal;
