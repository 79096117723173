/*
 *  Allowed configuration via props:
 *  NAME                     TYPE                    DEFAULT
 *  multipleSelection        Boolean                 true
 *  isMandatory              Boolean                 false
 *  showLabel                Boolean                 true
 */

import React from 'react';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import Icons from '../../../assets/icons';
import GenericCustomButton from '../../GenericCustomButton';
import { X } from 'lucide-react';
import { getPermissionKey } from '../../../helper/permissions';

const SEARCH_BY_MAP = {
    "CONTAINS": "Contains",
    "STARTS_WITH": "Starts with",
    "EXACT_MATCH": "Exactly matches"
};

class GenericSearchModalNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Configuration via props
            config: {
                multipleSelection: this.props.multipleSelection !== undefined ? this.props.multipleSelection : true,
                isMandatory: this.props.isMandatory !== undefined ? this.props.isMandatory : false,
                showLabel: this.props.showLabel !== undefined ? this.props.showLabel : true,
                isDoneRequire: this.props.isDoneRequire !== undefined ? this.props.isDoneRequire : false,
                isClearRequire: this.props.isClearRequire !== undefined ? this.props.isClearRequire : false,
                clearSingleSelection: this.props.clearSingleSelection !== undefined ? this.props.clearSingleSelection : false,
                callApiOnClick: this.props.callApiOnClick !== undefined ? this.props.callApiOnClick : true,
                staticOtherData: this.props.staticOtherData !== undefined ? this.props.staticOtherData : false,
                getOtherDataNew: this.props.getOtherDataNew !== undefined ? this.props.getOtherDataNew : false,
                pasteEnabled: this.props.pasteConfiguration?.enabled ? true : false,
            },
            dropdown: false,

            requestPayload: {
                // entity:
                // Denotes the entity of the field being searched (site, item, vendor, etc.)
                entity: this.props.field.entity,

                // key:
                // Denotes the primary detail of the field being searched.
                // In the API response object "searchResultNew",
                // the value corresponding to this key will be stored in the selectedItems
                key: this.props.field.key,

                // code:
                // Denotes the secondary detail of the field being searched.
                // In the API response object "searchResultNew",
                // the value corresponding to this key will only be displayed in the dropdown
                code: this.props.field.code || "",
                search: this.props.field.search || "",
                searchBy: "CONTAINS",
                pageNo: 1,
                other_data: {}
            },
            data: [],
            currentPage: 1,
            maxPage: 1,
            loading: false,
            searchByDropdown: false,
            searchByEnter: false,

            selectedItems: this.props.selectedItems[this.props.field.key] || [],
            isNameAndCodeRequire: this.props.isNameAndCodeRequire || false,
            searchMode: {
                type: "normal",
                method: "",
            },
            pasteOptionsDropdown: false,
            showSelectedData: false,
            filterSelectedData: this.props?.selectedItems?.[this.props.field?.key] || []
            


        }
        this.pastedRef = React.createRef();
        this.pastedRef.current = { data: {} }; // Initialize custom object.
        this.pasteOptions = {
            "comma": ',',
            "space": ' ',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.generalStore?.getSearchFiltersData?.isLoading) {
            // nextProps.getSearchFiltersClear();
            return {
                loading: true
            };
        }
        else if (nextProps.generalStore?.getSearchFiltersData?.isSuccess) {
            // nextProps.getSearchFiltersClear();
            return {
                loading: false,
                data: [...nextProps.generalStore.getSearchFiltersData?.data?.resource?.searchResultNew] || [],
                currentPage: nextProps.generalStore.getSearchFiltersData?.data?.resource?.currPage || 1,
                maxPage: nextProps.generalStore.getSearchFiltersData?.data?.resource?.maxPage || 1
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.selectedItems[this.props.field.key] || []) !== JSON.stringify(prevState.selectedItems)) {
            this.setState({
                selectedItems: this.props.selectedItems[this.props.field.key] || [],
                filterSelectedData: this.props?.selectedItems?.[this.props?.field?.key] || []
            });
        }
    }

    page = (dir) => {
        if (dir == "prev" && this.state.currentPage !== 1) {
            this.props.handleApiChange("getSearchFiltersRequest", {
                ...this.state.requestPayload,
                pageNo: this.state.currentPage - 1
            }, getPermissionKey("common", "commonFunctionality", "view"))
        }
        else if (dir == "next" && this.state.currentPage !== this.state.maxPage) {
            this.props.handleApiChange("getSearchFiltersRequest", {
                ...this.state.requestPayload,
                pageNo: this.state.currentPage + 1
            }, getPermissionKey("common", "commonFunctionality", "view"))
        }
    }

    handleChange = (item) => {
        if (['SM_TEXT_EMPTY_VALUE', 'SM_TEXT_NOT_EMPTY_VALUE'].includes(item)) {
            this.setState(prevState => {
                let selectedItems = [...prevState.selectedItems]
                if (selectedItems.includes(item)) { selectedItems = [] }
                else {
                    selectedItems = [item];
                }
                return {
                    selectedItems,
                    filterSelectedData: selectedItems,
                    showSelectedData: selectedItems?.length == 0 ? false : prevState.showSelectedData
                }
            }, () => {
                this.props.selectItems(this.props.field, this.state.selectedItems);
            })
        }
        else {
            this.setState(prevState => {
                if (item === "clearSingleSelection") {
                    return {
                        selectedItems: [],
                        filterSelectedData: [],
                        requestPayload: {
                            ...prevState.requestPayload,
                            search: '',
                        }
                    }
                }
                else if (!prevState.config.multipleSelection) {
                    return {
                        selectedItems: prevState.selectedItems[0] === item ? [] : [item],
                        filterSelectedData: prevState.selectedItems[0] === item ? [] : [item],
                        requestPayload: {
                            ...prevState.requestPayload,
                            search: prevState.selectedItems[0] === item ? prevState.requestPayload.search : item
                        }
                    };
                }
                else {
                    let selectedItems = [...prevState.selectedItems], index = selectedItems.indexOf(item);
                    if (item === "deselectAll") {
                        selectedItems = [];
                    }
                    else if (item === "deselectAllWithClear") {
                        return {
                            selectedItems: [],
                            filterSelectedData: [],
                            requestPayload: {
                                ...prevState.requestPayload,
                                search: '',
                            }
                        }
                    }
                    else {
                        if (index === -1) {
                            selectedItems.push(item);
                        }
                        else {
                            selectedItems.splice(index, 1);
                        }
                    }
                    const filteredData = prevState.showSelectedData ? selectedItems.filter((selectedItem) =>
                        selectedItem.toLowerCase().includes(prevState.requestPayload.search.toLowerCase())
                    ) : selectedItems;
                    return {
                        selectedItems,
                        filterSelectedData: filteredData,
                        showSelectedData: selectedItems?.length == 0 ? false : prevState.showSelectedData
                    };
                }
            }, () => {
                this.props.selectItems(this.props.field, this.state.selectedItems);
            });
        }
    }

    handleChangeNew = (item, code) => {
        this.setState(prevState => {
            if (!prevState.config.multipleSelection) {
                return {
                    selectedItems: prevState.selectedItems[0] === item ? [] : [item],
                    filterSelectedData: prevState.selectedItems[0] === item ? [] : [item],
                    requestPayload: {
                        ...prevState.requestPayload,
                        search: prevState.selectedItems[0] === item ? prevState.requestPayload.search : item
                    }
                };
            }
            else {
                let selectedItems = [...prevState.selectedItems], index = selectedItems.indexOf(item);
                if (item === "deselectAll") {
                    selectedItems = [];
                }
                else if (item === "deselectAllWithClear") {
                    return {
                        selectedItems: [],
                        filterSelectedData: [],
                        requestPayload: {
                            ...prevState.requestPayload,
                            search: '',
                        }
                    }
                }
                else {
                    if (index === -1) {
                        selectedItems.push(item);
                    }
                    else {
                        selectedItems.splice(index, 1);
                    }
                }
                const filteredData = prevState.showSelectedData ? selectedItems.filter((selectedItem) =>
                    selectedItem.toLowerCase().includes(prevState.requestPayload.search.toLowerCase())
                ) : selectedItems;
                return {
                    selectedItems,
                    filterSelectedData: filteredData,
                    showSelectedData: selectedItems?.length == 0 ? false : prevState.showSelectedData
                };
            }
        }, () => {
            this.props.selectItems(this.props.field, this.state.selectedItems, code);
        });
    }


    getOtherData = () => {
        console.log('fjef3ir2ti3rh2', this.props.selectedItems, this.props.generalStore?.getDropdownData)
        try {
            let other_data = {};
            const DROPDOWN_TYPE = this.props.field.entity === "item" || this.props.field.entity === "product_catalogue_vw" || this.props.field.entity === "logged_actions" || this.props.field.entity === "item_master" || this.props.field.entity === "generic_item_master_report" ? "DYNAMIC_ITEM_CONFIG" : this.props.field.entity === "site" ? "DYNAMIC_SITE_CONFIG" : this.props.field.entity === "asn_dashboard_details" ? "DYNAMIC_SITE_CONFIG" : this.props.field.entity === "box_performance_details" ? "DYNAMIC_SITE_CONFIG" : null;
            if (DROPDOWN_TYPE) {
                if (this.state?.config?.getOtherDataNew) {
                    if (this.props.generalStore?.getDropdownData?.data?.type !== DROPDOWN_TYPE) {
                        this.props.handleApiChange("getDropdownRequest", { type: DROPDOWN_TYPE })
                    }
                    else {
                        Object.keys(this.props.selectedItems).forEach(key => {
                            if (key !== this.props.field.key && this.props.generalStore?.getDropdownData?.data?.resource?.includes(key)) {
                                other_data[key] = this.props.selectedItems[key];
                            }
                        });
                    }
                }
                else {
                    if (!this.props.generalStore?.getDropdownData?.data?.[DROPDOWN_TYPE]) {
                        this.props.handleApiChange("getDropdownRequest", { type: DROPDOWN_TYPE })
                    }
                    else {
                        Object.keys(this.props.selectedItems).forEach(key => {
                            if (key !== this.props.field.key && this.props.generalStore.getDropdown.data[DROPDOWN_TYPE].resource.includes(key)) {
                                other_data[key] = this.props.selectedItems[key];
                            }
                        });
                    }
                }

            }
            return other_data;
        }
        catch (e) {
            return {};
        }
    }

    openDropdown = () => {
        this.setState(prevState => {
            if (!prevState.dropdown) {
                const other_data = this.state.config.staticOtherData ? this.props.field.other_data : this.getOtherData();
                if (this.state.config.callApiOnClick) {
                    this.props.handleApiChange("getSearchFiltersRequest", { ...prevState.requestPayload, other_data }, getPermissionKey("common", "commonFunctionality", "view"))
                }
                return {
                    requestPayload: {
                        ...prevState.requestPayload,
                        other_data
                    },
                    dropdown: true,
                    data: []
                };
            }
            return null;
        });
        document.addEventListener("click", this.closeDropdown);
    }

    closeDropdown = (e) => {
        let path = e.path || (e.composedPath && e.composedPath()), key = e.target.dataset.key || "";
        console.log('closing...', key !== this.props.field.key && !path.some(elem => elem.id === "genericSearchModalDropdown"));
        if (key !== this.props.field.key && !path.some(elem => elem.id === "genericSearchModalDropdown")) {
            this.setState({
                dropdown: false,
                searchMode : {type :"normal"},
                showSelectedData: false
            });
            document.removeEventListener("click", this.closeDropdown);
        }
    }

    isSearchValueIsInBulk = (val) => {
        let commaSplittedArray = val?.trim(',')?.split(',');
        let spaceSplittedArray = val?.trim(' ')?.split(' ');
        if (commaSplittedArray?.length >= 2) return "comma";
        if (spaceSplittedArray?.length >= 2) return "space";
        return false;
    }

    trimCharacter(str, char) {
        const escapedChar = char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
        const regex = new RegExp(`^${escapedChar}+|${escapedChar}+$`, 'g');
        return str.replace(regex, '');
    }

    setSearchValue = (search) => {
        let isSearchValueIsInBulk = this.isSearchValueIsInBulk(search);
        if (this.pastedRef?.current?.data?.check && isSearchValueIsInBulk) {
            this.setState({
                searchMode: {
                    "type": "pasteMode",
                    "method": isSearchValueIsInBulk,
                    data: search,
                    dropdownData: Object.fromEntries(
                        this.trimCharacter(search, this.pasteOptions?.[isSearchValueIsInBulk])?.split(this.pasteOptions?.[isSearchValueIsInBulk])?.map(item => [item.trim(), true])?.filter(item => item?.[0])
                    )
                },
            })
            this.setState(prevState => ({
                requestPayload: {
                    ...prevState.requestPayload,
                    search
                }
            }));

        } else {
            this.setState(prevState => ({
                requestPayload: {
                    ...prevState.requestPayload,
                    search
                }
            }), () => {
                if (this.props.getPayload) {
                    this.props.getPayload(this.state.requestPayload);
                }
                if(this.pastedRef.current.data)  this.pastedRef.current.data.check = false;
                if (search == "" && this.state.searchMode?.type == "pasteMode") {
                    this.setState({
                        searchMode: {
                            "type": "normal",
                        },
                    })
                }
            });
        }
    }

    openSearchByDropdown = (e) => {
        e.preventDefault();
        this.setState({
            searchByDropdown: !this.state.searchByDropdown
        })
        // , () => document.addEventListener("click", this.closeSearchByDropdown));
    }

    closeSearchByDropdown = () => {
        this.setState({
            searchByDropdown: false
        })
        // , () => document.removeEventListener("click", this.closeSearchByDropdown));
    }

    setSearchBy = (searchBy) => {
        this.setState(prevState => ({
            requestPayload: {
                ...prevState.requestPayload,
                searchBy
            },
            searchByDropdown: false
        }))
        // , () => document.removeEventListener("click", this.closeSearchByDropdown));
    }

    done = () => {
        if (this.state.selectedItems.length == 0 && this.state.config.isClearRequire) {
            this.clearFilter();
        }
        else {
            this.props.done();
            this.setState({
                dropdown: false,
                showSelectedData: false
            })
        }
    }

    clearFilter = () => {
        this.props.clearFilter();
        this.setState({
            requestPayload: {
                ...this.state.requestPayload,
                search: ""
            }
        });
        setTimeout(() => {
            this.props.done();
        }, 100);
    }

    handleOnKeyEnter = () => {
        if(this.state.config.multipleSelection && this.state.showSelectedData) {
            const filteredData = this.state?.selectedItems.filter((selectedItem) =>
                selectedItem.toLowerCase().includes(this.state?.requestPayload?.search.toLowerCase())
            );
            this.setState({
                filterSelectedData: filteredData
            })
            return;
        }
        if (this.state.searchMode?.type === "pasteMode") {
            let array = [...(this.state.selectedItems || []), ...Object.keys(this.state.searchMode?.dropdownData || {})?.filter(key => this.state.searchMode?.dropdownData?.[key])]
            this.props.selectItems(this.props.field, [...new Set(array)]);
            setTimeout(() => {
                this.props.done();
            }, 100);
        } else {
            if (this.state.config.callApiOnClick) {
                this.props.handleApiChange("getSearchFiltersRequest", this.state.requestPayload, getPermissionKey("common", "commonFunctionality", "view"))
            }
            else {
                if (this.state.requestPayload.search !== "") {
                    this.props.handleApiChange("getSearchFiltersRequest", this.state.requestPayload, getPermissionKey("common", "commonFunctionality", "view"))
                    this.setState({
                        searchByEnter: true
                    })
                }
            }
        }
    }
    // Handle the pasted value change
    handlePasteValue = (val) => {
        if (this.state.config?.pasteEnabled)
            this.pastedRef.current.data.check = true;
    }

    openPasteOptionsDropdown = () => {
        this.setState({
            pasteOptionsDropdown: !this.state.pasteOptionsDropdown
        });
    }

    renderPastedSearchDropdown = () => {
        console.log("Search mode", this.state.searchMode?.["dropdownData"])
        let placeholders = {
            "comma": "Comma",
            "space": "Space"
        }
        // Function : To render the pasted search dropdown.
        return <div className='!h-full'>
            {/* Render the selection dropdown */}
            <div className="gwa-advance-filter !h-full !flex flex-col">
                <button type="button" className={`!mt-[6px] ${this.state.pasteOptionsDropdown ? "gwaaf-btn open" : "gwaaf-btn"}`} onClick={() => { this.openPasteOptionsDropdown() }}>
                    <LanguageTranslator tag="span">Seperated By : </LanguageTranslator>
                    <LanguageTranslator>{placeholders?.[this.state.searchMode?.method] || ""}</LanguageTranslator>
                    <svg className='gwaafb-svg' width="24" height="32" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4477 10.0752L8.62127 14.3267C8.44752 14.5198 8.58453 14.8274 8.84426 14.8274H16.497C16.7568 14.8274 16.8938 14.5198 16.72 14.3267L12.8936 10.0752C12.7745 9.94274 12.5668 9.94274 12.4477 10.0752Z" fill="#0F172A" />
                        <path d="M12.4477 24.5796L8.62127 20.3281C8.44752 20.135 8.58453 19.8274 8.84426 19.8274H16.497C16.7568 19.8274 16.8938 20.135 16.72 20.3281L12.8936 24.5796C12.7745 24.712 12.5668 24.712 12.4477 24.5796Z" fill="#0F172A" />
                    </svg>
                </button>
                {this.state.pasteOptionsDropdown &&
                    <ul className="gwa-dropdown">
                        {Object.keys(this.pasteOptions || {}).map(key => <li onClick={() => {
                            this.setState(prev => ({
                                searchMode: {
                                    ...(prev.searchMode || {}),
                                    method: key,
                                    dropdownData: Object.fromEntries(
                                        this.trimCharacter(this.state.searchMode?.["data"], this.pasteOptions?.[key])?.split(this.pasteOptions?.[key])?.map(item => [item.trim(), true])?.filter(item => item?.[0])
                                    )
                                },
                                pasteOptionsDropdown: !this.state.pasteOptionsDropdown
                            }))
                        }}>{placeholders[key]}</li>)}
                    </ul>}

                {/* Render the input values here */}
                {Object.keys(this.state.searchMode?.["dropdownData"] || {})?.length > 0 && <ul className='mt-[8px] flex-1 overflow-auto space-y-2.5 pt-[4px] pb-2'>
                    {Object.keys(this.state.searchMode?.["dropdownData"] || {})?.map(item => {
                        return <li onClick={() => {
                            // Remove this current data from the dropdown data;
                            let updatedDropdownData = {...this.state.searchMode?.["dropdownData"]}
                            updatedDropdownData[item] = !updatedDropdownData?.[item];
                            this.setState(prev => ({
                                searchMode: {
                                    ...(prev.searchMode || {}),
                                    dropdownData: updatedDropdownData,
                                }
                            }))
                        }} className='flex items-center gap-2'>
                            {this.state.searchMode?.["dropdownData"]?.[item] ? <Icons.SelectedItemIcon /> : <Icons.DeselectedItemIcon/>}
                            <p className='!mb-0 !text-xs !font-normal !text-fontBlack truncate max-w-[233px]'>{item}</p>
                        </li>
                    })}
                </ul>}
                <hr />
                {/* Render the final submt button here */}
                <div className='w-full pt-[12px] pb-[8px]'>
                    <GenericCustomButton
                        label="Search"
                        type="primary"
                        disabled={() => !this.state.searchMode?.dropdownData || Object.keys(this.state.searchMode?.dropdownData || {})?.some(key => this.state.searchMode?.dropdownData?.[key]) === 0}
                        onClick={() => { this.applyCustomSearch() }}
                        className={'w-full !rounded-none'}
                    />
                </div>
            </div>
        </div>
    }

    // Function : To apply the custom search as filter
    applyCustomSearch = () => {
        let array = [...(this.state.selectedItems || []), ...Object.keys(this.state.searchMode?.dropdownData || {})?.filter(key => this.state.searchMode?.dropdownData?.[key])]
        this.props.selectItems(this.props.field, [...new Set(array)]);
        setTimeout(() => {
            this.done();
        }, 100)
    }

    handleChangeFun = (type, data) => {
        switch (type) {
            case "showSelected": {
                this.setState({
                    showSelectedData: data
                }, () => {
                    this.setState({
                        filterSelectedData: this.state.selectedItems
                    })
                });
            }
                break;
        
            default:
                break;
        }
    }

    render() {
        const ITEM = {...this.props.field}, { multipleSelection, isMandatory, showLabel, isDoneRequire, isClearRequire, clearSingleSelection, callApiOnClick } = this.state.config;
        const mapSelectedData = this.state.showSelectedData ? this.state.filterSelectedData : this.state.filterSelectedData?.slice(0, 1);
        return (
            <React.Fragment>
                {showLabel &&
                    <label className="pnl-purchase-label">
                        {ITEM.value}
                        {isMandatory && <span className='mandatory'>*</span>}
                    </label>}
                <div className={`generic-smart-input gsi-v2 ${this.state.dropdown && "focusBorder"}`}>
                    {(multipleSelection && this.state.selectedItems.length !== 0) &&
                        <span className="gsi-selected">
                            {`${this.state.dropdown ? "" : `selected`} +${this.state.selectedItems.length}`}
                        </span>}
                    {(clearSingleSelection && this.state.selectedItems.length !== 0) &&
                        <span className="gsi-selected">
                            {`${this.state.dropdown ? "" : `selected`} +${this.state.selectedItems.length}`}
                        </span>}
                    <input data-key={ITEM.key} placeholder={ITEM.value} value={this.state.requestPayload.search} onPaste={(e) => { this.handlePasteValue({ e, key: ITEM.key }) }} onChange={(e) => this.setSearchValue(e.target.value)} onFocus={this.openDropdown} onKeyDown={e => e.key === "Enter" ? this.handleOnKeyEnter() : null} />
                    {(multipleSelection && this.state.selectedItems.length !== 0) ?
                        <span className="gsis-clear" onClick={isClearRequire ? () => this.clearFilter() : () => this.handleChange("deselectAllWithClear")}>
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.728129 0.228129C1.0323 -0.076043 1.5253 -0.076043 1.82931 0.228129L5.5 3.89882L9.17069 0.228129C9.47486 -0.076043 9.96786 -0.076043 10.2719 0.228129C10.576 0.532301 10.576 1.0253 10.2719 1.32931L6.60118 5L10.2719 8.67069C10.576 8.97486 10.576 9.46786 10.2719 9.77187C9.9677 10.076 9.4747 10.0759 9.17069 9.77187L5.5 6.10118L1.82931 9.77187C1.52514 10.0759 1.03214 10.0759 0.728129 9.77187C0.423957 9.4677 0.423957 8.9747 0.728129 8.67069L4.39882 5L0.728129 1.32931C0.423957 1.02514 0.423957 0.532139 0.728129 0.228129Z" fill="#B6BACA" />
                            </svg>
                        </span> :
                        <span className="gsi-search">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.52673 2.37419C4.23287 2.37419 2.37333 4.23373 2.37333 6.52759C2.37333 8.82145 4.23287 10.681 6.52673 10.681C8.82059 10.681 10.6801 8.82145 10.6801 6.52759C10.6801 4.23373 8.82059 2.37419 6.52673 2.37419ZM1.18665 6.52759C1.18665 3.57834 3.57748 1.1875 6.52673 1.1875C9.47598 1.1875 11.8668 3.57834 11.8668 6.52759C11.8668 9.47684 9.47598 11.8677 6.52673 11.8677C3.57748 11.8677 1.18665 9.47684 1.18665 6.52759Z" fill="#67768E" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.45955 9.45894C9.69127 9.22723 10.067 9.22723 10.2987 9.45894L12.8797 12.04C13.1114 12.2717 13.1114 12.6474 12.8797 12.8791C12.648 13.1108 12.2723 13.1108 12.0406 12.8791L9.45955 10.2981C9.22784 10.0663 9.22784 9.69066 9.45955 9.45894Z" fill="#67768E" />
                            </svg>
                        </span>}
                </div>
                {this.state.searchMode?.type == "pasteMode" ? <div id="genericSearchModalDropdown" className="dropdown-menu-city1 gen-width-auto zi3">
                    {this.renderPastedSearchDropdown()}
                </div> :
                    this.state.dropdown &&
                    <div id="genericSearchModalDropdown" className="dropdown-menu-city1 gen-width-auto zi3">
                        <ul className="dropdown-menu-city-item">
                            <div className="gwa-advance-filter">
                                <button type="button" className={this.state.searchByDropdown ? "gwaaf-btn open" : "gwaaf-btn"} onClick={this.openSearchByDropdown}>
                                    <LanguageTranslator tag="span">Search By: </LanguageTranslator>
                                    <LanguageTranslator>{SEARCH_BY_MAP[this.state.requestPayload.searchBy]}</LanguageTranslator>
                                    <svg className='gwaafb-svg' width="24" height="32" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4477 10.0752L8.62127 14.3267C8.44752 14.5198 8.58453 14.8274 8.84426 14.8274H16.497C16.7568 14.8274 16.8938 14.5198 16.72 14.3267L12.8936 10.0752C12.7745 9.94274 12.5668 9.94274 12.4477 10.0752Z" fill="#0F172A" />
                                        <path d="M12.4477 24.5796L8.62127 20.3281C8.44752 20.135 8.58453 19.8274 8.84426 19.8274H16.497C16.7568 19.8274 16.8938 20.135 16.72 20.3281L12.8936 24.5796C12.7745 24.712 12.5668 24.712 12.4477 24.5796Z" fill="#0F172A" />
                                    </svg>
                                </button>
                                {this.state.searchByDropdown &&
                                    <ul className="gwa-dropdown">
                                        {Object.keys(SEARCH_BY_MAP).map(key => <li onClick={() => this.setSearchBy(key)}>{SEARCH_BY_MAP[key]}</li>)}
                                    </ul>}
                            </div>
                            {(multipleSelection && this.state.selectedItems.length !== 0) &&
                            <div className='!flex items-center justify-between'>
                                <button type="button" className="deselect-all" onClick={() => this.handleChange("deselectAll")}>
                                    <span className="dab-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 19">
                                            <g transform="translate(0 -9)">
                                                <g fill="#fff" stroke="#21314b" strokeWidth="1.5px" transform="translate(0 9)">
                                                    <rect width="19" height="19" stroke="none" rx="4" />
                                                    <rect width="17.5" height="17.5" x=".75" y=".75" fill="none" rx="3.25" />
                                                </g>
                                                <path fill="#fff" d="M3.244 6.827a.454.454 0 0 1-.322-.133L-.16 3.576a.786.786 0 0 1 0-.937.77.77 0 0 1 .937-.079l2.467 2.467L8.5-.228a.91.91 0 0 1 .945.069.893.893 0 0 1 0 .939L3.566 6.694a.454.454 0 0 1-.322.133zm0 0" transform="translate(4.916 15.262)" />
                                                <rect width="9" height="1.5" fill="#21314b" rx=".75" transform="translate(5 18)" />
                                            </g>
                                        </svg>
                                    </span>
                                    Deselect All
                                </button>
                                <GenericCustomButton className="deselect-all !justify-end" label={`Show ${this.state.showSelectedData ? 'All' : 'Selected'}`} onClick={() => this.handleChangeFun("showSelected", !this.state.showSelectedData)} />
                            </div>}
                            {this.props.emptyAttributes && <div className='dmci-checked'>
                                <React.Fragment>
                                    <li>
                                        <label className="checkBoxLabel0">
                                            <input type="checkBox" checked={this.state.selectedItems.includes('SM_TEXT_EMPTY_VALUE')} onChange={() => this.handleChange('SM_TEXT_EMPTY_VALUE')} />
                                            <span className="checkmark1"></span>
                                            <LanguageTranslator>Is Empty</LanguageTranslator>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkBoxLabel0">
                                            <input type="checkBox" checked={this.state.selectedItems.includes('SM_TEXT_NOT_EMPTY_VALUE')} onChange={() => this.handleChange('SM_TEXT_NOT_EMPTY_VALUE')} />
                                            <span className="checkmark1"></span>
                                            <LanguageTranslator>Is Not Empty</LanguageTranslator>
                                        </label>
                                    </li>
                                </React.Fragment>
                            </div>}
                            {this.state.filterSelectedData.length === 0 ? null :
                                <div className="dmci-checked">
                                    {mapSelectedData?.map((item) => {
                                        return !['SM_TEXT_EMPTY_VALUE', 'SM_TEXT_NOT_EMPTY_VALUE'].includes(item) &&
                                            (
                                                <li>
                                                    <label className="checkBoxLabel0">
                                                        <input type="checkBox" checked onChange={() => this.handleChange(item)} />
                                                        <span className="checkmark1"></span>
                                                        {item}
                                                    </label>
                                                </li>
                                            )
                                    }
                                    )}
                                    {!this.state.showSelectedData && this.state.filterSelectedData.length > 1 && 
                                    <li onClick={() => this.handleChangeFun("showSelected", !this.state.showSelectedData)}> 
                                        +{this.state.filterSelectedData?.length - 1} View More
                                    </li>}
                                </div>}
                            {this.state.loading ?
                                <li>
                                    <span className="vendor-details displayBlock">
                                        <LanguageTranslator tag="span" className="vd-name div-col-1 text-center">Fetching data...</LanguageTranslator>
                                    </span>
                                </li> :
                                this.state.data.length == 0 ?
                                    <li>
                                        <span className="vendor-details displayBlock">
                                            {this.state.searchByEnter ? <span className="vd-name div-col-1 text-center">No data found!</span> :
                                                !callApiOnClick ? null : <span className="vd-name div-col-1 text-center">No data found!</span>}
                                        </span>
                                    </li> : !this.state.showSelectedData &&
                                    this.state.data.map(item =>
                                        this.state.selectedItems.indexOf(item[ITEM.key]) !== -1 ?
                                            null :
                                            <li className={`${this.state.selectedItems.includes('SM_TEXT_EMPTY_VALUE') || this.state.selectedItems.includes('SM_TEXT_NOT_EMPTY_VALUE') ? 'select-btn-back' : ''}`}>{this.state.isNameAndCodeRequire === false ?
                                                <label className="checkBoxLabel0">
                                                    <input type="checkBox" disabled={this.state.selectedItems.includes('SM_TEXT_EMPTY_VALUE') || this.state.selectedItems.includes('SM_TEXT_NOT_EMPTY_VALUE')} checked={false} onChange={() => this.handleChange(item[ITEM.key])} />
                                                    <span className="checkmark1"></span>
                                                    {item[ITEM.key]}
                                                    {ITEM.code && ` - ${item[ITEM.code]}`}
                                                </label> :
                                                <label className="checkBoxLabel0">
                                                    <input type="checkBox" disabled={this.state.selectedItems.includes('SM_TEXT_EMPTY_VALUE') || this.state.selectedItems.includes('SM_TEXT_NOT_EMPTY_VALUE')} checked={false} onChange={() => this.handleChangeNew(item[ITEM.key], item[ITEM.code])} />
                                                    <span className="checkmark1"></span>
                                                    {item[ITEM.key]}
                                                    {ITEM.code && ` - ${item[ITEM.code]}`}
                                                </label>
                                            }
                                            </li>
                                    )}
                        </ul>
                        <div className={`gen-dropdown-pagination ${!this.state.loading && isDoneRequire && "!justify-between"}`}>
                            {this.state.loading ?
                                <span className="pagination-loader"></span> :
                                <React.Fragment>
                                    <div className="page-close">
                                        {isDoneRequire && <button type="button" className='gdp-done' onClick={this.done}>Done</button>}
                                    </div>
                                    <div className="page-next-prew-btn">
                                        <button className={`pnpb-prev ${this.state.currentPage === 1 && "!cursor-not-allowed"}`} type="button" onClick={() => this.page("prev")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                                <path id="chevron_1_" fill="#21314b" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)" />
                                            </svg>
                                        </button>
                                        <button className="pnpb-no" type="button" disabled>{this.state.currentPage}/{this.state.maxPage}</button>
                                        <button className={`pnpb-next ${this.state.maxPage === 1 && "!cursor-not-allowed"}`} type="button" onClick={() => this.page("next")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                                <path id="chevron_1_" fill="#21314b" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)" />
                                            </svg>
                                        </button>
                                    </div>
                                </React.Fragment>}
                        </div>
                    </div>}
            </React.Fragment>
        )
    }
}

export default GenericSearchModalNew;