import React from 'react'
import Icons from '../../assets/icons'
import GenericCustomButtton from '../../genericComponents/GenericCustomButton'
import { Trash2, X } from 'lucide-react';
import LanguageTranslator from '../../locals/LanguageTranslator';

const GenericDeleteModal = (props = {}) => {
    let {
        closeModal = () => {},
        finalSubmit = () => {},
        label = "Delete Item",
        subLabel = "This cannot be undone.",
        icon,
        topExtra,
        secondaryBtnLabel,
        primaryBtnLabel
    } = props || {};
    return (
        <>
        {topExtra && topExtra()}
        <div className='px-6 py-4 text-fontBlack space-y-1 flex flex-col'>
            <div className='flex items-start justify-between gap-2'>
                {icon && <div className='mb-2'>
                    {typeof(icon) == "function" ? icon() : icon}
                </div>}
                {/* <GenericCustomButtton
                    // type = "default"
                    leftIcon={<X size={17}/>}
                    onClick={() => closeModal()}
                    className={''}
                /> */}
            </div>
            <div className='flex-1 min-h-[130px]'>
                <LanguageTranslator tag='p' className='font-semibold text-lg'>{typeof label === 'function' ? label() : label}</LanguageTranslator>
                <LanguageTranslator tag='p' className='text-xs w-2/3'>{typeof subLabel === 'function' ? subLabel() : subLabel}</LanguageTranslator>
            </div>
            {/* Render the final submit button for the delete confirmation modal here */}
            <div className='flex items-center justify-end gap-3'>
                <GenericCustomButtton
                    label = {secondaryBtnLabel ?? "Cancel"}
                    onClick = {closeModal}
                    // type = "default"
                    className={'w-full max-w-[40%] !rounded-none border border-[#E5E7EB] !h-[32px] text-[13px] text-fontBlack font-medium hover:opacity-75'}

                />
                <GenericCustomButtton
                    loading = {props.deleteLoading}
                    label = {primaryBtnLabel ?? 'Delete'}
                    onClick = {() => {
                        finalSubmit();
                    }}
                    // type = "primary"
                    className={'w-full max-w-[60%] !rounded-none !h-[32px] text-[13px] text-white font-medium !bg-[#FD3757] hover:opacity-75'}
                    disabled={props.deleteLoading}
                />
            </div>
        </div>
        </>
    )
}

export default GenericDeleteModal