import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRenderClassComponent from '../../hooks/useRenderClassComponent';
import TableComponent from '../oldGenericComponents/TableComponent';
import { useParams } from 'react-router-dom';
import { dispatchHandler, handleApiChange } from '../../helper/apiHelperFunctions';
import { v4 as uuidv4 } from 'uuid';
import TableLoader from '../oldGenericComponents/tableHelperComponents/TableLoader';
import TableSkeletonLoader from '../oldGenericComponents/tableHelperComponents/TableSkeletonLoader'
import NoPageAvailable from './NoPageAvailable';
import NoPageFound from '../../components/helperComponents/NoPageFound';
import TableFullPageLoader from './TableFullPageLoader';

export default function GenericTableComponent(props) {

    const { apiName = "getTableComponentList", storeName = "generalStore", expandFirstProps = {}, summaryApiName = "getTableComponentSummary" } = props;


    const params = useParams()
    const [currentTab, setCurrentTab] = useState(params?.[props?.actionBarConfig?.tabsKey || "status"] ? JSON.parse(decodeURIComponent(params.status)) : "Pending")
    const dispatch = useDispatch();
    const generalStore = useSelector(state => state.generalStore);
    const states = {
        administrationStore: useSelector(state => state.administrationStore),
        masterDbStore: useSelector(state => state.masterDbStore),
        generalStore: useSelector(state => state.generalStore),
    }

    const [headersData, setHeadersData] = useState({});
    const [headersType, setHeadersType] = useState({});

    const [itemExpandedRow, setItemExpandedRow] = useState({})
    const [mainExpandedRow, setMainExpandedRow] = useState({})
    const [itemExpandedData, setItemExpandedData] = useState({})
    const [mainExpandedData, setMainExpandedData] = useState({})
    const [mainExpandLoading, setMainExpandLoading] = useState({});
    const [itemExpandLoading, setItemExpandLoading] = useState({});
    
    const [tabsLoading, setTabsLoading] = useState(true);

    const [filteredTabs, setFilteredTabs] = useState(props.actionBarConfig?.tabs?.key ? JSON.parse(JSON.stringify(props.actionBarConfig?.tabs?.data || "{}")) : {});
    const [tabsDisplayNameMapping, setTabsDisplayNameMapping] = useState({});

    const [bit, setBit] = useState(false);
    const [prevTabsData, setPrevTabsData] = useState({});
    const [headersResponse, setHeadersResponse] = useState({});

    useEffect(() => {
        if(JSON.stringify(props.actionBarConfig?.tabs?.data) != JSON.stringify(prevTabsData)){
            setPrevTabsData(props.actionBarConfig?.tabs?.data)
            setFilteredTabs(JSON.parse(JSON.stringify(props.actionBarConfig?.tabs?.data || "{}")) || {})
            updateCurrentTabOnFilteredTabs({
                filteredTabsData : JSON.parse(JSON.stringify(props.actionBarConfig?.tabs?.data || "{}")) || {}
            })
            getTabsData({
                filteredTabsData : JSON.parse(JSON.stringify(props.actionBarConfig?.tabs?.data || "{}")) || {}
            });
        }
    }, [props.actionBarConfig?.tabs?.data])

    const getTabsData = ({
        filteredTabsData = JSON.parse(JSON.stringify(filteredTabs))
    }) => {
        if(!props.tableHeadersMap){
            return
        }
        let requestId = uuidv4()
        if (props.actionBarConfig?.tabs?.key && Object.keys(props?.tableHeadersMap || {})?.length > 0) {
            let obj = {};
            setHeadersResponse({});
            setTabsLoading(requestId);
            Object.keys(props.tableHeadersMap || {})?.map(item => {
                if (filteredTabsData?.[item]) {
                    obj[props.tableHeadersMap?.[item]?.main?.key] = item;
                    handleApiChange(dispatch, "getHeadersRequest", {
                        enterpriseName: "TURNINGCLOUD",
                        displayName: props.tableHeadersMap?.[item]?.main?.key,
                        basedOn: "main",
                        requestId : requestId
                    })
                }
            })
            setTabsDisplayNameMapping(obj);;
        }else if(typeof(props.tableHeadersMap) == "function" ? props.tableHeadersMap?.()?.main?.key : props.tableHeadersMap?.main?.key){
            setTabsLoading(requestId);
            handleApiChange(dispatch, "getHeadersRequest", {
                enterpriseName: "TURNINGCLOUD",
                displayName: typeof(props.tableHeadersMap) == "function" ? props.tableHeadersMap?.()?.main?.key : props.tableHeadersMap?.main?.key,
                basedOn: "main",
                requestId : requestId
            })
        }else {
            setTabsLoading(false);
        }
    }

    useEffect(() => {
        if (generalStore?.getHeadersData?.isSuccess && generalStore?.getHeadersData?.data?.basedOn !== "main") {
            let data = generalStore?.getHeadersData?.data?.resource || {};
            setHeadersData(updatedHeadersData => {
                updatedHeadersData[`${generalStore?.getHeadersData?.data?.basedOn}`] = data;
                return updatedHeadersData
            })

            setHeadersType(updatedHeadersType => {
                updatedHeadersType[`${generalStore?.getHeadersData?.data?.basedOn}`] = Object.keys(data?.["Custom Headers"] || {})?.length > 0 ? "Custom Headers" : "Default Headers"
                return updatedHeadersType;
            })
            setBit(!bit);
        } else {
            // Main level data render and is called friom this page
            if (tabsLoading === generalStore?.getHeadersData?.data?.requestId) {
                let data = generalStore?.getHeadersData?.data?.resource || {};
                let displayName = generalStore?.getHeadersData?.data?.resource?.displayName || "";
                setHeadersResponse(prev => {
                    prev[displayName] = data?.["isDisplay"];
                    if (Object.keys(prev || {})?.length == Object.keys(tabsDisplayNameMapping || {})?.length && Object.keys(prev || {})?.length > 0) {
                        setTabsLoading(false);

                    }else if(!props.actionBarConfig?.tabs?.key){
                        // Case:  when not tab is present on the page.
                        setTabsLoading(false);
                    }
                    return prev;
                })
                // This should run only when user has some tabs available on the page.
                if (props?.actionBarConfig?.tabs?.key){
                    setFilteredTabs(prev => {
                        if (displayName && data?.["isDisplay"] === 0) {
                            delete prev?.[tabsDisplayNameMapping?.[displayName]];
                        }
                        updateCurrentTabOnFilteredTabs({
                            filteredTabsData : prev || {}
                        })
                        return prev;
                    })   
                }
            }
        }
    }, [generalStore?.getHeadersData?.isSuccess]);

    // Functon : To update the current tab based on the updated filtered tabs
    const updateCurrentTabOnFilteredTabs = ({
        filteredTabsData = filteredTabs,
    }) => {
        let filterTab = JSON.parse(JSON.stringify(filteredTabsData));
        if (props?.actionBarConfig?.tabs && Object.keys(filterTab)?.length !== 0) {
            let tabkey = props?.actionBarConfig?.tabs?.key;
            let payloadVal = props.tableDataPayload?.[tabkey];
    
            if (!filterTab?.[payloadVal]) {
                let payloadVal = Object.keys(filterTab)[0];
                props?.actionBarConfig?.tabs?.onChange(payloadVal)();
            }
        }
    }

    useEffect(() => {
        // See if the current tab is changed or not!
        resetExpandedData();
        setHeadersData({});
        setHeadersType({});
    }, [props.currentTab])

    const resetExpandedData = () => {
        setMainExpandedRow({});
        setMainExpandedData({})
        setItemExpandedData({})
        setItemExpandedRow({})
    }

    // Close the expanded row when the usern wants;
    useEffect(() => {
        if (expandFirstProps?.closeFirstExpandedRow) {
            resetExpandedData();
        }
    }, [expandFirstProps?.closeFirstExpandedRow])

    useLayoutEffect(() => {
        if (generalStore?.["getTableComponentSecondListData"]?.isSuccess) {
            let data = generalStore?.["getTableComponentSecondListData"]?.data;
            let uniqueId = generalStore?.["getTableComponentSecondListData"]?.data?.uniqueExpandId;
            console.log("Check uniqrw",uniqueId);
            if (mainExpandedRow && uniqueId){
                let listData = props?.getExpandFirstListData ? props.getExpandFirstListData(data, {
                    mainExpandedRow : mainExpandedRow?.[uniqueId] || {},
                }) : data?.resource || [];
                let updatedMainExpandedData = {...mainExpandedData};
                updatedMainExpandedData[uniqueId] = listData;
                setMainExpandedData(updatedMainExpandedData);
            }
            let updatedMainExpandLoading = {...mainExpandLoading};
            delete updatedMainExpandLoading?.[uniqueId];
            setMainExpandLoading(updatedMainExpandLoading);
        }
        if(generalStore?.["getTableComponentSecondListData"]?.isError){
            let uniqueId = generalStore?.["getTableComponentSecondListData"]?.data?.uniqueExpandId;
            let updatedMainExpandLoading = {...mainExpandLoading};
            delete updatedMainExpandLoading?.[uniqueId];
            setMainExpandLoading(updatedMainExpandLoading);
        }
    }, [generalStore?.["getTableComponentSecondListData"]])

    console.log("Main ExpNDED", mainExpandedData);

    useEffect(() => {
        if (generalStore?.["getTableComponentThirdListData"]?.isSuccess) {
            let uniqueId = generalStore?.["getTableComponentThirdListData"]?.data?.uniqueExpandId;
            let uniqueId1 = uniqueId?.split(',')?.[1]; 
            let uniqueId2 =  uniqueId?.split(',')?.[0];
            let data = generalStore?.["getTableComponentThirdListData"]?.data;
            let listData = props?.getExpandSecondListData ? props.getExpandSecondListData(data) : data?.resource || [];
            let updatedItemExpandedData = {...itemExpandedData};
            if (itemExpandedRow?.[uniqueId2]?.[uniqueId1]){
                if(!updatedItemExpandedData?.[uniqueId2]){
                    updatedItemExpandedData[uniqueId2] = {};
                }
                updatedItemExpandedData[uniqueId2][uniqueId1] = listData;
                setItemExpandedData(updatedItemExpandedData)
            }

            let updatedItemExpandLoading = {...itemExpandLoading};
            delete updatedItemExpandLoading?.[uniqueId2]?.[uniqueId1];
            setItemExpandLoading(updatedItemExpandLoading);
        }
        if(generalStore?.["getTableComponentThirdListData"]?.isError){
            let uniqueId = generalStore?.["getTableComponentThirdListData"]?.data?.uniqueExpandId;
            let uniqueId1 = uniqueId?.split(',')?.[1]; 
            let uniqueId2 =  uniqueId?.split(',')?.[0];
            let updatedItemExpandLoading = {...itemExpandLoading};
            delete updatedItemExpandLoading?.[uniqueId2]?.[uniqueId1];
            setItemExpandLoading(updatedItemExpandLoading);
        }
    }, [generalStore?.["getTableComponentThirdListData"]])

    const changeTab = (tab) => () => {
        setCurrentTab(tab);
    }

    const expandRow = (item) => e => {
        if (props.getExpandFirstPayload) {
            let updatedMainExpandedRow = {...mainExpandedRow};
            let updatedMainExpandedData = {...mainExpandedData}
            let updatedItemExpandedRow = {...itemExpandedRow};
            let updatedItemExpandedData = {...itemExpandedData};

            console.log("Updated Item expanded data", updatedItemExpandedData);
            
            delete updatedItemExpandedRow?.[item?.id];
            delete updatedItemExpandedData?.[item?.id];

            console.log("Updated Item expanded data", updatedItemExpandedData);
            
            if (!mainExpandedRow?.[item?.id]) {
                let payload = props?.getExpandFirstPayload(item);
                let expandFirstRowItem = props.getExpandFirstRowItem ? props.getExpandFirstRowItem ({item}) : item;
                updatedMainExpandedRow[item?.id] = expandFirstRowItem;
                dispatchHandler(dispatch, "getTableComponentSecondListRequest", {...payload, uniqueExpandId : item?.id}, props?.getExpandFirstExtraHeaders);
                let updatedMainExpandLoading = {...mainExpandLoading};
                updatedMainExpandLoading[item.id] = true; 
                setMainExpandLoading(updatedMainExpandLoading);
                let updatedItemExpandLoading = {...itemExpandLoading};
                delete updatedItemExpandLoading?.[item.id];
                setItemExpandLoading(updatedItemExpandLoading)
            } 
            else {
                delete updatedMainExpandedRow?.[item?.id];
            }
            setMainExpandedRow(updatedMainExpandedRow);
            setMainExpandedData(updatedMainExpandedData)
            setItemExpandedData(updatedItemExpandedData)
            setItemExpandedRow(updatedItemExpandedRow)
        }
    }

    const expandSetRow = (item, mainRowItem) => () => {
        // console.log("main row item",mainRowItem, mainExpandedRow)
        if (props.getExpandSecondPayload) {

            let updatedItemExpandedData = {...itemExpandedData};
            let updatedItemExpandedRow = {...itemExpandedRow};
            delete updatedItemExpandedData?.[mainRowItem?.id]?.[item?.id];

            if (!itemExpandedRow?.[mainRowItem?.id]?.[item?.id]) {
                let payload = props?.getExpandSecondPayload(item, mainExpandedRow?.[mainRowItem?.id]);
                dispatchHandler(dispatch, "getTableComponentThirdListRequest", {...payload, uniqueExpandId : `${mainRowItem?.id},${item?.id}`}, props?.getExpandSecondExtraHeaders);
                if(!updatedItemExpandedRow?.[mainRowItem?.id]){
                    updatedItemExpandedRow[mainRowItem.id] = {};
                }
                let updatedItemExpandLoading = {...itemExpandLoading};
                if(!updatedItemExpandLoading?.[mainRowItem?.id]){
                    updatedItemExpandLoading[mainRowItem.id] = {};
                } 
                updatedItemExpandLoading[mainRowItem.id][item.id] = true;
                updatedItemExpandedRow[mainRowItem.id][item.id] = item;
                setItemExpandLoading(updatedItemExpandLoading);
              
            }
            else {
                delete updatedItemExpandedRow?.[mainRowItem?.id]?.[item?.id];
                if(Object.keys(updatedItemExpandedRow?.[mainRowItem?.id] || {})?.length == 0){
                    delete updatedItemExpandedRow?.[mainRowItem?.id];
                }
            }
            setItemExpandedData(updatedItemExpandedData)
            setItemExpandedRow(updatedItemExpandedRow)
        }
    }

    const getExpandedData = (type, item) => {
        switch(type){
            case "main" : return mainExpandedData?.[item?.id];
        }
    }

    // Required Props = :
    // downloadReport -> If available then this is always required component
    // console.log("table heder", props.tableHeadersPayload);
    // console.log("Summary Request", (props.tableDataPayload?.summaryApiUrl || props.summaryApiUrl) ? `${summaryApiName}Request` : false);
    let Component =   (
        <React.Fragment>
            {
                useRenderClassComponent(TableComponent, {
                    braedCrumps: props.braedCrumps,
                    downloadReportName: props.page == "POGRNREPORT" ? true : false,
                    actionBarConfig: {
                        search: {
                            disabled: false
                        },
                        quickFilter: {
                            disabled: generalStore?.getQuickFiltersData?.isLoading
                        },
                        emailReport: {
                            disabled: false
                        },
                        downloadReport: props?.actionBarConfig?.isItemAvailable ? {
                            headerLevelModule: props?.tableHeadersPayload?.main?.key,
                            detailLevelModule: Object.values(props?.tableHeadersPayload || {})?.map(item => item?.key)?.join(),
                            detailHeadersData: Object.values(headersData || {}),
                            detailHeadersType: Object.values(headersType),
                            tableHeadersMap: props?.tableHeadersPayload
                        } : false,
                        sorting: {
                            disabled: generalStore?.getHeadersData?.isLoading
                        },
                        filters: {
                            disabled: generalStore?.getHeadersData?.isLoading,
                        },
                        ...props.actionBarConfig,
                        tabs: props?.actionBarConfig?.tabsKey ? {
                            key: props?.actionBarConfig?.tabsKey || "status",
                            data: filteredTabs || {},
                            onChange: changeTab
                        } :  props?.actionBarConfig?.tabs ?  {
                            key :  props?.actionBarConfig?.tabs?.key,
                            data : filteredTabs,
                            onChange: props?.actionBarConfig?.tabs?.onChange
                        }  :  false,
                    },
                    tableConfig: {
                        columnSetting: {
                            disabled: generalStore?.getHeadersData?.isLoading || generalStore?.createHeadersData?.isLoading
                        },
                        expand: (item) => props.expandFirstKey ? {
                            expandedData: getExpandedData("main", item) || [],
                            disabled: false,
                            expandedRow: mainExpandedRow,
                            headersData: (props?.expandSecondKey ? props.isMultipleLevelAvailable?.(mainExpandedRow?.[item?.id] || {}) : true) ? headersData?.[typeof (props?.expandFirstKey) == "function" ? props?.expandFirstKey?.(mainExpandedRow?.[item?.id] || {}) : props.expandFirstKey] : headersData?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(mainExpandedRow?.[item?.id] || {}, mainExpandedRow?.[item?.id] || {}) : props.expandSecondKey],
                            headersType: (props?.expandSecondKey ? props.isMultipleLevelAvailable?.(mainExpandedRow?.[item?.id] || {}) : true) ? headersType?.[typeof (props?.expandFirstKey) == "function" ? props?.expandFirstKey?.(mainExpandedRow?.[item?.id] || {}) : props.expandFirstKey] : headersType?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(mainExpandedRow?.[item?.id] || {}, mainExpandedRow?.[item?.id] || {}) : props.expandSecondKey],
                            isLoading: generalStore?.["getTableComponentSecondListData"]?.isLoading && (mainExpandLoading?.[item?.id]),
                            onClick: expandRow,
                            
                            expand: (subItem) => Object.keys(mainExpandedRow?.[item?.id] || {})?.length > 0 && (props.isMultipleLevelAvailable?.(mainExpandedRow?.[item?.id] || {}) || false) ? {
                                expandedData: itemExpandedData?.[item?.id]?.[subItem?.id] || [],
                                expandedRow: itemExpandedRow?.[item?.id] || {},
                                headersData: headersData?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(itemExpandedRow?.[item?.id]?.[subItem?.id] || {}, mainExpandedRow?.[item?.id] || {}) : props.expandSecondKey],
                                headersType: headersType?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(itemExpandedRow?.[item?.id]?.[subItem?.id] || {}, mainExpandedRow?.[item?.id] || {}) : props.expandSecondKey],
                                isLoading: generalStore?.["getTableComponentThirdListData"]?.isLoading && (itemExpandLoading?.[item?.id]?.[subItem?.id] ),
                                onClick: (subItem) => expandSetRow?.(subItem, item)
                            } : false,

                            ...(typeof (expandFirstProps) == "function" ? expandFirstProps({ expandedRow: mainExpandedRow?.[item?.id] }) : expandFirstProps || {})
                        } : false,
                        ...props.tableConfig,
                    },
                    collapseAll : {
                        onClick : () => resetExpandedData(),
                        isCollapseAllOpen : () => Object.keys(mainExpandedRow || {})?.length > 0,
                    },
                    tableHeadersPayload: props.tableHeadersPayload,
                    quickFiltersPayload: props.quickFiltersPayload || {
                        attributeType: props.tableHeadersPayload?.main?.key,
                        displayName: props.tableHeadersPayload?.main?.key,
                        filterName: ""
                    },
                    tableDataPayload: props.tableDataPayload || {
                        type: 1,
                        pageNo: 1,
                        sortedBy: "",
                        sortedIn: "",
                        search: "",
                        filter: {},
                        pageSize: 10,
                        apiUrl: props.apiUrl || undefined,
                        baseUrl: props.baseUrl || undefined,
                        summaryApiUrl: props.summaryApiUrl || undefined,
                    },
                    summaryRequest: (props.tableDataPayload?.summaryApiUrl || props.summaryApiUrl) ? `${summaryApiName}Request` : false,
                    getTableDataRequest: props.getTableDataRequest ? props.getTableDataRequest : `${apiName}Request` || "",
                    getTableDataLoading: props.getTableDataLoading ? props.getTableDataLoading : states?.[storeName]?.[`${apiName}Data`]?.isLoading,
                    getTableDataSuccess: props.getTableDataSuccess ? props.getTableDataSuccess : states?.[storeName]?.[`${apiName}Data`]?.isSuccess,
                    getTableDataError: props.getTableDataError ? props.getTableDataError : states?.[storeName]?.[`${apiName}Data`]?.isError,
                    tableData: props?.tableData ? props.tableData : states?.[storeName]?.[`${apiName}Data`]?.data?.resource ?
                        {
                            data: states?.[storeName]?.[`${apiName}Data`]?.data.resource || [],
                            totalItems: states?.[storeName]?.[`${apiName}Data`]?.data?.[props.totalRecordKey || "totalCount"] || 0,
                            currentPage: states?.[storeName]?.[`${apiName}Data`]?.data?.currPage || 1,
                            maxPage: states?.[storeName]?.[`${apiName}Data`]?.data?.maxPage || 1,
                            getTableApiData: states?.[storeName]?.[`${apiName}Data`]?.data || []
                        } :
                        {
                            data: [],
                            totalItems: 0,
                            currentPage: 1,
                            maxPage: 1
                        },
                    refreshData: props.refreshData || false,
                    confirmationLoading: props.confirmationLoading || false,
                    customAction: props.customAction || false,
                    resetstate: props.resetstate,
                    tableDataPayloadUpdated: props.tableDataPayloadUpdated,
                    ref: props.tableComponentRef || null,
                    imageOrderNumber: props.imageOrderNumber,
                    breadCrumbsProps : props.breadCrumbsProps,
                    viewType: props?.viewType || "list",
                    tableComExtraHeaders: props?.tableComExtraHeaders || null,
                    tableSummaryHeaders: props?.tableSummaryHeaders || null
                },
                    props.availableStores)
            }
        </React.Fragment>
    )

    if (tabsLoading) {
        return <TableFullPageLoader/>
    }

    if(props?.actionBarConfig?.tabs?.key ? Object.keys(filteredTabs)?.length == 0 :  props.tableHeadersMap ? headersResponse?.[typeof(props.tableHeadersMap) == "function" ? props.tableHeadersMap?.()?.main?.key :  props.tableHeadersMap?.main?.key] != 1 : false) {
        return  <NoPageAvailable/>
    }

    return Component;

}