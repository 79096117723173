import React, { useContext, useEffect } from 'react'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { CollectionsContext } from './CombinedContextProvider';
import { getPermissionKey } from '../../helper/permissions';
import { UserDetailsContext } from '../../App';

const ContextHandlers = () => {
	const dispatch = useDispatch();
	let {userDetails, setUserDetails} = useContext(UserDetailsContext)

	// Collections Context Handlers

	const { collectionsConfigurations, setCollectionsConfigurations } = useContext(CollectionsContext)

	const getAllCollectionDetailsData = useSelector(state => state?.digicatStore?.getAllCollectionDetailsData);
	const createNewCollectionData = useSelector(state => state?.digicatStore?.createNewCollectionData);


	const checkDigicatlogModulePresent = () => {
		let modules = JSON.parse(userDetails?.modules || "[]");
		console.log("modules check", modules?.some(item => item?.code == "PDRMAIN" || item?.code == "PCVMAIN"));
		if(modules?.some(item => item?.code == "PDRMAIN" || item?.code == "PCVMAIN")) return true;
		return false;
	}

	useEffect(() => {
		if(checkDigicatlogModulePresent())
			dispatchHandler(dispatch, "getAllCollectionDetailsRequest", {}, getPermissionKey("purchase", "products", "view"));
	}, [])

	useEffect(() => {
		if (createNewCollectionData?.isSuccess) {
			dispatchHandler(dispatch, "getAllCollectionDetailsRequest", {}, getPermissionKey("purchase", "products", "view"));
		}
	}, [createNewCollectionData?.isSuccess])
	useEffect(() => {
		if (getAllCollectionDetailsData?.isSuccess) {

			let data = getAllCollectionDetailsData?.data?.resource || {};

			// Step 1: Merge all objects into a single object
			const mergedData = Object.assign({}, ...data);

			// Step 2: Initialize the result object to hold productCount and productIds for each key
			const result = {};

			// Step 3: Iterate over the merged data and process collectionDetails
			for (const [mainKey, value] of Object.entries(mergedData)) {
				const collectionDetails = value.collectionDetails;

				if (mainKey != 0) {

					// Iterate over each key in collectionDetails
					for (const [detailKey, detailValue] of Object.entries(collectionDetails)) {

						// If this detailKey is not in the result yet, initialize it
						if (!result[detailKey]) {
							result[detailKey] = {
								productCount: 0,
								productIds: []
							};
						}

						// Update the productCount and productIds if the detailKey exists
						result[detailKey].productCount += 1;
						result[detailKey].productIds.push(mainKey);
					}

				}
			}

			setCollectionsConfigurations({
				// the merged data of the collections configurations
				"collectionData": mergedData,
				"collectionsProductsCount": result
			})

		}
	}, [getAllCollectionDetailsData?.isSuccess])

	const getFlatCollectionsDetails = (data) => {
		return Object.assign({}, ...data);
	}

	// Collections Context Handlers End


	return (
		<React.Fragment></React.Fragment>
	)
}

export { ContextHandlers }