import React, { act, useContext, useEffect, useRef, useState } from 'react'
import ListComponent from '../../../genericComponents/ListComponent'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import GenericInput from '../../../genericComponents/input/GenericInputComponent';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/icons';
import { v4 as uuidv4 } from 'uuid';
import { CollectionsContext } from '../../contextProviders/CombinedContextProvider';
import { CONFIG } from '../../../config';
import axios from 'axios';
import { CheckCheck, Plus, RotateCcw, Trash2, TriangleAlert } from 'lucide-react';
import ParentModal from '../../../genericComponents/ParentModal';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import GenericDeleteModal from '../../../genericComponents/helperComponents/GenericDeleteModal';
import { getPermissionKey } from '../../../helper/permissions';
import { updateVendorAndCustomerScopeWithPrefix } from '../../../redux/StateData/apiFunction';

const addProductInsideCollection = (config = {}) => {

    let { collectionsConfigurations, selectedItems, data, setCollectionsConfigurations } = config || {};

    let updatedCollectionsConfigurations = { ...collectionsConfigurations };
    selectedItems?.map(item => {
        updatedCollectionsConfigurations["collectionData"] = {
            ...updatedCollectionsConfigurations["collectionData"],
            [item["id"]]: {
                collectionDetails: {
                    ...updatedCollectionsConfigurations?.["collectionData"]?.[item["id"]]?.["collectionDetails"],
                    [data?.collectionCode]: data?.collectionName
                }
            },
        }
        if (updatedCollectionsConfigurations["collectionData"][item["id"]]) {
            updatedCollectionsConfigurations["collectionData"][item["id"]]["collectionCount"] = Object.keys(updatedCollectionsConfigurations["collectionData"]?.[item?.["id"]]?.["collectionDetails"] || {})?.length;
        }

        if (!updatedCollectionsConfigurations?.["collectionsProductsCount"]?.[data?.["collectionCode"]]) {
            updatedCollectionsConfigurations["collectionsProductsCount"][data?.["collectionCode"]] = {
                productCount: 0, productIds: [],
            };
        }
        // Push the id in the configuration
        if (!updatedCollectionsConfigurations?.["collectionsProductsCount"]?.[data?.["collectionCode"]]?.productIds?.includes(item?.["id"])) {
            updatedCollectionsConfigurations?.["collectionsProductsCount"]?.[data?.["collectionCode"]]?.productIds?.push(item?.["id"]);
            updatedCollectionsConfigurations["collectionsProductsCount"][data?.["collectionCode"]].productCount = updatedCollectionsConfigurations?.["collectionsProductsCount"]?.[data?.["collectionCode"]]?.productIds?.length;
        }
    })

    setCollectionsConfigurations(updatedCollectionsConfigurations);
}

function removeProductFromCollection(config = {}) {
    let { collectionsConfigurations, selectedItems, data, setCollectionsConfigurations } = config || {};
    const { collectionData, collectionsProductsCount } = collectionsConfigurations;
    let collectionIdToDelete = data?.collectionCode;

    selectedItems?.forEach(item => {
        console.log("Cjnblsakjdbnkjhbsaf", collectionData, item, collectionIdToDelete, collectionsProductsCount)
        if (collectionData?.[item?.id]?.collectionDetails?.[collectionIdToDelete]) {
            delete collectionData?.[item?.id]?.collectionDetails?.[collectionIdToDelete];
            collectionData[item?.id]["collectionCount"] = Object.keys(collectionData?.[item?.id]?.collectionDetails || {})?.length || 0;
        }

        if (collectionData[item?.id]["collectionCount"] == 0) {
            delete collectionData[item?.id];
        }

        // Delete the collection from collectionData
        if (collectionsProductsCount?.[collectionIdToDelete]) {
            collectionsProductsCount[collectionIdToDelete].productIds = collectionsProductsCount[collectionIdToDelete].productIds?.filter(val => val != item.id);
            collectionsProductsCount[collectionIdToDelete].productCount = Object.keys(collectionsProductsCount?.[collectionIdToDelete]?.productIds || {})?.length;
            if (collectionsProductsCount[collectionIdToDelete]?.productIds?.length == 0) {
                delete collectionsProductsCount[collectionIdToDelete];
            }
        }
        console.log("Cjnblsakjdbnkjhbsaf", collectionData, item, collectionIdToDelete, collectionsProductsCount)
    })
    setCollectionsConfigurations({ collectionData, collectionsProductsCount })
}


const deleteCollection = (config = {}) => {
    let { collectionsConfigurations, selectedItems, data, setCollectionsConfigurations } = config || {};
    const { collectionData, collectionsProductsCount } = collectionsConfigurations;
    let collectionIdToDelete = data?.collectionCode;


    let ids = Object.keys(collectionData || {});

    ids?.map(item => {
        if (collectionData?.[item]?.collectionDetails?.[data?.collectionCode]) {
            delete collectionData?.[item]?.collectionDetails?.[data?.collectionCode];
            collectionData[item]["collectionCount"] = Object.keys(collectionData?.[item]?.collectionDetails || {})?.length || 0;
        }

        if (collectionData[item]["collectionCount"] == 0) {
            delete collectionData[item];
        }
    })
    delete collectionsProductsCount?.[collectionIdToDelete];
    setCollectionsConfigurations({
        collectionData,
        collectionsProductsCount,
    })

}

const CollectionDropdown = (props = {}) => {

    // Declations Space
    let { closeModal, actionsConfig = {}, selectedItems = [], setSelectedData, setSelectedModal, setCallDeleteItem } = props;
    const dispatch = useDispatch();
    const createNewCollectionData = useSelector(state => state?.digicatStore?.createNewCollectionData);
    const getCollectionsListData = useSelector(state => state?.digicatStore?.getCollectionsListData);
    // State Space
    const [newCollectionConfig, setNewCollectionConfig] = useState({
        mode: false,
        name: "",
        error: null,
        success: null,
        requestId: null,
    })

    // To refresh the list component
    const [refreshData, setRefreshData] = useState(false);
    // To store the loading
    const [loading, setLoading] = useState({
        addProduct: {}
    });
    let loadingRef = useRef(null);
    // To store the error occured 
    const [error, setError] = useState({
        addProduct: {},
        delete: {},
    });
    const errorRef = useRef(null);

    const [success, setSuccess] = useState({});

    const successRef = useRef(null);

    const {
        collectionsConfigurations,
        setCollectionsConfigurations,
    } = useContext(CollectionsContext)

    console.log("Collection Configurations: ", collectionsConfigurations)

    // On the start up check which are the products that are currently selected
    useEffect(() => {

        let productIds = selectedItems?.map(item => item.id);

        let obj = {};

        Object.keys(collectionsConfigurations?.collectionsProductsCount || {})?.map(key => {
            if (productIds?.every(val => collectionsConfigurations?.collectionsProductsCount?.[key]?.productIds?.includes(val))) {
                obj[key] = true;
            }
        })
        setSuccess(obj);
    }, [])

    console.log("collections Configurations", collectionsConfigurations);

    // Handle the success of the new collection api response
    useEffect(() => {
        if (createNewCollectionData?.isSuccess) {
            setNewCollectionConfig({
                ...newCollectionConfig, success: "New Collection Created Successfully.", mode: false, name: "",
            })
            setRefreshData(true);
        }
        if (createNewCollectionData?.isError) {
            setNewCollectionConfig({
                ...newCollectionConfig, error: createNewCollectionData?.message?.errorMessage?.substring(0, 100),
            })
        }
        setTimeout(() => {
            setNewCollectionConfig(prev => {
                delete prev.success; delete prev.error;
                return prev;
            })
        }, 5000)
    }, [createNewCollectionData?.isSuccess, createNewCollectionData?.isError])

    // Hanle the loading of the get collection api
    useEffect(() => {
        if (getCollectionsListData?.isLoading) {
            setRefreshData(false);
        }
    }, [getCollectionsListData?.isLoading])

    useEffect(() => {
        loadingRef.current = loading;
        errorRef.current = error;
        successRef.current = success
    }, [loading, error, success])

    const handleChange = (type, data) => {
        switch (type) {
            case "addToCollection": {
                setNewCollectionConfig(prev => ({ ...prev, requestId: uuidv4() }))
                dispatchHandler(dispatch, "createNewCollectionRequest", data, getPermissionKey("purchase", "products", "view"));
                break;
            }
            case "addProductToCollection": {
                let updatedLoading = { ...loadingRef.current };
                setLoading({
                    ...updatedLoading,
                    addProduct: {
                        ...updatedLoading?.addProduct,
                        [data.collectionCode]: true,
                    }
                })
                let updatedError = { ...errorRef.current }
                delete updatedError.addProduct?.[data.collectionCode];
                setError(updatedError)

                let permissionObj  = { ...getPermissionKey("purchase", "products", "view")};
                if(permissionObj?.['X-Scope-Id']){
                    permissionObj = updateVendorAndCustomerScopeWithPrefix(permissionObj)
                }

                axios.post(encodeURI(`${CONFIG.BASE_URL}/admin/catalogue/collection/submit`), selectedItems?.map(item => ({
                    "collectionCode": data?.collectionCode,
                    "collectionName": data?.collectionName || "",
                    "productId": item?.["id"],
                }))
                    , {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": sessionStorage.getItem("token"),
                            "Page-Link": window.location.hash.split("?")[0] || undefined,
                            ...permissionObj
                        },
                    },)
                    .then(res => {
                        let updatedLoading = { ...(loadingRef.current || {}) }
                        delete updatedLoading?.["addProduct"]?.[data?.collectionCode];
                        setLoading(updatedLoading)
                        if ([200, 201, 2000, 2001]?.includes(res.status) && ["200", "201", "2000", "2001"]?.includes(res.data?.status)) {
                            // let data = res.data?.resource;
                            // Add the product to the context also.
                            addProductInsideCollection({ selectedItems, setCollectionsConfigurations, collectionsConfigurations, data });
                            let updatedSuccess = { ...successRef.current };
                            updatedSuccess[data.collectionCode] = true;
                            setSuccess(updatedSuccess);
                        } else {
                            let updatedError = { ...errorRef.current };
                            updatedError["addProduct"][data.collectionCode] = true;
                            setError(updatedError);
                        }
                    })
                    .catch(error => {
                        setLoading(prev => {
                            delete prev?.["addProduct"]?.[data?.collectionCode];
                            return prev;
                        })
                        let updatedError = { ...errorRef.current };
                        updatedError["addProduct"][data.collectionCode] = true;
                        setError(updatedError);
                    });
                break;
            }
            case "deleteCollection": {
                // Case: To delete a collections data;
                setLoading({
                    ...loading,
                    delete: data?.collectionCode
                })
                let updatedError = { ...errorRef.current }
                delete updatedError.addProduct?.[data.collectionCode];
                setError(updatedError)
                let permissionObj  = { ...getPermissionKey("purchase", "products", "view")};
                if(permissionObj?.['X-Scope-Id']){
                    permissionObj = updateVendorAndCustomerScopeWithPrefix(permissionObj)
                }

                axios.post(encodeURI(`${CONFIG.BASE_URL}/admin/catalogue/delete/collection`), {
                    "collectionCode": [data?.collectionCode],
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": sessionStorage.getItem("token"),
                        "Page-Link": window.location.hash.split("?")[0] || undefined,
                        ...permissionObj
                    },
                },)
                    .then(res => {
                        setLoading(prev => {
                            delete prev?.["delete"];
                            return prev;
                        })
                        if ([200, 201, 2000, 2001]?.includes(res.status) && ["200", "201", "2000", "2001"]?.includes(res.data?.status)) {
                            // let data = res.data?.resource;
                            setRefreshData(true);
                            deleteCollection({ selectedItems, setCollectionsConfigurations, collectionsConfigurations, data });
                            // Delete the collection from the context also.
                        } else {
                            let updatedError = { ...errorRef.current };
                            updatedError["delete"][data.collectionCode] = true;
                            setError(updatedError);
                        }
                    })
                    .catch(error => {
                        setLoading(prev => {
                            delete prev?.["delete"];
                            return prev;
                        })
                        let updatedError = { ...errorRef.current };
                        updatedError["delete"][data.collectionCode] = true;
                        setError(updatedError);
                        console.error("Error deleting collection:", error);
                    }).finally(() => {
                        setSelectedData({})
                    })
                break;
            }
            case 'openDeleteModal': {
                setSelectedData(data)
                setSelectedModal('deleteCollection')
                setCallDeleteItem({handleChange: handleChange})
            }
            default:
        }
    }

    return (
                <ListComponent
                    modalMainClass={`roles-modal-height-manage ${newCollectionConfig?.["mode"] && 'reduceListHeight'}`}
                    viewDataType="table-view"
                    label={{
                        content: "Collections",
                        closeModal: () => closeModal()
                    }}
                    closeModal={closeModal}
                    search={{
                        placeholder: "Search Collections...",
                        isSearchIconRequire: true,
                    }}
                    api={{
                        reduxState: 'digicatStore',
                        apiName: "getCollectionsList",
                        apiPayload: (params) => {
                            return {
                                pageNo: params?.currentPage || 1,
                                search: params?.currentSearch || "",
                                pageSize: 30,
                            }
                        },
                        apiExtraHeaders :  getPermissionKey("purchase", "products", "view"),
                        getMaxPage: (states, functions, response) => {
                            return Number(response?.resource?.maxPage || "");
                        },
                    }}
                    getListData={(data) => {
                        return data?.resource?.data || []
                    }}
                    headerKeys={{
                        "label": {}
                    }}
                    itemKeys={{
                        "label": ["label"]
                    }}
                    useKeyboardShortcuts={false}
                    refreshData={refreshData}
                    renderCustomItems={{
                        "1": () => {
                            // render the custom input here

                            const getTooltip = () => {
                                if (newCollectionConfig?.["name"]?.length > 60) return "Collection Name should be within 60 characters limit.";
                                if (newCollectionConfig?.["name"]?.length <= 0) return "Collection Name is required."

                                return null;
                            }
                            return <div className='px-[15px] mt-[.75rem] !text-fontBlack mb-2'>
                                {!newCollectionConfig?.["mode"] && (actionsConfig?.["saveAndAddNewProduct"] || actionsConfig?.["addNewCollection"]) && <GenericCustomButton
                                    label={actionsConfig?.["saveAndAddNewProduct"] ? "Add To New Collection" : "Add New Collection"}
                                    // type="default"
                                    onClick={() => setNewCollectionConfig({ ...newCollectionConfig, "mode": true })}
                                    className={'w-full !font-semibold border !border-dashed !ml-0 !h-[32px] text-xs'}
                                />}
                                {newCollectionConfig?.["mode"] && <div>
                                    <GenericInput
                                        placeholder="Enter Collection Name"
                                        value={newCollectionConfig?.["name"] || ""}
                                        inputKey="name"
                                        dependentFieldsObject={["name"]}
                                        onKeyDown={(e) => {
                                            if (e?.key == "Enter" && !createNewCollectionData?.isLoading && newCollectionConfig?.["name"]?.length > 0 && newCollectionConfig?.["name"]?.length <= 60) {
                                                handleChange("addToCollection", actionsConfig?.["saveAndAddNewProduct"] ?
                                                    selectedItems?.map(item => ({ productId: item?.id, collectionCode: "", collectionName: newCollectionConfig?.["name"] }))
                                                    : [{ productId: "", collectionCode: "", collectionName: newCollectionConfig?.name }])
                                            }
                                        }}
                                        onChange={(e) => setNewCollectionConfig({ ...newCollectionConfig, "name": e?.target?.value })}
                                    />
                                    {actionsConfig?.["saveAndAddNewProduct"] || actionsConfig?.["addNewCollection"] && <GenericCustomButton
                                        label={actionsConfig?.["saveAndAddNewProduct"] ? "Save Collection & Add" : actionsConfig?.["addNewCollection"] ? "Save Collection" : ""}
                                        type="primary"
                                        tooltip={getTooltip()}
                                        disabled={newCollectionConfig?.["name"]?.length <= 0 || newCollectionConfig?.["name"]?.length > 60}
                                        loading={createNewCollectionData?.isLoading ?? false}
                                        onClick={() => {
                                            handleChange("addToCollection", actionsConfig?.["saveAndAddNewProduct"] ?
                                                selectedItems?.map(item => ({ productId: item?.id, collectionCode: "", collectionName: newCollectionConfig?.["name"] }))
                                                : [{ productId: "", collectionCode: "", collectionName: newCollectionConfig?.name }])
                                        }}
                                        className={'w-full !ml-0 !rounded-none'}
                                    />}
                                    <hr className='mt-[1rem] border-[#dce6f0]' />
                                </div>}

                                {/* Render the success or the error messsage inside the add to new collection  */}
                                {newCollectionConfig?.["success"] && <p className='text-green-600 text-xs mt-[6px]'>{newCollectionConfig?.["success"] ?? ""}</p>}
                                {newCollectionConfig?.["error"] && <p className='text-danger text-xs mt-[6px]'>{newCollectionConfig?.["error"] ?? ""}</p>}
                            </div>
                        }
                    }}
                    renderCustomListCompleteItem={(s, f, item) => {
                        // Render the custom colleciton lsit item here
                        return `${item?.["collectionName"]}` &&
                            <div className='px-[15px] mt-[4px] mb-3.5 w-full flex items-center justify-between gap-2 text-fontBlack'>
                                <div className={`${actionsConfig['redirectToCollection'] ? 'w-full' : ''}`}>
                                    <p className={`text-xs font-medium mb-1 ${actionsConfig['redirectToCollection'] ? 'truncate' : ''}`}>{item?.["collectionName"] || ""}</p>
                                    <p
                                        onClick={() => {
                                            if (actionsConfig?.["canGoCollectionMode"]) {
                                                props.switchToCollectionMode({
                                                    item: item
                                                 })
                                            }

                                            if(actionsConfig?.["redirectToCollection"]){
                                                props.redirectToCollection({
                                                    item : item,
                                                })
                                            }
                                        }}
                                        className={`text-[#A6A8AC] text-[10px] mb-0 ${actionsConfig?.["canGoCollectionMode"] ? 'underline hover:text-primary border-primary cursor-pointer' : ''} ${actionsConfig['redirectToCollection'] ? 'w-fit underline hover:text-primary border-primary cursor-pointer' : ''}`}>{collectionsConfigurations?.["collectionsProductsCount"]?.[item?.["collectionCode"]]?.productCount || "0"} {collectionsConfigurations?.["collectionsProductsCount"]?.[item?.["collectionCode"]]?.productCount  > 1 ? "Product"  : "Products"}</p>
                                </div>
                                {(actionsConfig?.["addNewProduct"] || actionsConfig?.["delete"]) && <div className='flex items-center gap-2'>
                                    {actionsConfig?.["addNewProduct"] && <GenericCustomButton
                                        // disabled={success?.[item?.collectionCode]}
                                        leftIcon={error?.["addProduct"]?.[item?.collectionCode] ? <RotateCcw size={15} className='text-black group-hover:text-white' /> : success?.[item?.collectionCode] ? <CheckCheck size={15} className='text-white' /> : <Plus size={15} className='text-black group-hover:text-white' />}
                                        loading={loading?.["addProduct"]?.[item?.collectionCode]}
                                        onClick={() => !success?.[item?.collectionCode] && handleChange("addProductToCollection", item)}
                                        className={`group !border-none !h-[24px] !w-[26px] !inline-flex items-center justify-center !px-0 !py-0 ${!success?.[item?.collectionCode] ? 'hover:!bg-primary' : '!cursor-not-allowed'} ${success?.[item?.collectionCode] ? '!bg-[#03B675]' : '!bg-[#E3E4E7]'} ${loading?.["addProduct"]?.[item?.collectionCode] && 'buttonLoading'}`}
                                    />}
                                    {actionsConfig?.["delete"] && <GenericCustomButton
                                        leftIcon={error?.["delete"]?.[item?.collectionCode] ? <RotateCcw size={15} className='text-black group-hover:!text-white' /> : <Trash2 size={15} className='text-black group-hover:text-white' />}
                                        disabled={loading?.["delete"]}
                                        loading={loading?.["delete"] == item?.["collectionCode"]}
                                        onClick={() => handleChange('openDeleteModal', item)}
                                        className={`group !border-none !h-[24px] !w-[26px] !inline-flex items-center justify-center !px-0 !py-0 hover:!bg-primary !bg-[#E3E4E7]`}
                                    />}
                                </div>}
                            </div>
                    }}
                />
    )
}

export { CollectionDropdown, removeProductFromCollection }
