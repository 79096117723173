import React from 'react'
import TableSkeletonLoader from '../oldGenericComponents/tableHelperComponents/TableSkeletonLoader'
function TableFullPageLoader() {
    return (
        <div className='space-y-3'>
            <div className='space-y-3 bg-white pt-[12px]'>

                <div className='pl-[30px] pr-[15px] flex justify-between items-center gap-2'>
                    <div className='generic-skeleton mb-0 w-[300px]'></div>
                    <div className='flex items-center gap-3'>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                        <div className='generic-skeleton mb-0 w-[25px]'></div>
                    </div>
                </div>
                <div className='pl-[30px] pr-[15px] flex justify-between items-center gap-2'>
                    <div className='flex items-center gap-3'>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                        <div className='generic-skeleton mb-0 w-[100px]'></div>
                    </div>
                    <div className='flex justify-between items-center gap-2 p-[12px]'></div>
                    <div className='generic-skeleton mb-0 w-[100px]'></div>
                </div>
            </div>
            <div className='bg-white pt-[12px] pl-[30px] pr-[15px] flex justify-end items-center gap-2 mr-[15px] ml-[30px] rounded'>
                <div className='generic-skeleton mb-0 w-[100px]'></div>
                <div className='generic-skeleton mb-0 w-[25px]'></div>
                <div className='generic-skeleton mb-0 w-[100px]'></div>
                <div className='generic-skeleton mb-0 w-[25px]'></div>
                <div className='generic-skeleton mb-0 w-[25px]'></div>
                <div className='generic-skeleton mb-0 w-[80px]'></div>
                <div className='generic-skeleton mb-0 w-[25px]'></div>
                <div className='generic-skeleton mb-0 w-[25px]'></div>

            </div>
            <TableSkeletonLoader />
        </div>
    )
}

export default TableFullPageLoader
