import React, { useContext, useEffect, useState } from 'react'
import { connectWithSocketConnection, disconnectWithSocketConnection, getSocketConnection } from './socketHelperConnection.js'
import ParentModal from '../genericComponents/ParentModal.jsx';
import SinglePageDocument from '../components/singlePageDocument/SinglePageDocument.jsx';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { CommentContext } from '../components/AppStack.jsx';

const SocketComponent = () => {

    const history  = useHistory();
    const location = useLocation();
    const [selectedModal, setSelectedModal] = useState(null);
    const [selectedData, setSelectedData] = useState({})

    const {unreadComments, setUnreadComments} = useContext(CommentContext)
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search || "");
        let decodedParams = {};
        queryParams.forEach((value, key) => {
            decodedParams[key] = JSON.parse(decodeURIComponent(value));
        });
        if(decodedParams?.productId && decodedParams?.actionType) {
            setSelectedModal("singleDocument");
            setSelectedData({productId: decodedParams?.productId, actionType: decodedParams?.actionType})
        }

    }, [location.search]);

    useEffect(() => {
        const socket = getSocketConnection();
        if(socket?.connected){
            socket.on("new_comment_addition", (data) => {
                if (data?.documentId && data?.documentNumber) {
                    let updatedUnreadComments = { ...unreadComments };
                    if (!updatedUnreadComments) { updatedUnreadComments = {} }
                    updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] =  Number(updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] || "") + 1;
                    setUnreadComments(updatedUnreadComments);
                }
            })
        }
        return (() => {
            let socket = getSocketConnection();
            if (socket) 
                socket.off("new_comment_addition")
        })
    },[unreadComments])


    const getParentChildProps = () => {
        switch (selectedModal) {
            case "singleDocument": {
                return {
                    closeModal: () => { setSelectedModal("") },
                    animationType: "bottom",
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)'},
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "80vw", height:"80vh", maxHeight: '660px', maxWidth: '1140px' },
                    selectedData: selectedData
                }
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "singleDocument": return SinglePageDocument
        }
    }

    return (
        <React.Fragment>
            {selectedModal ? <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            /> : null}
        </React.Fragment>
    )
}

export default SocketComponent