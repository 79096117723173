import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../assets/icons';
import GenericTableComponent from '../genericComponents/tableComponent/GenericTableComponent';
import GenericStatusComponent from '../genericComponents/GenericStatusComponent';
import { getPermissionKey } from '../helper/permissions';

function DataExportLogs(props) {
    const generalStore = useSelector(state => state.generalStore);

    const tableHeadersMap = {
        main: {
            name: "Main",
            key: "EXCEL_EXPORT_LOG"
        }
    }

    return (
        <>
            <GenericTableComponent

                tableConfig={{
                    extras: item => <React.Fragment>
                        <li data-interactive="true" onClick={() => item?.file_url ? window.open(item.file_url) : null} className={!item?.file_url ? 'til-inner opacity04' : 'til-inner til-copy-btn'}>
                            <span>
                                <Icons.BlueDownloadIcon />
                            </span>
                        </li>
                    </React.Fragment>,
                    customCols: {
                        status: item => <GenericStatusComponent status={item?.["status"]} />
                    }
                }}
                tableComExtraHeaders={getPermissionKey("common", "commonFunctionality", "view")}
                tableDataPayload={{
                    type: 1,
                    pageNo: 1,
                    pageSize: 10,
                    sortedBy: "",
                    sortedIn: "",
                    search: "",
                    filter: {},
                    apiUrl: "/core/org/data/export/getAll"
                }}

                tableData={generalStore?.getTableComponentListData?.data?.resource ?
                    {
                        data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
                        totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                        currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                        maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                        getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                    } :
                    {
                        data: [],
                        totalItems: 0,
                        currentPage: 1,
                        maxPage: 1
                    }
                }
                tableHeadersMap={tableHeadersMap}
                tableHeadersPayload={tableHeadersMap}
                availableStores={["generalStore"]}
            />
        </>
    )
}

export default DataExportLogs;