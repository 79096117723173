import React from 'react'
import GenericTableComponent from '../../genericComponents/tableComponent/GenericTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../assets/icons';
import { getPermissionKey } from '../../helper/permissions';

function ExcelUploadLogs() {

    const generalStore = useSelector(state => state.generalStore);

    const tableHeadersMap = {
        main: {
            name: "Main",
            key: "EXCEL_UPLOAD_LOGS"
        }
    }

    return (
        <>
            <GenericTableComponent
                actionBarConfig={{
                    downloadReport: {
						headerLevelModule: tableHeadersMap?.main?.key,
						tableHeadersMap: tableHeadersMap
					}
                }}

                tableConfig={{
                    extras: item => <React.Fragment>
                        <li data-interactive="true" onClick={() => window.open(item.filePath)} className={item.filePath == null ? 'til-inner opacity04' : 'til-inner til-copy-btn'}>
                            <span>
                                <Icons.BlueDownloadIcon />
                            </span>
                        </li>
                    </React.Fragment>
                }}

                tableDataPayload={{
                    type: 1,
                    pageNo: 1,
                    pageSize: 10,
                    sortedBy: "",
                    sortedIn: "",
                    search: "",
                    filter: {},
                    apiUrl: "/system/config/excel/upload/log"
                }}
                tableComExtraHeaders={getPermissionKey("purchase", "excelupload", "view")}

                tableData={generalStore?.getTableComponentListData?.data?.resource ?
                    {
                        data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
                        totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                        currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                        maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                        getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                    } :
                    {
                        data: [],
                        totalItems: 0,
                        currentPage: 1,
                        maxPage: 1
                    }
                }

                tableHeadersPayload={tableHeadersMap}
                tableHeadersMap={tableHeadersMap}
                availableStores={["generalStore"]}
            />
        </>
    )
}

export default ExcelUploadLogs