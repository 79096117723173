import React, { useEffect, useState } from 'react';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';
import { getDesiredPayload, provideInputs } from './components/CommonFunctions';
import { getPermissionKey } from '../../helper/permissions';

const DocumentUpload = ({ menuBarDetailsData = [], getPayloadData, getInitialPayload, getInputErrorState }) => {
    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({})
    const dispatch = useDispatch()

    // logic to storing the inputdata in the required format
    useEffect(() => {
        // Constructing the object with headerKey as the key
        const newData = {};
        menuBarDetailsData?.forEach(item => {
            const updatedValue = item?.value?.map(field => {
                // If the field type is "upload" and it has a non-null value, parse it
                if (field?.type === "upload" && field?.value) {
                    return {
                        ...field,
                        value: typeof field?.value === 'string' && field?.value?.length > 0 ? JSON.parse(field?.value) : field?.value
                    };
                }
                // If the field type is "docLov" and it has a non-empty data object, parse it
                if (field?.type === "docLov" && field?.data) {
                    const parsedData = {};
                    Object.entries(field?.data)?.forEach(([key, value]) => {
                        // Check if value is a string before parsing
                        parsedData[key] = typeof value === 'string' ? JSON.parse(value) : value;
                    });
                    return {
                        ...field,
                        data: parsedData
                    };
                }
                return field;
            });

            // Update the item with the updated value
            newData[item.headerKey] = {
                ...item,
                value: updatedValue
            };
        });
        setInputData(newData);

        // getting the initial payload data
        const initialPayloadData = getDesiredPayload(newData);
        getInitialPayload(initialPayloadData)
    }, [menuBarDetailsData]);

    // function to get the desired payload and transfering to the parent
    useEffect(() => {
        getPayloadData(getDesiredPayload(inputData))
    }, [inputData])

    // getting InputErrorStatedata in parent component
    useEffect(() => {
        getInputErrorState({ inputData, errorData, setErrorData })
    }, [errorData, inputData])

    console.log('inoputdatatvxvgwfdvvcfw', inputData);

    // object to pass additionall data
    const extraData = {
        deleteImgFromServer: (files) => {
            let payload = [];
            files?.map((file) => {
                let fileObj = {
                    fileName: file?.fileName,
                    filePath: file?.filePath,
                    id: file?.id
                }
                payload.push(fileObj);
            });
            dispatchHandler(dispatch, "utilityDocumentDeleteRequest", payload, getPermissionKey("common", "commonFunctionality", "upload"));
        }
    }

    return (
        <div className='vob-right'>
            {
                Object.values(inputData || {})?.map((inputField, index) => {
                    return (
                        <div className='vobr-sec-sel' key={inputField?.headerKey}>
                            <h3> {inputField?.headerName} {inputField?.isSectionMandate && <span className='mandatory'>*</span>} </h3>
                            {/* {provideInputs(inputField, inputData, setInputData, errorData, setErrorData, extraData)} */}
                            {provideInputs(inputField, inputData, setInputData, errorData?.[index], (data) => {
                                let updatedErrorData = { ...errorData }
                                updatedErrorData[index] = data
                                setErrorData(updatedErrorData);
                            }, { ...extraData },)}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default DocumentUpload;