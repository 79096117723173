const permissions = {
    dashboard: {
        procurement: { view: "dashboard.procurement.view" },
        ordertracking: { view: "dashboard.ordertracking.view" },
        asnlifecycle: { view: "dashboard.asnlifecycle.view" },
        boxanalysis: { view: "dashboard.boxanalysis.view" },
        warehouseappointment: { view: "dashboard.warehouseappointment.view" },
        sotracking: { view: "dashboard.sotracking.view" },
        arsdashboard: { view: "dashboard.arsdashboard.view" },
    },
    planning: {
        assortment: { view: "planning.assortment.view" },
        siteAssortmentMbq: { view: "planning.siteassortmentmbq.view" },
        assortmentSizeMbq: { view: "planning.assortmentsizembq.view" },
        siteToSiteMapping: {
            view: "planning.sitetositemapping.view",
            create: "planning.sitetositemapping.create",
            update: "planning.sitetositemapping.update",
            delete: "planning.sitetositemapping.delete",
        },
        eventMapping: {
            view: "planning.eventmapping.view",
            create: "planning.eventmapping.create",
            update: "planning.eventmapping.update",
            delete: "planning.eventmapping.delete",
        },
        scheduledJobs: {
            view: "planning.scheduledjobs.view",
            delete: "planning.scheduledjobs.delete",
            create: "planning.scheduledjobs.create",
        },
        summary: { view: "planning.summary.view" },
        orderFulfillment: { view: "planning.orderfulfillment.view" },
        whReplenishment: {
            view: "planning.whreplenishment.view",
            runNow: "planning.whreplenishment.runnow",
        },
        history: { view: "planning.history.view" },
        adhocRequest: {
            view: "planning.adhocrequest.view",
            approve: "planning.adhocrequest.approve",
            update: "planning.adhocrequest.update",
            create: "planning.adhocrequest.create",
            delete: "planning.adhocrequest.delete",
        },
        transferOrder: {
            view: "planning.transferorder.view",
            approve: "planning.transferorder.approve",
            update: "planning.transferorder.update",
            unapprove: "planning.transferorder.unapprove",
            cancel: "planning.transferorder.cancel",
        },
        customData: { view: "planning.customdata.view" },
    },
    purchase: {
        indent: {
            view: "purchase.indent.view",
            create: "purchase.indent.create",
            update: "purchase.indent.update",
            delete: "purchase.indent.delete",
            approve: "purchase.indent.approve",
            copy: "purchase.indent.copy",
            cancel: "purchase.indent.cancel",
            merge: "purchase.indent.merge",
        },
        order: {
            view: "purchase.order.view",
            create: "purchase.order.create",
            update: "purchase.order.update",
            approve: "purchase.order.approve",
            extendValidity: "purchase.order.extendvalidity",
            delete: "purchase.order.delete",
            bulkoperation: "purchase.order.bulkoperation",
        },
        excelUpload: {
            view: "purchase.excelupload.view",
            upload: "purchase.excelupload.upload",
        },
        otbplan: {
            view: "purchase.otbplan.view",
            update: "purchase.otbplan.update",
            create: "purchase.otbplan.create",
            approve: "purchase.otbplan.approve",
            delete: "purchase.otbplan.delete",
        },
        mrprange: {
            view: "purchase.mrprange.view",
            update: "purchase.mrprange.update",
            create: "purchase.mrprange.create",
            delete: "purchase.mrprange.delete",
        },
        fabricnomination: {
            view: "purchase.fabricnomination.view",
            create: "purchase.fabricnomination.create",
            update: "purchase.fabricnomination.update",
            delete: "purchase.fabricnomination.delete",
        },
        qualitycheck: {
            view: "purchase.qualitycheck.view",
            approve: "purchase.qualitycheck.approve",
            completeinspection: "purchase.qualitycheck.completeinspection",
        },
        logistic: {
            view: "purchase.logistic.view",
            create: "purchase.logistic.create",
            update: "purchase.logistic.update",
            cancel: "purchase.logistic.cancel",
            delete: "purchase.logistic.delete",
            approve: "purchase.logistic.approve",
            confirm : "purchase.logistic.confirm"
        },
        shipment: {
            view: "purchase.shipment.view",
            update: "purchase.shipment.update",
            approve: "purchase.shipment.approve",
            create: "purchase.shipment.create",
            cancel: "purchase.shipment.cancel",
            completeinspection: "purchase.shipment.completeinspection",
            extendValidity: "purchase.shipment.extendvalidity",
            uploadinvoice: "purchase.shipment.uploadinvoice",
            cancelasn: "purchase.shipment.cancelasn",
        },
        gateentry: {
            view: "purchase.gateentry.view",
            cancel: "purchase.gateentry.cancel",
            cancelPartialAsn: "purchase.gateentry.cancelpartialasn",
            update: "purchase.gateentry.update",
        },
        grcConfirm: {
            view: "purchase.grc.view",
            create: "purchase.grc.create",
        },
        grc: {
            view: "purchase.grc.view",
        },
        appointment: {
            view: "purchase.appointment.view",
            update: "purchase.appointment.update",
            approve: "purchase.appointment.approve",
            manageConfig: "purchase.appointment.manageconfig",
        },
        poconfig: {
            view: "purchase.poconfig.view",
            create: "purchase.poconfig.create",
            delete: "purchase.poconfig.delete",
            update: "purchase.poconfig.update",
        },
        autoasn: {
            view: "purchase.autoasn.view",
            create: "purchase.autoasn.create",
            update: "purchase.autoasn.update",
            delete: "purchase.autoasn.delete",
        },
        product: {
            view: "purchase.products.view",
            create: "purchase.products.create",
            update: "purchase.products.update",
            copy: "purchase.products.copy",
            delete: "purchase.products.delete",
            submit: "purchase.products.submit",
            sampleandcostrequest: "purchase.products.sampleandcostrequest",
            approve: "purchase.products.approve",
        },
        orders: {
            view: "purchase.orders.view",
            create: "purchase.orders.create",
            update: "purchase.orders.update",
            approve: "purchase.orders.approve",
            extendValidity: "purchase.orders.extendvalidity",
            cancelpartial: "purchase.orders.cancelpartial",
            holdasn: "purchase.orders.holdasn",
            bulkoperation: "purchase.orders.bulkoperation"
        },
        orderdesign: {
            view: "purchase.orderdesign.view",
            create: "purchase.orderdesign.create",
            update: "purchase.orderdesign.update",
            cancelasn: "purchase.orderdesign.cancelasn",
            cancelinvoice: "purchase.orderdesign.cancelinvoice",
        },
        logistic: {
            view: "purchase.logistic.view",
            updatetrackingstatus: "purchase.logistic.updatetrackingstatus",
            update: "purchase.logistic.update",
            cancel: "purchase.logistic.cancel",
        },
        orderrequest: {
            view: "purchase.orderrequest.view",
            create: "purchase.orderrequest.create",
            cancel: "purchase.orderrequest.cancel",
        },
        finance: {
            view: "purchase.finance.view",
            update: "purchase.finance.update",
        },
        products: {
            view: "purchase.products.view",
            create: "purchase.products.create",
            update: "purchase.products.update",
            delete: "purchase.products.delete",
            cancelinvoice: "purchase.products.cancelinvoice",
            bulkupload: "purchase.products.bulkupload",
            copy: "purchase.products.copy",
            cancel: "purchase.products.cancel",
            sampledispatch: "purchase.products.sampledispatch",
            costsubmit: "purchase.products.costsubmit",
        },
        product: {
            view: "purchase.products.view",
            create: "purchase.products.create",
            update: "purchase.products.update",
            delete: "purchase.products.delete",
            cancelinvoice: "purchase.products.cancelinvoice",
            bulkupload: "purchase.products.bulkupload",
            copy: "purchase.products.copy",
            cancel: "purchase.products.cancel",
            sampledispatch: "purchase.products.sampledispatch",
            costsubmit: "purchase.products.costsubmit",
        },
        library: {
            view: "purchase.library.view",
            create: "purchase.library.create",
            update: "purchase.library.update",
            upload: "purchase.library.upload",
        },
        salesindent: {
            view: "purchase.salesindent.view",
            create: "purchase.salesindent.create",
            update: "purchase.salesindent.update",
        },
        createindnet: {
            view: "purchase.createindnet.view",
            create: "purchase.createindnet.create",
        },
    },
    sales: {
        salesorder: {
            view: "sales.salesorder.view",
            create: "sales.salesorder.create",
            update: "sales.salesorder.update",
            copy: "sales.salesorder.copy",
            approve: "sales.salesorder.approve",
            delete: "sales.salesorder.delete",
            cancel: "sales.salesorder.cancel",
            unapprove: "sales.salesorder.unapprove",
            extendvalidity: "sales.salesorder.extendvalidity",
            delivered: "sales.salesorder.delivered",
        },
        salesagent: {
            view: "sales.salesagent.view",
            update: "sales.salesagent.update",
        },
        customer: {
            view: "sales.customer.view",
            update: "sales.customer.update",
        },
        priceList: { na: "sales.pricelist.na" },
        deliverychallan: {
            view: "sales.deliverychallan.view",
            create: "sales.deliverychallan.create",
            approve: "sales.deliverychallan.approve",
        },
        buyingplan: {
            view: "sales.buyingplan.view",
            approval: "sales.buyingplan.approval",
            confirm: "sales.buyingplan.confirm",
            converttoindent: "sales.buyingplan.converttoindent",
            submit: "sales.buyingplan.submit",
            copy: "sales.buyingplan.copy",
        },
    },
    reportsanalytics: {
        storeAnalysis: { view: "reportsanalytics.storeanalysis.view" },
        inventoryPlanning: { view: "reportsanalytics.inventoryplanning.view" },
        storePerformance: { view: "reportsanalytics.storeperformance.view" },
        transferorderreport: { view: "reportsanalytics.transferorderreport.view"},
        salesInventory: { view: "reportsanalytics.salesinventory.view" },
        salesContribution: { view: "reportsanalytics.salescontribution.view" },
        salesComparison: { view: "reportsanalytics.salescomparison.view" },
        sellthruperformance: { view: "reportsanalytics.sellthruperformance.view" },
        categorysizeinventory: { view: "reportsanalytics.categorysizeinventory.view" },
        topmovingitems: { view: "reportsanalytics.topmovingitems.view" },
        sellthroughreport: { view: "reportsanalytics.sellthroughreport.view" },
        lycomparison: { view: "reportsanalytics.lycomparison.view" },
        channelwisenetsalesanalysis: { view: "reportsanalytics.channelwisenetsalesanalysis.view" },
        storewiseatv: { view: "reportsanalytics.storewiseatv.view" },
        dailytrackerkpi: { view: "reportsanalytics.dailytrackerkpi.view" },
        storesalesdetails: { view: "reportsanalytics.storesalesdetails.view" },
        gvanalysis: { view: "reportsanalytics.gvanalysis.view" },
        salesreport: { view: "reportsanalytics.salesreport.view" },
        newhitlineratereport: { view: "reportsanalytics.newhitlineratereport.view" },
        jistreport: { view: "reportsanalytics.jistreport.view" },
        footfallconversionreport: { view: "reportsanalytics.footfallconversionreport.view" },
        salesstockitemreport: { view: "reportsanalytics.salesstockitemreport.view" },
        salesmis: { view: "reportsanalytics.salesmis.view" },
        salesorderstatus: { view: "reportsanalytics.salesorderstatus.view" },
        reorderingreport: { view: "reportsanalytics.reorderingreport.view" },
        returnreport: { view: "reportsanalytics.returnreport.view" },
        poitemreport: { view: "reportsanalytics.poitemreport.view" },
        ordersreport: { view: "reportsanalytics.ordersreport.view" },
        poshipmentreport: { view: "reportsanalytics.poshipmentreport.view" },
        pogrnreport: { view: "reportsanalytics.pogrnreport.view" },
        multilevelapprovalreport: { view: "reportsanalytics.multilevelapprovalreport.view" },
        pogrnexception: { view: "reportsanalytics.pogrnexception.view" },
        tnareport: { view: "reportsanalytics.tandareport.view" },
        otbreport: { view: "reportsanalytics.otbreport.view" },
        otbforcastreport: { view: "reportsanalytics.otbforcastreport.view" },
        indentorderreport: { view: "reportsanalytics.indentorderreport.view" },
        vendorledger: { view: "reportsanalytics.vendorledger.view" },
        vendoroutstanding: { view: "reportsanalytics.vendoroutstanding.view" },
        customeroutstanding: { view: "reportsanalytics.customeroutstanding.view" },
        customerledger: { view: "reportsanalytics.customerledger.view" },
        stocksreport: { view: "reportsanalytics.stocksreport.view" },
        stockagingreport: { view: "reportsanalytics.stockagingreport.view" },
        stockmis: { view: "reportsanalytics.stockmis.view" },
        factoryaddressreport: { view: "reportsanalytics.factoryaddressreport.view" },
        appointmentreport: { view: "reportsanalytics.appointmentreport.view" },
        logisticsreport: { view: "reportsanalytics.logisticsreport.view" },
        logisticswarehousestock: { view: "reportsanalytics.logisticswarehousestock.view" },
        logisitcspaymentreport: { view: "reportsanalytics.logisitcspaymentreport.view" },
        autoasnreport: { view: "reportsanalytics.autoasnreport.view" },
        shipmentboxreport: { view: "reportsanalytics.shipmentboxreport.view" },
        inspectionsreport: { view: "reportsanalytics.inspectionsreport.view" },
        shipmentsreport: { view: "reportsanalytics.shipmentsreport.view" },
        userlogin: { view: "reportsanalytics.userlogin.view" },
        useractivity: { view: "reportsanalytics.useractivity.view" },
        potogrnreport: { view: "reportsanalytics.potogrnreport.view" },
        ledgerreport: { view: "reportsanalytics.ledgerreport.view" },
    },
    masterData: {
        vendors: {
            view: "masterdata.vendors.view",
            update: "masterdata.vendors.update",
            manageportalaccess: "masterdata.vendors.manageportalaccess",
            sendverificationlink: "masterdata.vendors.sendverificationlink",
        },
        onboardingrequest: {
            view: "masterdata.onboardingrequest.view",
            approve: "masterdata.onboardingrequest.approve",
        },
        agents: {
            view: "masterdata.agents.view",
            update: "masterdata.agents.update",
            manageportalaccess: "masterdata.agents.manageportalaccess",
        },
        transporter: {
            view: "masterdata.transporter.view",
            update: "masterdata.transporter.update",
            manageportalaccess: "masterdata.transporter.manageportalaccess",
            delete: "masterdata.transporter.delete",
        },
        fabriclibrary: {
            view: "masterdata.fabriclibrary.view",
            create: "masterdata.fabriclibrary.create",
        },
        materiallibrary: {
            view: "masterdata.materiallibrary.view",
            update: "masterdata.materiallibrary.update",
            create: "masterdata.materiallibrary.create",
            delete: "masterdata.materiallibrary.delete",
        },
        costsheetlibrary: {
            view: "masterdata.costsheetlibrary.view",
            update: "masterdata.costsheetlibrary.update",
            create: "masterdata.costsheetlibrary.create",
            delete: "masterdata.costsheetlibrary.delete",
        },
        customer: {
            view: "masterdata.customer.view",
            update: "masterdata.customer.update",
            manageportalaccess: "masterdata.customer.manageportalaccess",
            delete: "masterdata.customer.delete",
        },
        salesagent: {
            view: "masterdata.salesagent.view",
            update: "masterdata.salesagent.update",
            create: "masterdata.salesagent.create",
            delete: "masterdata.salesagent.delete",
        },
        items: {
            view: "masterdata.items.view",
            update: "masterdata.items.update",
            create: "masterdata.items.create",
            resync: "masterdata.items.resync",
            bulkoperation: "masterdata.items.bulkoperation",
        },
        sites: {
            view: "masterdata.sites.view",
            update: "masterdata.sites.update",
            create: "masterdata.sites.create",
        },
    },
    administration: {
        roles: {
            view: "administration.roles.view",
            update: "administration.roles.update",
            create: "administration.roles.create",
            delete: "administration.roles.delete",
            copy: "administration.roles.copy",
        },
        users: {
            view: "administration.users.view",
            update: "administration.users.update",
            create: "administration.users.create",
        },
        userdatamapping: {
            view: "administration.userdatamapping.view",
            update: "administration.userdatamapping.update",
            create: "administration.userdatamapping.create",
        },
        datasync: { view: "administration.datasync.view" },
        apilogs: { view: "administration.apilogs.view" },
        auditlogs: { view: "administration.auditlogs.view" },
        systemmails: { view: "administration.systemmails.view" },
        announcement: {
            view: "administration.announcement.view",
            manage: "administration.announcement.manage",
            delete: "administration.announcement.delete",
        },
        apiintegration: {
            view: "administration.apiintegration.view",
            manage: "administration.apiintegration.manage",
        },
        barcodeprofile: {
            view: "administration.barcodeprofile.view",
            manage: "administration.barcodeprofile.manage",
        },
        datamanagement: {
            view: "administration.datamanagement.view",
            manage: "administration.datamanagement.manage",
        },
        datasettings: {
            view: "administration.datasettings.view",
            manage: "administration.datasettings.manage",
        },
        documentformat: {
            view: "administration.documentformat.view",
            manage: "administration.documentformat.manage",
        },
        formsettings: {
            view: "administration.formsettings.view",
            manage: "administration.formsettings.manage",
        },
        generalsettings: {
            view: "administration.generalsettings.view",
            manage: "administration.generalsettings.manage",
        },
        loginsettings: {
            view: "administration.loginsettings.view",
            manage: "administration.loginsettings.manage",
        },
        masterdataconfiguration: {
            view: "administration.masterdataconfiguration.view",
            manage: "administration.masterdataconfiguration.manage",
        },
        multilevelapproval: {
            view: "administration.multilevelapproval.view",
            manage: "administration.multilevelapproval.manage",
        },
        notification: {
            view: "administration.notification.view",
            manage: "administration.notification.manage",
        },
        organizationdata: {
            view: "administration.organizationdata.view",
            manage: "administration.organizationdata.manage",
        },
        replenishmentsettings: {
            view: "administration.replenishmentsettings.view",
            manage: "administration.replenishmentsettings.manage",
        },
        systemmaintenance: {
            view: "administration.systemmaintenance.view",
            manage: "administration.systemmaintenance.manage",
        },
        tableheader: {
            view: "administration.tableheader.view",
            manage: "administration.tableheader.manage",
        },
        trainingguides: {
            view: "administration.trainingguides.view",
            manage: "administration.trainingguides.manage",
        },
        workflowsettings: {
            view: "administration.workflowsettings.view",
            manage: "administration.workflowsettings.manage",
        }
    },
    common: {
        commonFunctionality: {
            view: "common.commonfunctionality.view",
            upload: "common.commonfunctionality.upload",
            downloaddocuments: "common.commonfunctionality.downloaddocuments",
            exporttoexcel: "common.commonfunctionality.exporttoexcel",
            quickfilters: "common.commonfunctionality.quickfilters"
        },
    },
};

// Function to get permission key
export function getPermissionKey(module, submodule, action) {
    if (permissions?.[module]?.[submodule]?.[action]) {
        return {"X-Scope-Id": permissions[module][submodule][action]?.toLowerCase()}
    }
    else if (module && submodule && action) {
        return {"X-Scope-Id": `${module}.${submodule}.${action}`?.toLowerCase()}
    }
    else {
        console.error("Permission not found for specified module, submodule, and action");
        return {};
    }
}

export default permissions;
