import React, { useEffect, useState } from 'react'
import Icons from '../../assets/icons'
import { parseJwt } from '../../helper/genericFunction'
import GenericFormFooterComponent from '../../genericComponents/forms/GenericFormFooterComponent'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import MergeSuccessModal from './MergeSuccessModal'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import { getPermissionKey } from '../../helper/permissions'

const VendorMerge = (props) => {
    const dispatch = useDispatch();

    const tokenData = parseJwt(sessionStorage.getItem("token"));
    const getAllMergeVendorListData = useSelector(state => state?.authStore?.getAllMergeVendorListData);
    const mergeVendorsAccountData = useSelector(state => state?.authStore?.mergeVendorsAccountData);

    const [accountMergeList, setAccountMergeList] = useState(props.multiAccountModal ? props.accMobNumDetailsList?.map((item, index) => ({...item, primary : index == 0 ? true : false}) ) : []);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        if(!props.multiAccountModal)
            dispatchHandler(dispatch, "getAllMergeVendorListRequest", null, getPermissionKey("common", "commonfunctionality", "view"));
    }, [])


    useEffect(() => {
        if (getAllMergeVendorListData?.isSuccess) {
            setAccountMergeList(getAllMergeVendorListData?.data?.resource || []);
        }
    }, [getAllMergeVendorListData?.isSuccess])
    
    useEffect(()=>{
        if(mergeVendorsAccountData?.isSuccess){
            setShowSuccessModal(true);
            // props.submitModal();,
        }
    },[mergeVendorsAccountData?.isSuccess])

    const renderTopMergeInfo = () => {
        return (
            <div className='eud-multi-acc-alert'>
                <Icons.InfoYellow />
                <div className="eudmaa-content">
                    <label>Your number is associated with a total of <span>{tokenData?.accMobNumDetailsList?.length} accounts.</span></label>
                    <p>As there are multiple accounts linked to your contact number, please ensure to identify the accounts that are not associated with you. If you discover any unauthorized or incorrect accounts, please promptly report them to us at <b className='txt-underline'>support@supplymint.com.</b></p>
                </div>
            </div>
        )
    }

    const renderMergeInformations = () => {
        return (
            <div className="eud-multi-acc-alert eud-merge-req">
                <Icons.AdminIconMenuIcon />
                <div className="eudmaa-content">
                    <label>Account Merge Required !</label>
                    <p>During this process, all of your accounts connected to {tokenData?.mob} will be consolidated. Consequently, you will need to establish a primary account to which all other accounts will be merged. All your linked accounts will be visible within the primary account under <b>Profile &#62; Your Organizations.</b></p>
                </div>
            </div>
        )
    }

    // Function : To render all the account list
    const renderAccount = (data, type) => {
        return (
            data?.map((item, index) => (
                <div className='euda-accounts'>
                    <div className='eudaa-acc-details'>
                        <p>{item?.orgName} {type == "primary" && <span className="eudaa-primary-acc">Primary</span>}</p>
                        <label>Display Name</label>
                    </div>
                    <div className='eudaa-acc-details'>
                        <p>{item?.[props.multiAccountModal ? "mappedOrgId" : "userCode"]}</p>
                        <label>Code</label>
                    </div>
                    <div className='eudaa-acc-details'>
                        <p>{item?.[props.multiAccountModal ? "accType" :  "userType"]}</p>
                        <label>Account Type</label>
                    </div>
                    {props.multiAccountModal && <GenericCustomButton
                        label = "Set As Primary"
                        type='primary'
                        disabled={type == "primary"}
                        onClick={ () => {
                            let updatedData = [...accountMergeList];
                            updatedData?.map((val,i) => ({...val, primary : i == index ? 1 : 0 }))
                            setAccountMergeList(updatedData)
                        }}
                    />}
                </div>
            ))
        )
    }

    const handleChange = (type) => {
        switch (type) {
            case "mergeVendorAccount": {
                let ids = accountMergeList?.map(item => item.id);
                let result = ids.join(',');
                dispatchHandler(dispatch, "mergeVendorsAccountRequest", {
                    functionsName: "supplymint.merge_account_pending_signup",
                    additionalAttribute: result,
                    attributeValues: tokenData.jti
                },getPermissionKey("common", "commonfunctionality", "view") )
                break;
            }
        }
    }

    console.log("sdfsadfasd", accountMergeList, props.multiAccountModal);

    if(showSuccessModal){
        return  <MergeSuccessModal
            submitModal = {() => {
                props.submitModal();
            }}
        />
    }

    return (
        <div className='multi-acc-merge'>
            <div className='mac-inner'>
                {/* Render the top div inside the merge component */}
                {renderTopMergeInfo()}

                {/* Render the merge information */}
                {renderMergeInformations()}

                {/* Render the Available accounts */}
                <div className="eudmaa-scroll">
                    <div className='eud-accounts'>
                        <h2>Primary Account</h2>
                        {renderAccount(props.multiAccountModal ? accountMergeList?.filter(item => item?.primary == 1) :  [{
                            userCode: tokenData.slCode || "",
                            orgName: tokenData.ename || "",
                            userType: tokenData.uType || ""
                        }], "primary")}
                        </div>

                    {getAllMergeVendorListData?.isLoading ? <div>
                        <p>VendorListSkeletonLoader</p>
                    </div> : <div className='eud-accounts'>
                        {accountMergeList?.length > 0 && <div style={{height:"100px"}}>
                            <h2>Other Accounts</h2>
                            {renderAccount(props.multiAccountModal ?  accountMergeList?.filter(item => item?.primary == 1)  : accountMergeList || [])}
                        </div>}

                    </div>}
                </div>
            </div>
            <GenericFormFooterComponent
                buttons={[
                    { label: "Skip", type: "default", onClick: () => props.skipModal() },
                    { label: "Access", type: "primary", onClick: () => handleChange("mergeVendorAccount") }
                ]}
            />
        </div>
    )
}

export default VendorMerge