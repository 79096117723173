import React from 'react'
import GenericCustomButton from '../../../../../genericComponents/GenericCustomButton';
import { useState } from 'react';
import Icons from '../../../../../assets/icons';
import LanguageTranslator from '../../../../../locals/LanguageTranslator';

const AnnoucementPreview = (props) => {
    let { posts = [], closeModalOnFinalPost, closeModal, viewMode = false, currentActiveStrip, loading, setLoading } = props;

    // First we need to calculate the min index and the max index in which the user can move back and forward else other will be major annoucements.
    let minIndex = posts?.findIndex(item => ["popupAnnoucements"]?.includes(item?.type)) || 0;
    let maxIndex = posts?.length;

    const [currentActivePost, setCurrentActivePost] = useState(currentActiveStrip ? currentActiveStrip : 0);

    // function to get the lable based on the viewMode
    const getLabel = (viewMode) => {
        console.log('viewMode', viewMode);
        switch (viewMode) {
            default: return 'Know More';
        }
    };
    console.log('currentactivepost', posts?.[currentActivePost]);
    return (
        <div className='announcement-preview'>
            {/* the top banner image */}
            {posts?.[currentActivePost]?.bannerImage ?
                typeof posts?.[currentActivePost]?.bannerImage === 'function' ? posts?.[currentActivePost]?.bannerImage() :
                    <div className='ap-img min-h-[200px]'>
                        <img src={posts?.[currentActivePost]?.bannerImage} />
                    </div>
                :
                <div className='ap-img'>
                    <div className='api-no-img !h-[200px]'>
                        <div className='p-bot-5'><Icons.BannerImageIcon /></div>
                        <LanguageTranslator tag="p">Banner Image</LanguageTranslator>
                    </div>
                </div>
            }
            <div className={`ap-body ${posts?.[currentActivePost]?.renderCustomButtons ? "apb-content-body" : ""}`}>
                
                {/* Render the title and subtitle if available */}
                <div className='apb-head'>
                    {posts?.[currentActivePost]?.["title"] && <label>{posts?.[currentActivePost]?.["title"] || ""}</label>}
                    {posts?.[currentActivePost]?.["annoucementView"] == "popup" && posts?.[currentActivePost]?.["subtitle"] && <p>{posts?.[currentActivePost]?.["subtitle"] || ""}</p>}
                </div>

                {/* render the custom part data */}
                {posts?.[currentActivePost]?.renderCustomData ?
                    <div>
                        {typeof posts?.[currentActivePost]?.renderCustomData === 'function' ?
                            posts[currentActivePost]?.renderCustomData({
                                item: posts?.[currentActivePost] || {},
                            }) : posts[currentActivePost]["renderCustomData"]
                        }
                    </div> : posts?.[currentActivePost]?.["message"] &&
                    <div className='apb-para'>
                        <label>Message</label>
                        <p>{posts?.[currentActivePost]?.["message"] || "-"}</p>
                    </div>}

                {/* render the external link  */}
                {
                    posts?.[currentActivePost]?.external_link &&
                    <div className='apb-read-more'>
                        <button onClick={() => window.open(posts?.[currentActivePost]?.external_link, '_blank')}>
                            <div className='flex items-center'>
                                <Icons.ReadMoreAttachIcon />
                                <label>{getLabel(posts?.[currentActivePost]?.type)}</label>
                            </div>
                            <Icons.RightArrowNewGreen />
                        </button>
                    </div>
                }

            </div>
            {/* Render the final submit button of the annoucement */}

            {
                posts?.[currentActivePost]?.renderCustomButtons ?
                    typeof posts?.[currentActivePost]?.renderCustomButtons === 'function' ?
                        posts?.[currentActivePost]?.renderCustomButtons({
                            item: posts?.[currentActivePost] || {},
                            setCurrentActivePost: setCurrentActivePost,
                            posts: posts,
                            loading: loading,
                            setLoading: setLoading,
                        }) : posts?.[currentActivePost]?.renderCustomButtons
                    :
                    <div className='ap-footer'>
                        {posts?.length > 1 &&
                            <GenericCustomButton
                                type='default'
                                disabled={currentActivePost - minIndex == 0}
                                onClick={() => setCurrentActivePost(prev => prev - 1)}
                                label="Previous"
                            />
                        }
                        {posts?.length > 1 &&
                            <p>
                                <span>{(currentActivePost - minIndex) + 1}</span>
                                <span> of </span>
                                <span>{maxIndex - minIndex}</span>
                            </p>
                        }
                        <GenericCustomButton
                            className={posts?.length <= 1 && "w-100"}
                            type='primary'
                            disabled={viewMode}
                            onClick={viewMode ? null : currentActivePost + 1 == posts?.length ? () => closeModalOnFinalPost?.() : () => setCurrentActivePost(prev => prev + 1)}
                            label="Continue"
                        />
                    </div>
            }
        </div>
    )
}

export default AnnoucementPreview