import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../helper/apiHelperFunctions';
import { InfoIcon, Waypoints } from 'lucide-react';
import { parseJwt } from '../helper/genericFunction';
import { getPermissionKey } from '../helper/permissions';

function UserDataMappingCheck(props) {
    const { history } = props
    const generalStore = useSelector(state => state.generalStore);
    const dispatch = useDispatch();

    const uType = parseJwt(sessionStorage.getItem('token')).uType?.toUpperCase() || "ENT";

    const [userDataMappingData, setUserDataMappingData] = useState({})

    useEffect(() => {
        if (Object.keys(userDataMappingData || {}).length == 0 && uType == "ENT") {
            dispatchHandler(dispatch, 'getUserDataMappingRequest', {},  getPermissionKey("common", "commonFunctionality", "view"))
        }
    }, []);

    useEffect(() => {
        if (generalStore?.getUserDataMappingData?.isSuccess) {
            setUserDataMappingData(generalStore?.getUserDataMappingData?.data?.resource || {})
        }
    }, [generalStore?.getUserDataMappingData?.isSuccess])

    if (uType !== "ENT") {
        return null;
    }

    const checkUserDataMapping = () => {
        let userMapping = false;
        Object.keys(userDataMappingData || {})?.map((key) => {
            if (key == "itemMapping") {
                Object.keys(userDataMappingData?.[key] || {})?.map((innerKey) => {
                    if (userDataMappingData?.[key]?.[innerKey]?.length > 0) {
                        userMapping = true
                    }
                })
            }
            else if (userDataMappingData?.[key]?.length > 0) {
                userMapping = true
            }
        })
        return userMapping;
    }

    if (checkUserDataMapping()) {
        return (
            <div className='relative group mr-4'>
                <div className='flex items-center bg-[#EE5A241A] rounded-full duration-300 ease-in-out overflow-hidden w-[28px] group-hover:w-[175px]'><div className='bg-[#EE5A24] p-[6px] rounded-full'><Waypoints size={16} color='white' /></div><p className={`pl-2 pr-2.5 mb-0 text-xs font-semibold text-[#EE5A24] whitespace-nowrap`}>Data mapping active</p></div>
                <div className='w-[210px] pt-[8px] absolute top-full right-0 duration-200 opacity-0 invisible pointer-events-none group-hover:opacity-100 group-hover:visible group-hover:pointer-events-auto'>
                    <div className='text-xs rounded bg-[#111D31] p-[12px]'>
                        <p className='text-white mb-1'>Filters and data view might get affected for data availability.</p>
                        {history?.location?.pathname != "/profile" && <button
                            onClick={() => history?.push({
                                pathname: "/profile",
                                state: { currentTab: "Data Mapping" }
                            })} className='text-[#EE5A24] underline hover:opacity-75'
                        >View mapping details</button>}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

export default UserDataMappingCheck