import { Radio } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Pie, Cell, ResponsiveContainer, Tooltip, PieChart, Sector } from 'recharts';
import GraphSkeleton from './GraphSkeleton';
import NoDataFound from '../../../genericComponents/NoDataFound';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const COLORS = ['#824bf6', '#ef6e8b'];
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 2) * cos;
    const sy = cy + (outerRadius + 2) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#111d31" style={{ fontSize: 12, fontWeight: 600 }}>{`${(percent * 100).toFixed(2)} %`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#67768e" style={{ fontSize: 11, fontWeight: 500 }}>{payload.name} %
            </text>
        </g>
    );
};

const GraphComponent = (props) => {
    const { graphData } = props
    const digivendStore = useSelector(state => state.digivendStore)
    const [graphType, setGraphType] = useState('asnType')

    const asnPieData = graphData?.asnLifeCycle?.map((item) => {
        if (item.stage === "Inspection request to Inspection Approval") {
            return {
                "name": "ASN Approval",
                "value": item.value,
            }
        }
        else if (item.stage === "ASN Approval to LR Creation") {
            return {
                "name": "Pickup Request",
                "value": item.value,
            }
        }
        else if (item.stage === "In Transit to Gate Entry") {
            return {
                "name": "Transit Time",
                "value": item.value,
            }
        }
        else if (item.stage === "Gate Entry TO GRC") {
            return {
                "name": "GRN",
                "value": item.value,
            }
        }
        return {
            "name": item.stage,
            "value": item.value,
        }
    });
    const boxDashPieData = graphData?.boxScore?.map((item) => {
        return {
            "matchPer": item?.matchPer,
            "mismatchedPer": item?.mismatchedPer,
        }
    });
    const boxPieData = [
        {
            name: 'Matched',
            value: boxDashPieData && boxDashPieData[0]?.matchPer,
        },
        {
            name: 'Mismatched',
            value: boxDashPieData && boxDashPieData[0]?.mismatchedPer,
        },
    ];

    console.log('asnpiedatatattattaa', asnPieData?.some((item) => item.value !== 0))

    return (
        <React.Fragment>
            {digivendStore?.getOrderTrackingDashboardGraphDetailsData?.isLoading ? <GraphSkeleton /> :
                    (<div className='otdb-right'>
                        <Radio.Group value={graphType} onChange={(e) => setGraphType(e?.target?.value || "")}>
                            <Radio.Button value={"asnType"} className='whitespace-nowrap !px-3 w-full'><LanguageTranslator>ASN complete lifecycle</LanguageTranslator></Radio.Button>
                            <Radio.Button value={"boxType"} className='whitespace-nowrap !px-3 w-full'><LanguageTranslator>Box score analysis</LanguageTranslator></Radio.Button>
                        </Radio.Group>

                        {graphType === "asnType" ?
                            <React.Fragment>
                                <div className='pcsl-top'>
                                    <LanguageTranslator tag="p">All bucket events</LanguageTranslator>
                                </div>
                                <div className='pcsl-circle'>
                                    {
                                        asnPieData?.some((item) => item?.value != 0) ? <PieChart width={250} height={250}
                                        >
                                            <Pie
                                                data={asnPieData}
                                                cx={120}
                                                cy={120}
                                                isAnimationActive={false}
                                                innerRadius={60}
                                                outerRadius={90}
                                                fill="#8884d8"
                                                label={{ color: "#121212", fontSize: 12, fontWeight: 500, }}
                                                dataKey="value"
                                            >
                                                {graphData?.asnLifeCycle?.map((item, index) => (
                                                    <Cell key={item?.stage} fill={
                                                        item?.stage === "Inspection request to Inspection Approval" ? "#6bf3e3" :
                                                            item?.stage === "ASN Approval to LR Creation" ? "#ef6e8b" :
                                                                item?.stage === "In Transit to Gate Entry" ? "#824bf6" : "#ffaf4f"
                                                    }
                                                    />
                                                ))}
                                            </Pie>
                                            <Tooltip
                                                overlayClassName='!p-[0]'
                                                labelStyle={{ color: "#ffffff82", fontWeight: 600, }}
                                                contentStyle={{ color: "#fff", fontWeight: 600 }}
                                            />
                                        </PieChart> : <NoDataFound />
                                    }
                                </div>
                                {
                                    asnPieData?.some((item) => item?.value != 0) &&
                                    <div className='pcslc-bottom pcslc-bottom-asn'>
                                        <div className='pcslc-col'>
                                            <span className='pcslcc-dot pcslccd-v1'></span>
                                            <h3><LanguageTranslator>ASN Approval</LanguageTranslator>
                                                <LanguageTranslator tag="span" className="generic-tooltip">Inspection request to Inspection Approval</LanguageTranslator>
                                            </h3>
                                        </div>
                                        <div className='pcslc-col'>
                                            <span className='pcslcc-dot pcslccd-v2'></span>
                                            <h3><LanguageTranslator>Pickup Request</LanguageTranslator>
                                                <LanguageTranslator tag="span" className="generic-tooltip">ASN Approval to LR Creation</LanguageTranslator>
                                            </h3>
                                        </div>
                                        <div className='pcslc-col m-top-15'>
                                            <span className='pcslcc-dot'></span>
                                            <h3><LanguageTranslator>Transit Time</LanguageTranslator>
                                                <span className="generic-tooltip">In Transit to Gate Entry</span>
                                            </h3>
                                        </div>
                                        <div className='pcslc-col m-top-15'>
                                            <span className='pcslcc-dot pcslccd-v3'></span>
                                            <h3>GRN
                                                <LanguageTranslator tag="span" className="generic-tooltip">Gate Entry TO GRC</LanguageTranslator>
                                            </h3>
                                        </div>
                                    </div>
                                }
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="pcs-left pcsl-v2">
                                    <div className="piecsi-top">
                                        <div className='pcsl-top m0'>
                                            <LanguageTranslator tag="p">Matched & Mismatched box score percentage</LanguageTranslator>
                                        </div>
                                    </div>
                                    <div className='pcsl-circle'>
                                        {boxPieData?.some((item) => item?.value != undefined) ?
                                            <ResponsiveContainer width={390} height={333}>
                                                <PieChart>
                                                    <Pie
                                                        data={boxPieData}
                                                        isAnimationActive={false}
                                                        cx="47%"
                                                        cy="50%"
                                                        outerRadius={70}
                                                        fill="#ef6e8b"
                                                        dataKey="value"
                                                        label={renderActiveShape}
                                                    >
                                                        {graphData?.boxScore?.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                            : <NoDataFound />
                                        }
                                    </div>
                                    {boxPieData?.some((item) => item?.value != undefined) &&
                                        <div className='pcslc-bottom'>
                                            <div className='pcslc-col'>
                                                <span className='pcslcc-dot'></span>
                                                <LanguageTranslator tag="h3">Match Percentage</LanguageTranslator>
                                            </div>
                                            <div className='pcslc-col'>
                                                <span className='pcslcc-dot pcslccd-v2'></span>
                                                <LanguageTranslator tag="h3">Mismatch Percentage</LanguageTranslator>
                                            </div>
                                        </div>}
                                </div>
                            </React.Fragment>}
                    </div>)}
        </React.Fragment>);
};

export default GraphComponent;
