import React, { useEffect, useState, useRef } from 'react';
import { decodeToken, deleteCookie, getCookie, parseJwt } from '../../../helper/genericFunction';
import ProfilePlaceholder from '../../../assets/icons/ProfilePlaceholder.svg';
import Icons from '../../../assets/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteDB } from 'idb';
import SwitchOrg from './SwitchOrg';
import FullPageLoader from '../../../loaders/FullPageLoader';
import '../../../styles/stylesheets/home/Header.scss';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import NewOnboardingRequestModal from '../../../authComponents/vendorOnboarding/components/NewOnboardingRequestModal';
import ParentModal from '../../../genericComponents/ParentModal';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { ReloadContext, UserDetailsContext } from '../../../App';

const ProfileSection = (props) => {    
    const { handleDocumentStatusChange } = useContext(ReloadContext);

    const dispatch = useDispatch();
    const { setLoading } = props;
    let tokenData = parseJwt(sessionStorage.getItem('token'));

    const uType = tokenData?.uType?.toUpperCase();

    const {clearUserContext} = useContext(UserDetailsContext)
    const history = useHistory();
    // Token data
    let {userDetails} = useContext(UserDetailsContext)
    // The login response of the api
    let loginResponse = JSON.parse(userDetails?.loginResponse || "{}") || {};

    const [enterprises, setEnterprises] = useState([]);
    const [openProfileDrop, setOpenProfileDrop] = useState(false);
    const [switchOrgDrop, setSwitchOrgDrop] = useState(false);

    const [selectedModal, setSelectedModal] = useState("");

    const profileRef = useRef(null);

    useEffect(() => {
        setEnterprises(loginResponse?.entDetails?.filter(_ => _.active == 1) || []);

        // Add event listener when component mounts
        document.addEventListener("click", handleClickOutside);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const openProfileDropdown = () => {
        setOpenProfileDrop(prevState => !prevState);
    }

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event?.target)) {
            setOpenProfileDrop(false);
            setSwitchOrgDrop(false);
        }
    };

    const onLogout = async () => {
        let registrationJwt = getCookie('registrationJwt');
        setLoading(true);
        dispatchHandler(dispatch, "userSessionCreateRequest", { token: sessionStorage.getItem('token') || "", flowType: "LOGGEDOUT" });
        deleteCookie('jwt')
        if(registrationJwt) {
            deleteCookie('registrationJwt')
        }
        localStorage?.clear();
        sessionStorage?.clear();
        clearUserContext?.();
        // window?.location?.reload();
        // await deleteDB("Supplymint-ai-local-storage");/
        setTimeout(() => {
            setLoading(false);
            handleDocumentStatusChange();
        },1000);
    };

    const handleRedirect = (path) => {
        history.push(path);
        setOpenProfileDrop(false);
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal(false) },
            animationType: "bottom",
        }
        switch (selectedModal) {
            case "newOnboardingModal": {
                return {
                    ...payload,
                    animationType: 'bottom',
                    // postitionProps: { top: "20%", left: "25%", position: "fixed", borderRadius: 4 },
                    // postitionProps: { top: "50%", left: "50%", position: "fixed", transform: 'translate(-50%, -50%)', borderRadius: 0 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { minWidth: "400px", maxWidth: '460px', height: 'fit-content', maxHeight: '90vh', overflowY: 'auto' },
                    closeModal: () => setSelectedModal(""),
                    isOpen: selectedModal != '' ? true : false,
                    data: null
                }
            }
            default : 
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "newOnboardingModal": {
                return NewOnboardingRequestModal;
            }
            default : 
        }
    }

    return (
        <React.Fragment>
            {/* // Render the dropdown section for profile */}
            <div className='hohrm-user-info' ref={profileRef}>

                {/* The profile dropdown handler  */}
                <div className='flex' onClick={tokenData?.loginSource?.toUpperCase() == "SELF" ? openProfileDropdown : null}>
                    <div className='hu-logo'>
                        <img src={tokenData?.org_logo || ProfilePlaceholder} loading="lazy" />
                    </div>
                    <div className='hu-det'>
                        <div className='flex items-center'>
                            <h4>{enterprises?.[0]?.["ename"]}</h4>
                            {tokenData?.loginSource?.toUpperCase() == "SELF" && <span className={`hud-icon ${openProfileDrop && "rotate-180"}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.293" height="6.146" viewBox="0 0 14.293 8.146">
                                    <path fill="none" stroke="#21314b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_377" d="M6 14.732L11.732 9l5.732 5.732" data-name="Path 377" transform="translate(-4.586 -8)"></path>
                                </svg>
                            </span>}
                        </div>
                        <p>{tokenData?.firstName == null ? "" : tokenData?.firstName} {tokenData?.lastName == null ? "" : tokenData?.lastName}</p>
                    </div>
                </div>

                {/* The profile dropdowns */}
                {openProfileDrop && <div className='profile-dropdown'>
                    <ul>
                        {/* vendoronboarding form redirect */}
                        {tokenData?.uType == 'VENDOR' && <div className='p-10'>
                            <div className='onboarding-alert'>
                                <div> <Icons.OnboardingWarningIcon /> </div>
                                <div className='oa-det'>
                                    <LanguageTranslator tag='h2'>Complete your Onboarding</LanguageTranslator>
                                    <LanguageTranslator tag='p'>Complete your onboarding process to avail full benefits of supplymint features</LanguageTranslator>
                                    <GenericCustomButton
                                        rightIcon={<Icons.RightArrowThin />}
                                        label='Complete Now'
                                        onClick={() => {
                                            props.setAppStackDocState('otherStack')
                                            history.push('/vendoronboarding/summary')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}

                        {/* The profile url handler */}
                        <li onClick={() => handleRedirect('/profile')}>
                            <span className="hund-icon"><Icons.ProfileFaceIcon /></span>
                            <LanguageTranslator tag="label">Profile</LanguageTranslator>
                        </li>

                        {/* The settings url for supplymint */}
                        <li onClick={() => handleRedirect('/changeSetting')}>
                            <span className="hund-icon"><Icons.ProfileSettingIcon /></span>
                            <LanguageTranslator tag="label">Settings</LanguageTranslator>
                        </li>

                        {/* Accounts change section */}
                        <li className="pd-label">
                            <LanguageTranslator tag="span">Your Accounts</LanguageTranslator>
                            <div className='flex items-center gap-2'>
                                {uType?.toUpperCase() == "VENDOR" && tokenData?.userAsAdmin &&  <GenericCustomButton
                                    label = "Manage Users"
                                    type = "primary"
                                    onClick={() => {history.push({
                                        pathname: '/profile',
                                        state: {
                                            currentTab : "Manage Vendors"
                                        }
                                    })
                                    setOpenProfileDrop(false);
                                }}
                                    className={'!rounded-full !text-[11px] !h-[26px]'}
                                />}
                                <span>{loginResponse?.["entDetails"]?.length}</span>
                            </div>
                        </li>

                        {/* Currently set up account */}
                        <li id="cascadeEnterprises" className={`switch-retailer ${switchOrgDrop && " sr-active"}`} onClick={() => setSwitchOrgDrop(!switchOrgDrop)}>
                            {
                                enterprises?.[0] ? <React.Fragment>
                                    <div className="sr-org-inner">
                                        <div className="sr-left">
                                            <span className="hund-icon">
                                                <Icons.ProfileOrgIcon />
                                            </span>
                                            <LanguageTranslator tag="label">{enterprises?.[0]?.ename}</LanguageTranslator>
                                        </div>
                                        <span className={switchOrgDrop ? "" : "sricon-rotete180"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.293" height="6.146" viewBox="0 0 14.293 8.146">
                                                <path fill="none" stroke="#21314b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_377" d="M6 14.732L11.732 9l5.732 5.732" data-name="Path 377" transform="translate(-4.586 -8)" />
                                            </svg>
                                        </span>
                                    </div>
                                    <span className="sroi-name">{enterprises?.[0]?.ename}</span>
                                </React.Fragment> :
                                    <div className='flex gap-2 bg-[#FEF5E3] p-[8px]'>
                                        <div>
                                            <Icons.InfoYellow />
                                        </div>
                                        <p className='!mt-0 !text-black'>You haven't associated with any brands or retailers yet. To get started, create a new onboarding request by clicking the button below.</p>
                                    </div>
                            }

                            {/* Render the swith organisation dropdown */}
                            <div id="orgDropdownMain">
                                {switchOrgDrop && <SwitchOrg
                                    enterprises={loginResponse?.["entDetails"] || []}
                                // enterprises={data}
                                />}
                            </div>
                        </li>

                        {/* New onboarding Request */}
                        {
                            tokenData?.uType == 'VENDOR' && <div className={`border border-dashed border-[#B7C6DE] py-3 mr-[10px] ml-[13px] mb-[10px] flex items-center justify-center gap-2 duration-200 hover:bg-gray-100`} onClick={() => setSelectedModal('newOnboardingModal')}>
                                <span className='hund-icon'><Icons.PlusIconBlack /></span>
                                <LanguageTranslator tag='span' className='text-xs font-semibold uppercase'>Create New Onboarding Request</LanguageTranslator>
                            </div>
                        }

                        <li className="pd-logout" onClick={(e) => onLogout(e)}>
                            <span className="hund-icon">
                                <Icons.LogoutIcon />
                            </span>
                            <LanguageTranslator tag="label">Logout</LanguageTranslator>
                        </li>
                    </ul>
                </div>}
            </div>

            {/* new onboarding rquest modal */}
            {selectedModal ? <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            /> : null}

        </React.Fragment>
    )
}

export default ProfileSection;
