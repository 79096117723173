import { openDB } from "idb";
import { dispatchHandler } from "../../helper/apiHelperFunctions";
import { parseJwt, setCookie } from "../../helper/genericFunction";
import { isSetAutoLoginCookie } from "./helper/autoLoginFunctions";
import { getAllAnnouncements } from "../../initialPhaseOperations/getInitialPhaseFunctions";
import { getPermissionKey } from "../../helper/permissions";

// Function : To provide the updated modules for new changes SMP  
export const getUpdatedModulesObject = (obj) => {
    let removeCodes = ["RDPPICIV2", "RDPPIVPRS", "RDPPOCOV1", "RDPPOCOV2", "RDAREPSCHWH", "RDAREPSCHIST", "RDPCONFMAIN"];
    let updateDisplayName = {
        "RDPPICORDER": "Create Order",
        "RDDIPICIV2": "Create Indent",
        "RDPPICIV3": "Create Indent",
        "RDPPOCOV3": "Create Order"
    }

    // console.log("ASDfdasf", obj, typeof(obj));

    obj = obj?.map(module => {
        // Remove these module or pages from the side menu
        if (removeCodes?.includes(module?.code)) {
            return null;
        }
        // Changes the name of the page if required
        if (updateDisplayName?.[module?.code]) {
            module.name = updateDisplayName[module?.code];
        }
        //  repeat the same process for the submodules also
        if (module?.subModules) {
            module.subModules = getUpdatedModulesObject(module?.subModules)
        }
        return module;
    })?.filter(item => item ? true : false)

    return obj;

}

// Function -> Set the data inside the session storage
export const setSessionStorageItems = async (data, config = {}) => {

    let {userDetails, setUserDetails,clearUserContext } = config;

    // Set all the modules 
    console.log("Data Token", data);
    if (userDetails?.loginTempInfo) {
        clearUserContext("loginTempInfo")
    }
    sessionStorage.setItem("token", data?.token)
    // Set for the language here
    if (data?.user?.language) {
        localStorage.setItem("CURRENT_LANGUAGE", data?.user?.language)
    }

    // If these two things are present inside the config 
    if(userDetails && setUserDetails){
        let isNewVendorOnboardingLoggingIn = data?.vendorOnboardingPage === 1 && data?.user?.enterprises?.[0]?.eid == -1;
        let updatedUserDetails = {
            ...userDetails,
            "modules" : isNewVendorOnboardingLoggingIn ? JSON.stringify(getUpdatedModulesObject([])) :  JSON.stringify(getUpdatedModulesObject(data?.modules?.["0"] || [])),
            "dashboardModules" : isNewVendorOnboardingLoggingIn ? JSON.stringify([]) : JSON.stringify(data?.dashboardModules || []),
            "loginResponse" : JSON.stringify(data || {}),
        }
        setUserDetails(updatedUserDetails)
    }

    // Update and set the token inside the cookie for auto login of user
    data?.token && isSetAutoLoginCookie() && setCookie({ name: "jwt", value: data?.token, hours: 6 })

    let db =  await openDB('Supplymint-ai-local-storage', 1, {
        upgrade(db) {
            db.createObjectStore("MASTER_DB_FILTER");
            db.createObjectStore("MASTER_DB_COLORS");
            db.createObjectStore("MASTER_DB_HEADER");
            db.createObjectStore("MASTER_DB_DOWNLOAD_DOCUMENTS");
            db.createObjectStore("MASTER_DB_DROPDOWN");
            db.createObjectStore("AUTO_SAVE_DATA");
        }
    });
    db?.close();
}

// Function -> Set the data inside the local storage.
export const setLocalStorageItems = async (data, dispatch) => {
    data?.token && localStorage.setItem('token', data?.token);
    let parseToken = parseJwt(data?.token);
    // Create User Session 
    // dispatchHandler(dispatch, "userSessionCreateRequest", { token: data?.token, flowType: "LOGGEDIN"});
    // Call for the master Db Dropdown, Filter, Headers api.
    dispatchHandler(dispatch, "getMasterDbHeaderRequest", { "entity": "header" }, getPermissionKey("common", "commonFunctionality", "view"));
    dispatchHandler(dispatch, "getMasterDbFilterRequest", { "entity": "filter" }, getPermissionKey("common", "commonFunctionality", "view"));
    dispatchHandler(dispatch, "getMasterDbDropdownRequest", { "entity": "dropdown" }, getPermissionKey("common", "commonFunctionality", "view"));
    // Get the dropdown data also for the selected current organisation
    dispatchHandler(dispatch, "getMasterDbDocumentDropdownRequest", {}, getPermissionKey("common", "commonFunctionality", "view"));
    getAllAnnouncements({ dispatch })
}
