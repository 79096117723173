
/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ArsDashboard from './ars/ArsDashboard'
import GenericTabsComponent from '../../genericComponents/GenericTabsComponent'
import GenericInput from '../../genericComponents/input/GenericInputComponent'
import Icons from '../../assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import '../../styles/stylesheets/home/Dashboard.scss'
import SalesOrderDashboard from './salesOrder/SalesOrderDashboard'
import ProcurementDashboard from './procurement/ProcurementDashboard'
import WarehouseAppointmentDashboard from './warehouseAppointment/WarehouseAppointmentDashboard'
import BoxAnalysisDashboard from './boxAnalysis/BoxAnalysisDashboard'
import AsnLifecycleDashboard from './asnLifecycleDashboard/AsnLifecycleDashboard'
import OrderTrackingDashboard from './orderTracking/OrderTrackingDashboard'
import PerformanceSummaryDashboard from './performanceSummary/PerformanceSummary'
import { setLocalStorageItems } from '../../authComponents/login/LoginApiResponse'
import { getNumberOfStrips } from '../../initialPhaseOperations/InitialAnnouncementModal'
import NewRegisterVendorDashbaord from './newVendor/NewRegisterVendorDashbaord'
import { parseJwt } from '../../helper/genericFunction'
import { getEnterprises } from '../../helper/helperTableFunctions'
import { getPermissionKey } from '../../helper/permissions'
import { UserDetailsContext } from '../../App'
import DigicatalogueDashbaord from './digicat/DigicatalogueDashbaord'

const Dashboard = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation();

    let uType = parseJwt(sessionStorage.getItem("token"))?.uType?.toUpperCase();
    let {userDetails, setUserDetails} = useContext(UserDetailsContext)
    const enterprisesData  =  getEnterprises({userDetails})

    let dashboardModules = JSON.parse(userDetails?.dashboardModules || "[]");
    let availableDashboardModules = ["RDADASH", "RDARSDASHSKX", "SALMNGDASH", "VDVDASHORDV2", "RDVDASHWH", "RDPDASH", "RDVDASHV2", "RDVDASHBOX", "RDVDASHASN", "CSALMNGDASH", "RDPCATDASH", "VDPCATDASH"];
    const digiArsStore = useSelector((state) => state.digiArsStore)

    const tabsDataObject = dashboardModules?.reduce((acc, item) => {
        if (availableDashboardModules?.includes(item?.code))
            acc[item.pageUrl] = (parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T" && item?.name == "Order Tracking") ? "Transporter Dashboard" : item?.name;
        return acc;
    }, {});

    const [currentTab, setCurrentTab] = useState(Object?.keys(tabsDataObject || "")?.includes(`#${location.pathname}`) ? `#${location.pathname}` : dashboardModules?.[0]?.pageUrl)
    const [ruleEngineData, setRuleEngineData] = useState([])
    const [newEngineData, setNewEngineData] = useState({})
    const [stripHeight, setStripHeight] = useState(getNumberOfStrips());

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    useEffect(() => {

        if (sessionStorage.getItem("switchOrgResponse")) {
            let data = JSON.parse(userDetails?.["switchOrgResponse"] || "{}");
            let func = async () => {
                sessionStorage.removeItem('switchOrgResponse');
                // setUserDetails(prev => {
                //     delete prev.switchOrgResponse;
                //     return prev;
                // })
                await setLocalStorageItems(data, dispatch);
            }
            func();
        }

        // When component gets mounted, move to the first route 
        if (Object.keys(tabsDataObject)?.[0] && !Object.keys(tabsDataObject || {})?.includes(`#${location.pathname}`)) {
            history.replace(Object.keys(tabsDataObject || {})?.[0]?.replace('#', ''))
        }
    }, [])
    // Mapping object for components
    const componentMapping = {
        '/home/ars/new/dashboard': ArsDashboard,
        '/home/sales/dashboard': SalesOrderDashboard,
        '/home/customer/dashboard': SalesOrderDashboard,
        '/home/procurement/dashboard': ProcurementDashboard,
        '/home/wh/appointment/dashboard': WarehouseAppointmentDashboard,
        '/home/box/analysis/dashboard': BoxAnalysisDashboard,
        '/home/asn/dashboard': AsnLifecycleDashboard,
        "/home/orderTracking/dashboard": OrderTrackingDashboard,
        '/home/vendor/orderTracking/dashboard': OrderTrackingDashboard,
        '/home/ars/dashboard': PerformanceSummaryDashboard,
        '/home/digicat/dashboard' : DigicatalogueDashbaord,
    };

    useEffect(() => {
        // Update the current tab when user changes the current tab;
        if (Object.keys(tabsDataObject)?.includes(location?.pathname)) {
            let path = location?.pathname;
            setCurrentTab(tabsDataObject?.[path]);
        }
    }, [location])

    useEffect(() => {
        if (currentTab === '#/home/ars/new/dashboard') {
            dispatchHandler(dispatch, 'getTransferOrderDropdownRequest', { pageNo: 1, search: '', type: 1 }, getPermissionKey("dashboard", "arsdashboard", "view"))
        }
        let path = location?.pathname;
        let moduleName = dashboardModules?.find(item => item?.pageUrl?.includes(path))?.name;
        document.title = (parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T" && moduleName == "Order Tracking") ? "Transporter Dashboard" : moduleName || "Supplymint";
    }, [currentTab])

    // // Function : To get the transfer dropdown data request
    const getTransferOrderDataRequest = () => {
        dispatchHandler(dispatch, 'getArsDashboardRequest', { ruleId: ruleEngineData?.[0]?.id }, getPermissionKey("dashboard", "arsdashboard", "view"))
    }

    useEffect(() => {
        if (digiArsStore?.getTransferOrderDropdownData?.isSuccess && digiArsStore?.getTransferOrderDropdownData?.data?.resource != null) {
            setRuleEngineData(digiArsStore?.getTransferOrderDropdownData?.data?.resource?.ruleEngineData)
            setNewEngineData(digiArsStore?.getTransferOrderDropdownData?.data?.resource?.ruleEngineData?.[0])
            dispatchHandler(dispatch, 'getArsDashboardRequest', { ruleId: digiArsStore?.getTransferOrderDropdownData?.data?.resource?.ruleEngineData[0]?.id }, getPermissionKey("dashboard", "arsdashboard", "view"))
        }
    }, [digiArsStore?.getTransferOrderDropdownData?.isSuccess])

    const onChangeJobname = (data) => {
        setNewEngineData(data)
        dispatchHandler(dispatch, 'getArsDashboardRequest', { ruleId: data.id }, getPermissionKey("dashboard", "arsdashboard", "view"))
    }

    const renderDashboardTopRightComponent = () => {
        switch (currentTab) {
            case "#/home/ars/new/dashboard":
                return <GenericInput
                    type="dropdown"
                    iconRight={<Icons.DownArrowNew />}
                    inputKey="jobname"
                    placeholder='Rule Engine'
                    value={newEngineData?.jobname || ""}
                    onChange={(item) => onChangeJobname(item)}
                    inputProps={() => {
                        return {
                            headerKeys: {
                                "jobname": {
                                    value: "jobname",
                                    width: "100%"
                                },
                            },
                            "itemKeys": {
                                "jobname": ["jobname"]
                            },
                            "getListData": () => {
                                return ruleEngineData || [];
                            },
                        }
                    }}
                />
        }
    }

    return (<>
        {(enterprisesData?.length != 0 && enterprisesData?.[0]?.eid == -1 || enterprisesData?.[0]?.oid == -1 && uType === "VENDOR") ?
            <NewRegisterVendorDashbaord />
            :
            <React.Fragment>
                <div className={`dashboard-tab top-${stripHeight + 45}`}>
                    <GenericTabsComponent
                        tabsData={tabsDataObject}
                        className="p-lr-47"
                        tabsKey={"currentTab"}
                        handleTabChange={(val) => {
                            setCurrentTab(val);
                            if (val == "#/home/ars/new/dashboard") {
                                // Call this when user switches to the 
                                getTransferOrderDataRequest()
                            }
                            history.push(val?.replace(/^#/, ''))
                        }}
                        current={currentTab}
                        type={1}
                    />

                    {renderDashboardTopRightComponent()}
                </div>
                <Switch>
                    {Object.keys(componentMapping || {}).map(url => {
                        return (
                            <Route
                                key={url}
                                exact
                                path={url}
                                render={(props) => {
                                    const Component = componentMapping?.[url];
                                    return <Component jobName={newEngineData?.jobname} newEngineData={newEngineData} />;
                                }}
                            />
                        );
                    })}
                </Switch>
            </React.Fragment>
        }
    </>
    )
}

export default Dashboard