import React from 'react'
import Icons from '../../assets/icons'

const NoPageAvailable = () => {
  return (
    <div className='text-center w-full h-[calc(100vh-45px)] rounded pl-[30px] pr-[15px] py-[15px]'>
      <div className='flex flex-col items-center justify-center gap-7 h-full bg-white'>

          <div><Icons.NoPageAvailable/></div>
          <p className='text-[22px] font-semibold mb-0'>No Data Found</p>
          <p className='text-[#575D6A] font-medium max-w-28 mb-0'>This page's data has been disabled by your organization. To view the content, please enable it in the configuration settings.</p>
      </div>
    </div>
  )
}

export default NoPageAvailable